import api from "./api";

export const fetchReservation = async (params: {
  limit?: number;
  page?: number;
  sort?: string;
  order?: string;
  psycholog_code?: string;
  status?: string;
  keyword?: string;
  start_date?: string;
  end_date?: string;
}) => {
  const resp = await api.get("/v1/reservation", {
    params: params,
  });

  return resp.data;
};

export const updateReservationSchedule = async (params: {
  scheduleDetailCode: string;
  reservationCode: string;
  formatCall: string;
  locationCode?: string;
}) => {
  return api
    .put(`/v1/reservation/${params.reservationCode}`, {
      schedule_detail_code: params.scheduleDetailCode,
      format_call: params.formatCall,
      location_code: params.locationCode,
    })
    .then((val) => val.data);
};

export const getCounselingByReservation = async (params: {
  type: "pre_counseling" | "post_counseling";
  reservationCode: string;
}) => {
  return api
    .get(`/v1/reservation/${params.reservationCode}/counseling/answer`, {
      params: {
        type: params.type,
        limit: 100,
        sort: "asc",
      },
    })
    .then((val) => val.data);
};

export const getNoteByReservation = async (params: {
  reservationCode: string;
}) => {
  return api
    .get(`/v1/reservation/${params.reservationCode}/note`)
    .then((val) => val.data);
};

export const getNoteFilesByReservation = async (params: {
  reservationCode: string;
}) => {
  return api
    .get(`/v1/reservation/${params.reservationCode}/note/files`)
    .then((val) => val.data);
};

export const getAvailabilityFormatCall = async (params: {
  memberCode: string;
  year: number;
  month: number;
}) => {
  const resp = await api.get(
    `/v1/specialists/${params.memberCode}/schedule/format_call`,
    {
      params: {
        year: params.year,
        month: params.month,
      },
    }
  );

  return resp.data;
};

export const getAvailabilityLocation = async (params: {
  memberCode: string;
  year: number;
  month: number;
}) => {
  const resp = await api.get(
    `/v1/specialists/${params.memberCode}/schedule/format_call/location`,
    {
      params: {
        year: params.year,
        month: params.month,
      },
    }
  );

  return resp.data;
};

export const getAvailabilityLocationV2 = async (params: {
  memberCode: string;
  year: number;
  month: number;
}) => {
  const resp = await api.get(
    `/v1/specialists/${params.memberCode}/schedule/format_call/location_v2`,
    {
      params: {
        year: params.year,
        month: params.month,
      },
    }
  );

  return resp.data;
};
