import api from "./api";

export const fetchLocation = async (params: {
  limit: number;
  page: number;
  offset?: number;
  keyword?: string;
  sort?: string;
  order?: string;
  status?: string;
}) => {
  return api.get("/v1/location", { params: params }).then((val) => val.data);
};

export const addLocation = (params: any) => {
  return api.post("/v1/location", params).then((val) => val.data);
};

export const updateLocation = (params: any) => {
  return api
    .put(`/v1/location/${params.location_code}`, params)
    .then((val) => val.data);
};

// @deprecated
export const fetchLocationByFormatCall = async (params: {
  format_call: string;
}) => {
  return api
    .get("/v1/location/codes", { params: params })
    .then((val) => val.data);
};

export const fetchLocationByFormatCallV2 = async (params: {
  format_call: string;
}) => {
  return api
    .get("/v1/locations/codes", { params: params })
    .then((val) => val.data);
};
export const addRoom = (params: any) => {
  return api.post("/v1/rooms", params).then((val) => val.data);
};
export const updateRoom = (params: any) => {
  return api
    .put(`/v1/rooms/${params.room_code}`, params)
    .then((val) => val.data);
};
export const fetchRooms = async (params: {
  limit: number;
  page: number;
  offset?: number;
  keyword?: string;
  sort?: string;
  order?: string;
  status?: string;
}) => {
  return api.get("/v1/rooms", { params: params }).then((val) => val.data);
};