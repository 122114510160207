import React from "react";
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { Endpoints, Images } from "constant";
import numeral from "numeral";
import { sessionTypes } from "constant/Session";

export default function RateRow(props: {
  row: any;
  isSelectedRow: boolean;
  labelId: any;
  withoutAction: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
}) {
  const { specialist_rate_code, username, profile_url, title, rates } =
    props.row;

  return (
    <TableRow
      hover
      key={specialist_rate_code}
      aria-checked={props.isSelectedRow}
      role="checkbox"
      tabIndex={-1}
      selected={props.isSelectedRow}
    >
      <TableCell align="left">
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "200px !important",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ marginRight: "20px" }}>
            {props.row.profile_url === "" ? (
              <img
                alt="maxi-cms"
                src={String(Images.avatar)}
                style={{ width: " 36px ", height: "36px" }}
              />
            ) : (
              <div
                style={{
                  width: "36px",
                  height: "36px",
                  borderRadius: "100%",
                  backgroundSize: "cover",
                  backgroundImage: `url(${String(
                    Endpoints.baseUrlAws + profile_url
                  )})`,
                }}
              />
            )}
          </div>
          <div className="truncate">{username}</div>
        </Typography>
      </TableCell>

      <TableCell align="left">
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "150px !important",
          }}
        >
          {title}
        </Typography>
      </TableCell>

      {sessionTypes.map((val, i) => {
        const session = (rates ?? []).find(
          (item: any) => item.format_call === val.key
        );
        let price = "-";
        if (session) {
          price = `Rp${numeral(session.price).format("0,0")}/Rp${numeral(
            session.fee
          ).format("0,0")}`;
        }

        return (
          <TableCell align="left" key={i}>
            <Typography
              variant="subtitle2"
              noWrap
              sx={{
                fontFamily: "Fonts.interRegular",
                width: "180px !important",
              }}
            >
              {price}
            </Typography>
          </TableCell>
        );
      })}

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        <Box sx={{ width: "150px", display: "flex" }}>
          {!props.withoutAction ? (
            <>
              <IconButton
                title="Edit"
                className="bg-neutral-200"
                onClick={props.onEdit}
              >
                <img src={Images.btn_edit_act} alt="" />
              </IconButton>
            </>
          ) : null}
        </Box>
      </TableCell>
    </TableRow>
  );
}
