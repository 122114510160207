import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from "@mui/material";
import { TableEmptyRows, TableHeadCustom } from "components/Table";
import useTable from "components/Table/useTable";
import React, { useEffect, useState } from "react";
import PromoRow from "sections/@dashboard/promo";
import { getListPromo } from "service/promo";
import { utils } from "utils";

const TABLE_HEAD = [
  {
    id: "promo_code",
    label: "NAMA PROMO",
    alignRight: false,
  },
  {
    id: "promo_type",
    label: "TIPE PROMO",
    alignRight: false,
  },
  {
    id: "discount_type",
    label: "TIPE DISKON",
  },
  {
    id: "discount",
    label: "DISKON",
    alignRight: false,
  },
  {
    id: "max_discount",
    label: "MAKS DISKON",
    alignRight: false,
  },
  {
    id: "promo_budget",
    label: "ANGGARAN PROMO",
  },
  {
    id: "usage",
    label: "PEMAKAIAN",
  },
  {
    id: "status",
    label: "STATUS",
  },
  { id: "act" },
];

export default function TabDeleted(props: {
  search: string;
  forceUpdate: number;
  onCount: (val: number) => void;
  onRestore: (val: any) => void;
}) {
  const [data, setData] = useState<any[]>([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);

  const { onSort } = useTable();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("promo_code");

  useEffect(() => {
    init();
  }, [props.forceUpdate, page, limit]);

  useEffect(() => {
    if (props.search.length > 0 && page !== 1) {
      onChangePage(0);
    } else {
      init();
    }
  }, [props.search]);

  const onRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const dataFiltered = utils.applySortFilter({
    data: data,
    comparator: utils.getComparator(order, orderBy),
  });

  const onChangeRowPerPage = (val) => {
    setPage(1);
    setLimit(val);
  };

  const onChangePage = (val) => {
    setPage(val + 1);
  };

  const init = async () => {
    const resp = await getListPromo({
      page: page,
      limit: limit,
      keyword: props.search,
      status: "inactive",
    });

    const pagination = resp.pagination;

    setCount(pagination.count);
    setLimit(pagination.limit);
    setPage(pagination.page);
    setData(resp.data ?? []);

    props.onCount(pagination.count);
  };

  return (
    <div>
      <TableContainer
        sx={{
          minWidth: 800,
          position: "relative",
        }}
      >
        <Table size="medium">
          <TableHeadCustom
            headLabel={TABLE_HEAD}
            rowCount={data.length}
            orderBy={orderBy}
            onRequestSort={onRequestSort}
            onSort={onSort}
            order={order}
          />

          <TableBody>
            {dataFiltered.map((row, index) => {
              const labelIdx = `promo-deleted-${index}`;

              return (
                <PromoRow
                  key={labelIdx}
                  row={{
                    ...row,
                    status: "inactive",
                  }}
                  labelId={labelIdx}
                  onRestore={() => props.onRestore(row)}
                />
              );
            })}

            <TableEmptyRows height={72} emptyRows={data.length} />
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ position: "relative" }}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          labelRowsPerPage="Item per halaman: "
          count={count || 0}
          rowsPerPage={limit || 10}
          page={page - 1 || 0}
          onPageChange={(_, pg) => onChangePage(pg)}
          onRowsPerPageChange={(e) => onChangeRowPerPage(e.target.value)}
        />
      </Box>
    </div>
  );
}
