import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { ModalSimple } from "components/Modal/ModalSimple";
import { Colors, Fonts } from "constant";
import { sessionTypes } from "constant/Session";
import { IVoidCallback } from "interfaces/callback";
import React, { useEffect, useState, useCallback, useRef } from "react";
import { LabelModal } from "screens/Login/style";
import { Button as ButtonStyle } from "screens/style";
import { fetchLocation } from "service/location";

export default function ModalRoom(props: {
  isEdit: boolean;
  currentItem: any;
  isShow: boolean;
  isAddContent: boolean;
  toggle: IVoidCallback;
  onSubmit: (val: any) => void;
}) {
  const [locationName, setLocationName] = useState("");
  const [locationCode, setLocationCode] = useState("");
  const [address, setAddress] = useState("");
  const [roomName, setRoomName] = useState("");
  const [sessionType, setSessionType] = useState<any[]>([]);

  const [isEnabled, setIsEnabled] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [isNewLocation, setIsNewLocation ] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const checkFormValid = () => {
    if (sessionType && sessionType.length > 0) {
      if (isNewLocation || props.isEdit) {
        setIsEnabled(!!(locationName && address));
      } else {
        setIsEnabled(!!(locationCode));
      }
    } else {
      setIsEnabled(false);
    }
  };

  useEffect(() => {
    checkFormValid();
  }, [locationName, address, roomName, sessionType]);

  useEffect(() => {
    if (!props.isShow) {
      setLocationName("");
      setAddress("");
      setSessionType([]);
      setRoomName("");
      setLocationCode("");

      setIsEnabled(false);
    } else {
      const item = props.currentItem;

      setLocationName(item.name ?? "");
      setLocationCode(item.location_code ?? "");
      setAddress(item.address ?? "");
      setSessionType(item.format_calls ? item.format_calls.split(",") : []);
      setRoomName(item.room_name ?? "");
    }
    init();
  }, [props.isShow]);

  const init = async () => {
    const resp = await fetchLocation({
      page: 1,
      limit: 10,
      keyword: "",
      status: "active",
    });

    const pagination = resp.pagination;

    setData(resp.data ?? []);
  };

  const onChangeSessionType = (val: string | string[]) => {
    if (typeof val === "string") {
      setSessionType(val.split(","));
    } else {
      setSessionType(val);
    }
  };

  const onUpdateLocationCode = useCallback((code: string) => {
    if (code == "-new-") {
      setIsNewLocation(true);
      setLocationName("");
      setAddress("");
      inputRef && inputRef.current && inputRef.current.focus();
    } else {
      setLocationCode(code);
      const loc = data.find((p) => (p.location_code == code));
      if (loc) {
        setAddress(loc.address);
      }
    }
  }, [data]);

  const onSubmit = async () => {
    setIsEnabled(false);

    props.onSubmit({
      is_new: isNewLocation,
      location_code: locationCode,
      name: locationName,
      address: address,
      room_name: roomName,
      format_calls: sessionType.join(","),
    });
  };

  return (
    <ModalSimple
      isShown={props.isShow}
      title={props.isAddContent ? "Add New Location" : "Update Location"}
      hide={props.toggle}
    >
      <>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box sx={{ overFlowY: "initial !important" }}>
              <div className="modalBodyC">
                { (!isNewLocation && !props.isEdit) && <>
                  <div className="input-wrapper" style={{ margin: "0 0 0 0" }}>

                      <div style={{ width: "100%", marginBottom: "10px" }}>
                        <LabelModal
                          style={{
                            margin: "5px 0 10px 0",
                            display: "block",
                          }}
                        >
                          Location Name
                        </LabelModal>
                        <Select
                          displayEmpty
                          value={locationCode}
                          onChange={(val) => onUpdateLocationCode(val.target.value)}
                          sx={{
                            width: "100%",
                            fontSize: Fonts.sizes.xs,
                          }}
                          >
                          <MenuItem value={""}>
                            <em className="text-slate-400">Location Name</em>
                          </MenuItem>
                          <MenuItem value={"-new-"}>
                            <em className="text-red-700">New Location</em>
                          </MenuItem>
                          { data && data.map((loc, i) => {
                            return (<MenuItem key={i} value={loc.location_code}>
                              { loc.name }
                            </MenuItem>);
                          }) }
                        </Select>
                      </div>
                  </div>
                </> }

                { (isNewLocation || props.isEdit) && <>
                <div className="input-wrapper" style={{ margin: "0 0 0 0" }}>
                  <div className="schedule-cus" style={{ width: "100%" }}>
                    <div style={{ width: "100%" }}>
                      <LabelModal
                        style={{
                          margin: "5px 0 10px 0",
                          display: "inline-block",
                        }}
                      >
                        Location Name
                      </LabelModal>
                      <div className="input-wrapper form-inline">
                        <input
                          type="text"
                          placeholder="Location Name"
                          onChange={(val) => setLocationName(val.target.value)}
                          value={locationName}
                          ref={inputRef}
                        />
                        { !props.isEdit && <Button style={{ marginBottom: "10px" }}
                          onClick={() => setIsNewLocation(false)}
                        ><CloseIcon /></Button>
                        }
                      </div>
                    </div>
                  </div>
                </div>

                </>
                }

                <div className="input-wrapper" style={{ margin: "0 0 0 0" }}>
                  <div
                    className="schedule-cus"
                    style={{
                      width: "100%",
                      paddingTop: "0",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <LabelModal
                        style={{
                          margin: "0 0 10px 0",
                          display: "inline-block",
                        }}
                      >
                        Location Address
                      </LabelModal>
                      <div className="input-wrapper form-inline">
                        <input
                          type="text"
                          placeholder="Location Address"
                          onChange={(e) => setAddress(e.target.value)}
                          value={address}
                          disabled={!isNewLocation && !props.isEdit}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="input-wrapper" style={{ margin: "0 0 0 0" }}>
                  <div
                    className="schedule-cus"
                    style={{
                      width: "100%",
                      paddingTop: "0",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <LabelModal
                        style={{
                          margin: "0 0 10px 0",
                          display: "inline-block",
                        }}
                      >
                        Room Name
                      </LabelModal>
                      <div className="input-wrapper form-inline">
                        <input
                          type="text"
                          placeholder="Room Name"
                          value={roomName}
                          onChange={(val) => setRoomName(val.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="input-wrapper" style={{ margin: "0 0 0 0" }}>
                  <div
                    style={{
                      width: "100%",
                      paddingTop: "16px",
                      marginBottom: "8px",
                    }}
                  >
                    <LabelModal
                      style={{
                        margin: "5px 0 10px 0",
                        display: "block",
                      }}
                    >
                      Session Type
                    </LabelModal>
                    <Select
                      displayEmpty
                      multiple
                      value={sessionType}
                      onChange={(val) => onChangeSessionType(val.target.value)}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return (
                            <em className="text-slate-400">Session Type</em>
                          );
                        }

                        const vals = selected.map((item) => {
                          const idx = sessionTypes.findIndex((val) => {
                            return val.key === item;
                          });

                          if (idx !== -1) {
                            return sessionTypes[idx].label;
                          } else {
                            return "";
                          }
                        });

                        return vals.join(", ");
                      }}
                      sx={{
                        width: "100%",
                        border: "solid 1px Colors.app.GhostGrey",
                        background: Colors.white.default,
                        borderRadius: "5px",
                        color: Colors.app.black,
                        fontWeight: Fonts.weights.w5,
                        fontFamily: "Fonts.interMedium",
                        fontSize: Fonts.sizes.xs,
                      }}
                    >
                      <MenuItem value={""}>
                        <em className="text-slate-400">Session Type</em>
                      </MenuItem>
                      {sessionTypes.map((val) => {
                        return (
                          <MenuItem value={val.key} key={val.key}>
                            <Checkbox
                              color="default"
                              checked={sessionType.indexOf(val.key) > -1}
                            />
                            <ListItemText primary={val.label}></ListItemText>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </div>
                </div>
              </div>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: "20px 24px !important" }}>
          <ButtonStyle
            className="btn-primary"
            disabled={!isEnabled}
            title="Add New Specialist "
            onClick={onSubmit}
            style={{ marginTop: "30px" }}
          >
            {props.isEdit ? "Update" : "Add"}
          </ButtonStyle>
        </DialogActions>
      </>
    </ModalSimple>
  );
}
