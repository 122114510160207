import {
  Box,
  DialogActions,
  DialogContent,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import {
  DayCalendarSkeleton,
  LocalizationProvider,
  PickersDay,
  PickersDayProps,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { ModalSimple } from "components/Modal/ModalSimple";
import { LabelModal } from "components/Modal/style";
import { Colors, Fonts } from "constant";
import dayjs from "dayjs";
import { Calendar, Clock } from "iconsax-react";
import { IChangeCallback } from "interfaces/callback";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button as ButtonStyle } from "screens/style";
import {
  fetchMonthlyScheduleBySpecialistV2,
  getSchedulesByDateV2,
} from "service/schedule";
import { sessionTypes } from "constant/Session";
import { getListPromo } from "service/promo";
import { useQuery } from "react-query";
import {
  getAvailabilityFormatCall,
  getAvailabilityLocationV2,
} from "service/reservation";

const invalidLocationCode = "LOC---------";
const onlineLocationCode = "";

export default function ModalModify(props: {
  onSubmit: IChangeCallback<any, void>;
  hide: () => void;
  isAddContent: boolean;
  isShow: boolean;
  specialists: any[];
  value: any;
}) {
  const [specialistCode, setSpecialistCode] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [sessionMethod, setSessionMethod] = useState("");
  const [sessionDate, setSessionDate] = useState<Date | null>(null);
  const [sessionTime, setSessionTime] = useState("");
  const [sessionTimeCode, setSessionTimeCode] = useState("");
  const [promo, setPromo] = useState("");
  const [sessionLocation, setSessionLocation] = useState(invalidLocationCode);
  const [calendarDate, setCalendarDate] = useState(new Date());

  // const [formatCalls, setFormatCalls] = useState({});
  const [isShowDatePicker, setShowDatePicker] = useState(false);
  const [isShowTimePicker, setShowTimePicker] = useState(false);

  const [promos, setPromos] = useState<any[]>([]);
  const [formatCalls, setFormatCalls] = useState<any[]>([]);

  const changeSessionMethod = (newSessionMethod: string) => {
    if (newSessionMethod.includes("online")) {
      setSessionLocation(onlineLocationCode);
    } else {
      setSessionLocation(invalidLocationCode);
    }
    setSessionDate(null);
    setSessionTime("");
    setSessionMethod(newSessionMethod);
  };

  const onReset = () => {
    setSessionDate(null);
    setSessionTime("");
    setSessionTimeCode("");
    setSessionMethod("");
    setClientEmail("");
    setSpecialistCode("");
    setPromo("");
    setSessionLocation(invalidLocationCode);
  };

  useEffect(() => {
    getListPromo({
      limit: 100,
      page: 1,
      onlyAvailable: true,
      status: "active",
    }).then((val) => {
      setPromos(val.data ?? []);
    });
  }, []);

  const getFormatCall = async () => {
    getAvailabilityFormatCall({
      memberCode: specialistCode,
      year: calendarDate.getFullYear(),
      month: calendarDate.getMonth() + 1,
    }).then((val) => {
      const formats: any[] = [];
      for (const _format of sessionTypes) {
        if (_format.key === "in_person_child") {
          if (val.data.in_person_child) formats.push(_format);
        } else if (_format.key === "in_person_couple") {
          if (val.data.in_person_couple) formats.push(_format);
        } else if (_format.key === "in_person_family") {
          if (val.data.in_person_family) formats.push(_format);
        } else if (_format.key === "in_person_hypnotherapy") {
          if (val.data.in_person_hypnotherapy) formats.push(_format);
        } else if (_format.key === "in_person_individual") {
          if (val.data.in_person_individual) formats.push(_format);
        } else if (_format.key === "in_person_sensory_integration_therapy") {
          if (val.data.in_person_sensory_integration_therapy)
            formats.push(_format);
        } else if (_format.key === "in_person_speech_therapy") {
          if (val.data.in_person_speech_therapy) formats.push(_format);
        } else if (_format.key === "online_call_couple") {
          if (val.data.online_call_couple) formats.push(_format);
        } else if (_format.key === "online_call_individual") {
          if (val.data.online_call_individual) formats.push(_format);
        }
      }
      setFormatCalls(formats);
    });
  };

  const [locations, setLocations] = useState<any>();
  const fetchLocation = () => {
    getAvailabilityLocationV2({
      memberCode: specialistCode,
      year: calendarDate.getFullYear(),
      month: calendarDate.getMonth() + 1,
    }).then((val) => {
      setLocations(val.data);
    });
  };

  const isEnableButton =
    specialistCode.length > 0 &&
    sessionDate !== null &&
    sessionTimeCode !== "" &&
    sessionTime !== "" &&
    sessionMethod !== "" &&
    sessionLocation != invalidLocationCode;

  useEffect(() => {
    if (!props.isShow) {
      onReset();
    }

    if (props.isShow && !props.isAddContent) {
      const value = props.value;

      setSpecialistCode(value.psycholog_code);
      setSessionMethod(value.format_call);
      setClientEmail(value.member_email);
      setSessionTimeCode(value.schedule_detail_code);
      setSessionTime(value.time_from);
      setSessionDate(new Date(value.date));
      setSessionLocation(value.location_code);
      setPromo(value.promo_code);
    }
  }, [props.isShow]);

  useEffect(() => {
    if (specialistCode) {
      getFormatCall();
      fetchLocation();
    }
  }, [specialistCode]);

  // const onFetchRates = () => {
  //   return () =>
  //     getSpecialistRates({
  //       memberCode: props.value.psycholog_code,
  //       lang: "id",
  //     }).then((val) => {
  //       return val.data;
  //     });
  // };

  // const profileRates = useQuery("getSpecialistRates", onFetchRates());

  const onSubmit = () => {
    let specialist: any = {};
    for (const item of props.specialists) {
      if (item.member_code === specialistCode) {
        specialist = item;
        break;
      }
    }

    if (sessionLocation === invalidLocationCode) {
      return toast.warn("please, selection location first");
    }

    props.onSubmit({
      psycholog_code: specialistCode,
      schedule_detail_code: sessionTimeCode,
      format_call: sessionMethod,
      referral_code: specialist.referral_code,
      psycholog_username: specialist.username,
      time_from: sessionTime,
      date: sessionDate,
      promo: promo,
      location: sessionLocation,
    });
  };

  return (
    <>
      <ModalSimple
        isShown={props.isShow}
        title={props.isAddContent ? "Add Session" : "Edit Session"}
        hide={props.hide}
        reset={onReset}
      >
        <>
          <DialogContent>
            <Box sx={{ overFlowY: "initial !important" }}>
              <div className="modalBodyC">
                <LabelModal
                  style={{
                    marginTop: "20px",
                    display: "inline-block",
                  }}
                >
                  Nama Spesialis
                </LabelModal>
                <div className="input-wrapper">
                  <Select
                    required
                    displayEmpty
                    value={specialistCode}
                    disabled={!props.isAddContent}
                    inputProps={{ "aria-label": "Without label" }}
                    onChange={(val) => {
                      setSpecialistCode(val.target.value!);
                      setSessionMethod("");
                    }}
                    sx={{
                      marginTop: "8px",
                      width: "100%",
                      border: "solid 1px Colors.app.GhostGrey",
                      background: Colors.white.default,
                      borderRadius: "5px",
                      color: Colors.app.black,
                      fontWeight: Fonts.weights.w5,
                      fontFamily: "Fonts.interMedium",
                      fontSize: Fonts.sizes.xs,
                      marginBottom: "8px",
                    }}
                  >
                    <MenuItem value="" disabled>
                      <em>Specialist Name</em>
                    </MenuItem>
                    {props.specialists.map((sugges) => (
                      <MenuItem
                        key={sugges.member_code}
                        value={sugges.member_code}
                      >
                        {sugges.username}
                      </MenuItem>
                    ))}
                  </Select>
                </div>

                {clientEmail !== "" ? (
                  <>
                    <LabelModal
                      style={{
                        marginTop: "20px",
                        display: "inline-block",
                      }}
                    >
                      Client Email
                    </LabelModal>
                    <div className="input-wrapper">
                      <TextField
                        required
                        fullWidth
                        value={clientEmail}
                        disabled
                        placeholder="Client Email"
                        type="email"
                        sx={{
                          marginTop: "8px",
                          width: "100%",
                          border: "solid 1px Colors.app.GhostGrey",
                          background: Colors.white.default,
                          borderRadius: "5px",
                          color: Colors.app.black,
                          fontWeight: Fonts.weights.w5,
                          fontFamily: "Fonts.interMedium",
                          fontSize: Fonts.sizes.xs,
                        }}
                      ></TextField>
                    </div>
                  </>
                ) : null}

                <LabelModal
                  style={{
                    marginTop: "20px",
                    display: "inline-block",
                  }}
                >
                  Tipe Sesi
                </LabelModal>
                <div className="input-wrapper">
                  <Select
                    required
                    displayEmpty
                    value={sessionMethod}
                    inputProps={{ "aria-label": "Without label" }}
                    onChange={(val) => changeSessionMethod(val.target.value!)}
                    sx={{
                      marginTop: "8px",
                      width: "100%",
                      border: "solid 1px Colors.app.GhostGrey",
                      background: Colors.white.default,
                      borderRadius: "5px",
                      color: Colors.app.black,
                      fontWeight: Fonts.weights.w5,
                      fontFamily: "Fonts.interMedium",
                      fontSize: Fonts.sizes.xs,
                      marginBottom: "8px",
                    }}
                  >
                    <MenuItem value="" disabled>
                      <em>Session Type</em>
                    </MenuItem>
                    {formatCalls.map((val) => {
                      return (
                        <MenuItem value={val.key} key={val.key}>
                          <em>{val.label}</em>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>

                <LabelModal
                  style={{
                    marginTop: "20px",
                    display: "inline-block",
                  }}
                >
                  Lokasi Sesi
                </LabelModal>
                <div className="input-wrapper">
                  {sessionMethod.includes("online") ? (
                    <TextField
                      fullWidth
                      value="Online"
                      sx={{
                        marginTop: "8px",
                        width: "100%",
                        border: "solid 1px Colors.app.GhostGrey",
                        background: Colors.white.default,
                        borderRadius: "5px",
                        color: Colors.app.black,
                        fontWeight: Fonts.weights.w5,
                        fontFamily: "Fonts.interMedium",
                        fontSize: Fonts.sizes.xs,
                      }}
                    ></TextField>
                  ) : locations ? (
                    <Select
                      required
                      displayEmpty
                      value={sessionLocation}
                      inputProps={{ "aria-label": "Without label" }}
                      onChange={(val) => {
                        setSessionTime("");
                        setSessionTimeCode("");
                        setSessionDate(null);
                        setSessionLocation(val.target.value!);
                      }}
                      sx={{
                        marginTop: "8px",
                        width: "100%",
                        border: "solid 1px Colors.app.GhostGrey",
                        background: Colors.white.default,
                        borderRadius: "5px",
                        color: Colors.app.black,
                        fontWeight: Fonts.weights.w5,
                        fontFamily: "Fonts.interMedium",
                        fontSize: Fonts.sizes.xs,
                        marginBottom: "8px",
                      }}
                    >
                      <MenuItem value={invalidLocationCode} disabled>
                        <em>Lokasi</em>
                      </MenuItem>
                      {locations.locations[sessionMethod]?.map((val: any) => {
                        return (
                          <MenuItem
                            value={val.location_code}
                            key={val.location_code}
                          >
                            <em>{val.name}</em>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  ) : null}
                </div>

                <div className="flex gap-4">
                  <div>
                    <LabelModal
                      style={{
                        marginTop: "20px",
                        display: "inline-block",
                      }}
                    >
                      Session Date
                    </LabelModal>
                    <OutlinedInput
                      required
                      fullWidth
                      disabled={
                        specialistCode === "" ||
                        sessionLocation === invalidLocationCode
                      }
                      value={
                        sessionDate === null
                          ? ""
                          : dayjs(sessionDate).format("DD/MM/YYYY")
                      }
                      placeholder="Session Date"
                      startAdornment={
                        <Calendar
                          className="text-neutral-400 mr-2"
                          variant="Bold"
                        ></Calendar>
                      }
                      onClick={() => {
                        if (specialistCode === "") {
                          return toast.warn("please, psychologist first");
                        }

                        setShowDatePicker(true);
                      }}
                      sx={{
                        marginTop: "8px",
                        width: "100%",
                        border: "solid 1px Colors.app.GhostGrey",
                        background: "white !important",
                        borderRadius: "5px",
                        color: Colors.app.black,
                        fontWeight: Fonts.weights.w5,
                        fontFamily: "Fonts.interMedium",
                        fontSize: Fonts.sizes.xs,
                      }}
                      className="no-disabled-style"
                    ></OutlinedInput>
                  </div>
                  <div>
                    <LabelModal
                      style={{
                        marginTop: "20px",
                        display: "inline-block",
                      }}
                    >
                      Session Time
                    </LabelModal>
                    <OutlinedInput
                      required
                      fullWidth
                      value={sessionTime}
                      disabled={sessionDate === null}
                      placeholder="Session Time"
                      startAdornment={
                        <Clock
                          className="text-neutral-400 mr-2"
                          variant="Linear"
                        ></Clock>
                      }
                      onClick={() => {
                        if (sessionDate === null) {
                          return toast.warn("please, choose date first");
                        }

                        setShowTimePicker(true);
                      }}
                      sx={{
                        marginTop: "8px",
                        width: "100%",
                        border: "solid 1px Colors.app.GhostGrey",
                        background: Colors.white.default,
                        borderRadius: "5px",
                        color: Colors.app.black,
                        fontWeight: Fonts.weights.w5,
                        fontFamily: "Fonts.interMedium",
                        fontSize: Fonts.sizes.xs,
                      }}
                      className="no-disabled-style"
                    ></OutlinedInput>
                  </div>
                </div>

                {props.isAddContent ? (
                  <>
                    <LabelModal
                      style={{
                        marginTop: "20px",
                        display: "inline-block",
                      }}
                    >
                      Promo Code
                    </LabelModal>
                    <div className="input-wrapper">
                      <Select
                        required
                        displayEmpty
                        value={promo}
                        inputProps={{ "aria-label": "Without label" }}
                        onChange={(val) => setPromo(val.target.value!)}
                        sx={{
                          marginTop: "8px",
                          width: "100%",
                          border: "solid 1px Colors.app.GhostGrey",
                          background: Colors.white.default,
                          borderRadius: "5px",
                          color: Colors.app.black,
                          fontWeight: Fonts.weights.w5,
                          fontFamily: "Fonts.interMedium",
                          fontSize: Fonts.sizes.xs,
                          marginBottom: "8px",
                        }}
                      >
                        <MenuItem value="" disabled>
                          <em>Promo Code</em>
                        </MenuItem>
                        {promos.map((val) => {
                          return (
                            <MenuItem
                              value={val.promo_code}
                              key={val.promo_code}
                            >
                              <em>{val.promo_code}</em>
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </div>
                  </>
                ) : null}
              </div>
            </Box>
          </DialogContent>
          <DialogActions sx={{ padding: "20px 24px !important" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <ButtonStyle
                disabled={!isEnableButton}
                onClick={onSubmit}
                className="btn-primary"
                style={{ marginTop: "30px" }}
              >
                {props.isAddContent ? "Bagikan Link Pembayaran" : "Edit"}
              </ButtonStyle>
            </Box>
          </DialogActions>
        </>
      </ModalSimple>
      <ModalAvailableCalendar
        formatCall={sessionMethod}
        onHide={() => setShowDatePicker(false)}
        onChange={(val, calendar) => {
          setSessionDate(val);
          setCalendarDate(calendar);
          setShowDatePicker(false);
        }}
        isOpen={isShowDatePicker}
        specialistCode={specialistCode}
        locationCode={sessionLocation}
      ></ModalAvailableCalendar>

      <ModalAvailableTime
        formatCall={sessionMethod}
        currentDate={sessionDate}
        currentTime={sessionTime}
        onChange={(scheduleCode, scheduleTime) => {
          setSessionTime(scheduleTime);
          setSessionTimeCode(scheduleCode);
          setShowTimePicker(false);
        }}
        isShow={isShowTimePicker}
        specialistCode={specialistCode}
        onHide={() => setShowTimePicker(false)}
        locationCode={sessionLocation}
      ></ModalAvailableTime>
    </>
  );
}

function ModalAvailableTime(props: {
  currentDate?: Date | null;
  currentTime?: string;
  isShow: boolean;
  specialistCode: string;
  formatCall: string;
  locationCode: string;
  onChange: (val: string, time: string) => void;
  onHide: () => void;
}) {
  const [scheduleDetailCode, setScheduleDetailCode] = useState("");
  const [scheduleDetailTime, setScheduleDetailTime] = useState("");
  const [schedules, setSchedules] = useState<any[]>([]);

  useEffect(() => {
    if (props.specialistCode !== "" && props.currentDate !== null) {
      getSchedulesByDateV2({
        date: dayjs(props.currentDate).format("YYYY-MM-DD"),
        specialistCode: props.specialistCode,
        formatCall: props.formatCall,
        locationCode: props.locationCode,
      }).then((val) => setSchedules(val.data ?? []));
    }
  }, [props.specialistCode, props.currentDate, props.formatCall]);

  useEffect(() => {
    if (!props.isShow) {
      setScheduleDetailCode("");
      setScheduleDetailTime("");
    }
  }, [props.isShow]);

  const onSubmit = () => {
    if (scheduleDetailCode === "") {
      return toast.warn("please, choose schedule detail!");
    }

    props.onChange(scheduleDetailCode, scheduleDetailTime);
  };

  return (
    <ModalSimple
      hide={props.onHide}
      isShown={props.isShow}
      title=""
      width="360"
    >
      <DialogContent>
        {(schedules ?? []).map((val: any, i: number) => {
          return (
            <div className="my-6" key={i}>
              <div className="mb-4 text-neutral-secondary">{val.period}</div>
              <div className="grid grid-flow-row grid-cols-2 gap-6 sm:grid-cols-3 md:grid-cols-4">
                {val.details.map((item: any, j: number) => {
                  return (
                    <div
                      key={`${i}-${j}`}
                      className={`py-2 text-center px-8 border border-solid border-slate-200 rounded-lg cursor-pointer ${
                        item.schedule_detail_code === scheduleDetailCode
                          ? "border-black bg-blue-background"
                          : ""
                      }`}
                      onClick={() => {
                        setScheduleDetailCode(item.schedule_detail_code);
                        setScheduleDetailTime(item.time_from);
                      }}
                    >
                      {item.time_from}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
        <ButtonStyle
          onClick={onSubmit}
          className="btn-primary"
          title="Save"
          style={{ marginTop: "30px" }}
        >
          Save
        </ButtonStyle>
      </DialogContent>
    </ModalSimple>
  );
}

function ModalAvailableCalendar(props: {
  onHide: () => void;
  onChange: (newDate: Date, calendarDate: Date) => void;
  isOpen: boolean;
  specialistCode: string;
  formatCall: string;
  locationCode: string;
}) {
  const [availables, setAvailables] = useState([]);
  const [currentDate, setCurrentDate] = useState<Date | null>(null);
  const [calendarDate, setCalendarDate] = useState(new Date());

  useEffect(() => {
    if (props.isOpen === false) {
      setCurrentDate(null);
    }

    if (props.specialistCode === "") {
      setAvailables([]);
    }

    if (props.specialistCode !== "") {
      fetchMonthlyScheduleBySpecialistV2({
        month: calendarDate.getMonth() + 1,
        year: calendarDate.getFullYear(),
        specialistCode: props.specialistCode,
        location_code: props.locationCode,
        format_call: props.formatCall,
      }).then((val) => setAvailables(val.data ?? []));
    }
  }, [props.specialistCode, calendarDate, props.isOpen, props.formatCall]);

  const handleMonthChange = (date: Date) => {
    setCalendarDate(date);
  };

  const onSubmit = () => {
    if (currentDate === null) {
      return toast.warn("please, choose date first");
    }

    props.onChange(currentDate, calendarDate);
    props.onHide();
  };

  return (
    <ModalSimple
      hide={props.onHide}
      isShown={props.isOpen}
      title=""
      width="360"
    >
      <>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateCalendar
              onMonthChange={handleMonthChange}
              renderLoading={() => <DayCalendarSkeleton />}
              slots={{ day: ServerDay } as any}
              slotProps={{
                day: {
                  availables,
                  onChange: (val: string) => setCurrentDate(new Date(val)),
                } as any,
              }}
            />
          </LocalizationProvider>
          <ButtonStyle
            onClick={onSubmit}
            className="btn-primary"
            title="Save"
            style={{ marginTop: "30px" }}
          >
            Save
          </ButtonStyle>
        </DialogContent>
      </>
    </ModalSimple>
  );
}

function ServerDay(
  props: PickersDayProps<Date> & {
    availables?: any[];
    onChange: (val: Date) => void;
  }
) {
  const { availables = [], day, outsideCurrentMonth, ...other } = props;

  const indexAvailable = availables.findIndex(
    (val) => val.date === dayjs(day).format("YYYY-MM-DD")
  );
  const isSelected = !props.outsideCurrentMonth && indexAvailable !== -1;

  return (
    <div className={`${isSelected ? "bg-blue-background rounded-full" : ""}`}>
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
        disabled={!isSelected}
        onClick={() => props.onChange(availables[indexAvailable].date)}
      />
    </div>
  );
}
