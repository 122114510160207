import {
  Box,
  Card,
  Container,
  FormControl,
  InputAdornment,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import Select from "@mui/material/Select";
import { ModalDelete } from "components/Modal/ModalSimple";
import { SearchStyle, TabPanelHidden, a11yProps } from "components/Panel";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Iconify from "screens/Layout/components/Iconify";
import {
  getSpecialistTitleList,
  updateSpecialistStatus,
} from "service/specialist";
import ModalModify from "./components/ModalModify";
import TabActived from "./components/TabActived";
import { useQuery } from "react-query";
import { updateSpecialistRate } from "service/billing";
import TabInactived from "./components/TabInactived";

export default function SpecialistRate() {
  // Options Table
  const [filterSearch, setFilterSearch] = useState("");
  const [filterTitle, setFilterTitle] = useState("");

  // Tab Index
  const [tabValue, setTablIndex] = useState(0);

  // Count Label
  const [activedCount, setActivedCount] = useState(0);
  const [deletedCount, setDeletedCount] = useState(0);

  // Force Update
  const [activedForceUpdate, setActivedForceUpdate] = useState(0);

  // Modal
  const [isShowModalModify, setShowModalModify] = useState(false);
  const [isShowModalDeleted, setShowModalDeleted] = useState(false);

  // Additional
  const [currentItem, setCurrentItem] = useState<any>({});

  // Specialist Titles
  const onFetchTitles = () => {
    return getSpecialistTitleList({ isActive: true, lang: "id" }).then(
      (val) => val.data ?? []
    );
  };
  const queryTitles = useQuery("fetchTitles", onFetchTitles);
  const titles = queryTitles.data ?? [];

  useEffect(() => {
    if (!isShowModalModify && !isShowModalDeleted) {
      setCurrentItem({});
    }
  }, [isShowModalModify, isShowModalDeleted]);

  const onEdit = (item: any) => {
    setCurrentItem(item);
    setShowModalModify(true);
  };

  const onDelete = (item: any) => {
    setCurrentItem(item);

    setShowModalDeleted(true);
  };

  const onConfirmDelete = async () => {
    await updateSpecialistStatus({
      psychologCode: currentItem.psycholog_code,
      status: "inactive",
    });

    setShowModalDeleted(false);

    setActivedForceUpdate(Math.random());

    toast.success("psychologist already inactive");
  };

  const onSubmit = async (val: any) => {
    await updateSpecialistRate(currentItem.specialist_rate_code, val);

    setActivedForceUpdate(Math.random());

    toast.success("specialist rate already updated!");
    setShowModalModify(false);
  };

  return (
    <>
      <Container maxWidth="lg">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4}
        >
          <Box>
            <Typography variant="h4" sx={{ fontSize: "24px" }}>
              Tarif Psikolog
            </Typography>
          </Box>
        </Stack>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Tabs
              value={tabValue}
              onChange={(_, val) => setTablIndex(val)}
              aria-label="basic tabs example"
            >
              <Tab label={`Aktif (${activedCount})`} {...a11yProps(1)} />
              <Tab label={`Dihapus (${deletedCount})`} {...a11yProps(2)} />
            </Tabs>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            margin: "0",
            paddingTop: "20px",
            paddingBottom: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "250px" }}>
            <SearchStyle
              onChange={(e) => setFilterSearch(e.target.value)}
              placeholder="Cari"
              startAdornment={
                <InputAdornment position="start">
                  <Iconify
                    icon="eva:search-fill"
                    sx={{
                      color: "text.disabled",
                      width: 25,
                      height: 25,
                    }}
                  />
                </InputAdornment>
              }
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            marginBottom: "20px",
          }}
        >
          <FormControl>
            <Select
              displayEmpty
              value={filterTitle}
              onChange={(e) => setFilterTitle(e.target.value)}
              sx={{
                height: "40px",
                fontSize: "14px",
                fontFamily: "Inter-Medium",
              }}
            >
              <MenuItem value="">
                <em>All Titles</em>
              </MenuItem>
              {titles.map((item: any) => (
                <MenuItem
                  key={item.specialist_title_code}
                  value={item.specialist_title_code}
                >
                  <em>{item.title}</em>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <TabPanelHidden value={tabValue} index={0}>
          <Card>
            <TabActived
              search={filterSearch}
              forceUpdate={activedForceUpdate}
              title={filterTitle}
              onEdit={onEdit}
              onDelete={onDelete}
              onCount={(val) => setActivedCount(val)}
            />
          </Card>
        </TabPanelHidden>
        <TabPanelHidden value={tabValue} index={1}>
          <Card>
            <TabInactived
              search={filterSearch}
              forceUpdate={activedForceUpdate}
              title={filterTitle}
              onEdit={onEdit}
              onDelete={onDelete}
              onCount={(val) => setDeletedCount(val)}
            />
          </Card>
        </TabPanelHidden>

        <ModalModify
          currentItem={currentItem}
          isShow={isShowModalModify}
          onSubmit={onSubmit}
          toggle={() => setShowModalModify(false)}
        ></ModalModify>

        <ModalDelete
          isOpen={isShowModalDeleted}
          onClose={() => setShowModalDeleted(false)}
          onDelete={onConfirmDelete}
          title="Apakah Anda yakin ingin menonaktifkan psikolog ini? Mereka tidak akan tersedia di Ohana."
        ></ModalDelete>
      </Container>
    </>
  );
}
