import api from "./api";

export const fetchSpecialistRate = async (params: {
  status: string;
  keyword?: string;
  limit?: number;
  page?: number;
  titleCode?: string;
  order?: string;
  sort?: string;
  lang?: string;
}) => {
  const resp = await api.get("/v1/specialists/rates", {
    params: {
      ...params,
      title: params.titleCode,
      lang: params.lang,
    },
  });

  return resp.data;
};
