import React, { useRef } from "react";
import { DialogContent } from "@mui/material";
import { ModalSimple } from "components/Modal/ModalSimple";
import { Endpoints } from "constant";
import dayjs from "dayjs";
import { Button as ButtonStyle } from "screens/style";
import { sessionTypes } from "constant/Session";
import { toast } from "react-toastify";

export default function ModalLink(props: {
  value: any;
  isShow: boolean;
  location: any;
  onHide: () => void;
  onSubmit: (val: string) => void;
}) {
  const contentRef = useRef<HTMLDivElement>(null);
  const value = props.value;

  const onSubmit = () => {
    window.navigator.clipboard.writeText(
      contentRef.current!.innerText!.replace(/\n\n/g, "\n")
    );
    toast.success("Tautan berhasil disalin!");
  };

  return (
    <ModalSimple
      hide={props.onHide}
      isShown={props.isShow}
      title=""
      width="560px"
    >
      <DialogContent>
        <div ref={contentRef}>
          {value.status === "waiting_payment" ? (
            <WaitingPayment
              value={value}
              location={props.location}
            ></WaitingPayment>
          ) : (
            <SuccessPayment
              value={value}
              location={props.location}
            ></SuccessPayment>
          )}
        </div>

        <ButtonStyle
          onClick={onSubmit}
          className="btn-primary"
          title="Salin"
          style={{ marginTop: "30px" }}
        >
          Salin teks
        </ButtonStyle>
      </DialogContent>
    </ModalSimple>
  );
}

function SuccessPayment(props: { value: any; location: any }) {
  const value = props.value;
  const location = props.location;

  return (
    <div
      className="leading-6 !font-medium mx-auto mt-8"
      style={{ width: "500px" }}
    >
      <div className="font-bold mb-2">Konfirmasi Konsultasi</div>
      <div>Nama: {value.psycholog_username}</div>
      <div>Sesi: {getFormatCall(value.format_call)}</div>
      {location ? <div>Lokasi: {location.name}</div> : null}
      <div>Tanggal: {dayjs(value.date).format("DD MMMM YYYY")}</div>
      <div>Waktu: {value.time_from}</div>
      {props.value.promo ? <div>Promo: {props.value.promo}</div> : null}
      <div className="mt-4">
        Gunakan link ini untuk mengikuti sesi kamu:
        <a
          className="text-brand-dark"
          href={getLink(value)}
          target="_blank"
          rel="noreferrer"
        >
          {getLink(value)}
        </a>
      </div>
      <div className="mt-4">
        Kamu dapat menghubungi kami melalui whatsapp di 6281215881388 jika kamu
        memerlukan bantuan. Terima kasih.
      </div>
    </div>
  );
}

function WaitingPayment(props: { value: any; location: any }) {
  const value = props.value;
  const location = props.location;

  return (
    <div
      className="leading-6 !font-medium mx-auto mt-8 px-5"
      style={{ width: "500px" }}
    >
      <div className="font-bold mb-2">Konfirmasi Konsultasi</div>
      <div>Nama: {value.psycholog_username}</div>
      <div>Sesi: {getFormatCall(value.format_call)}</div>
      {location ? <div>Lokasi: {location.name}</div> : null}
      <div>Tanggal: {dayjs(value.date).format("DD MMMM YYYY")}</div>
      <div>Waktu: {value.time_from}</div>
      {props.value.promo ? <div>Promo: {props.value.promo}</div> : null}
      <div className="mt-4">
        Gunakan link ini untuk menyelesaikan pembayaran kamu melalui website:
        <a
          className="text-brand-dark"
          href={getLink(value)}
          target="_blank"
          rel="noreferrer"
        >
          {getLink(value)}
        </a>
      </div>
      <div className="mt-4">
        <span>
          Unduh aplikasi Maxi dan gunakan kode &apos;{" "}
          <span className="font-bold">{value.referral_code}</span>&apos; untuk
          mendapatkan 10,000 Maxi Points untuk memulai perjalanan kamu. Ayo
          mulai mencoba dan lihat bagaimana aplikasi ini dapat meningkatkan
          wellbeing kamu! Link aplikasi:
        </span>
        <a
          className="text-brand-dark"
          href={`${Endpoints.baseUrlLandingUrl}/download-now`}
          target="_blank"
          rel="noreferrer"
        >{`${Endpoints.baseUrlLandingUrl}/download-link`}</a>
      </div>
      <div className="mt-4">*Kode berlaku untuk sekali pakai</div>
    </div>
  );
}

function getFormatCall(formatCall: string) {
  return sessionTypes.find((val) => val.key === formatCall)?.label;
}

function getLink(value: any) {
  if (value.reservation_code) {
    if (value.room_link) {
      return value.room_link;
    }

    return `${Endpoints.baseUrlLandingUrl}/booking/reservation/${value.reservation_code}`;
  }

  return `${Endpoints.baseUrlLandingUrl}/booking/${value.psycholog_code}/${
    value.schedule_detail_code
  }?sessionType=${value.format_call}&promoCode=${value.promo ?? ""}&location=${
    value.location ?? ""
  }`;
}
