import api from "./api";

export const fetchReligion = () => {
	return api
		.get('/v1/members/religion', {
			params: {
				lang: 'id'
			}
		})
		.then((val) => val.data);
};
