import {
  Box,
  Card,
  Container,
  FormControl,
  InputAdornment,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import Select from "@mui/material/Select";
import { ModalBan, ModalDelete } from "components/Modal/ModalSimple";
import { SearchStyle, TabPanelHidden, a11yProps } from "components/Panel";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Iconify from "screens/Layout/components/Iconify";
import {
  checkCalendarToken,
  refreshCalendarToken,
  updateCalendarToken,
  updateSchedule,
} from "service/schedule";
import { getSettingList } from "service/setting";
import {
  updateSpecialistNoticePeriod,
  updateSpecialistStatus,
} from "service/specialist";
import ModalModify from "./components/ModalModify";
import TabActived from "./components/TabActived";
import TabDeleted from "./components/TabDeleted";
import { useNavigate } from "react-router-dom";
import { PopUpWindow } from "helpers";

export default function SpecialistAvailabilityPage() {
  const navigate = useNavigate();
  // Options Table
  const [filterSearch, setFilterSearch] = useState("");
  const [filterMembership, setFilterMembership] = useState("");

  // Header
  const [listMembership, setListMembership] = useState<any[]>([]);

  // Tab Index
  const [tabValue, setTablIndex] = useState(0);

  // Count Label
  const [activedCount, setActivedCount] = useState(0);
  const [deletedCount, setDeletedCount] = useState(0);

  // Force Update
  const [activedForceUpdate, setActivedForceUpdate] = useState(0);
  const [deletedForceUpdate, setDeletedForceUpdate] = useState(0);

  // Modal
  const [isShowModalModify, setShowModalModify] = useState(false);
  const [isShowModalDeleted, setShowModalDeleted] = useState(false);

  // Additional
  const [currentItem, setCurrentItem] = useState<any>({});

  useEffect(() => {
    getSettingList({
      group: "psycholog_level",
    }).then((val) => {
      setListMembership(val.data ?? []);
    });
  }, []);

  useEffect(() => {
    if (!isShowModalModify && !isShowModalDeleted) {
      setCurrentItem({});
    }
  }, [isShowModalModify, isShowModalDeleted]);

  const onEdit = (item: any) => {
    setCurrentItem(item);
    setShowModalModify(true);
  };

  const onDelete = (item: any) => {
    setCurrentItem(item);

    setShowModalDeleted(true);
  };

  const onConfirmDelete = async () => {
    await updateSpecialistStatus({
      psychologCode: currentItem.psycholog_code,
      status: "inactive",
    });

    setShowModalDeleted(false);

    setActivedForceUpdate(Math.random());
    setDeletedForceUpdate(Math.random());

    toast.success("psychologist already inactive");
  };

  const onSubmit = async (val: any) => {
    const data = {
      psychologCode: currentItem.psycholog_code,
      scheduleCode: currentItem.schedule_code,
      dateRange: val.date_range,
      scheduleDay: val.schedule_day,
      timezone: val.timezone,
      formatCall: "both_call",
      location: ["Google Meet"],
    };

    await updateSchedule(data);
    await updateSpecialistNoticePeriod({
      noticePeriod: val.notice_period,
      psychologCode: currentItem.psycholog_code,
    });

    if (val.calendar_sync_status === "inactive") {
      await updateCalendarToken({
        status: "inactive",
        memberCode: currentItem.psycholog_code,
      });

      setSuccess();
    } else if (val.calendar_sync_status === "not_linked") {
      setSuccess();
    } else {
      checkCalendarToken({ memberCode: currentItem.psycholog_code }).then(
        async (val) => {
          if (val.data === null) {
            checkCalendar();
          } else {
            await updateCalendarToken({
              status: "active",
              memberCode: currentItem.psycholog_code,
            });

            setSuccess();
          }
        }
      );
    }
  };

  const checkCalendar = async () => {
    refreshCalendarToken({
      memberCode: currentItem.psycholog_code,
    }).then((val) => {
      const windowName = "LoginGoogle";
      const w = 500;
      const h = 600;
      const y = window.top!.outerHeight / 2 + window.top!.screenY - h / 2;
      const x = window.top!.outerWidth / 2 + window.top!.screenX - w / 2;
      const myWindow = window.open(
        val.data.url,
        windowName,
        `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
      );

      const timer = setInterval(async function () {
        // if (
        //   myWindow?.location.href === "https://landing.ohanaspace.id/" ||
        //   myWindow?.location.href === "https://ohanaspace.id/"
        // ) {
        //   myWindow?.close();
        // }

        if (myWindow?.closed) {
          clearInterval(timer);
          await updateCalendarToken({
            status: "active",
            memberCode: currentItem.psycholog_code,
          });

          setSuccess();
        }
      }, 200);
    });
  };

  const setSuccess = () => {
    setActivedForceUpdate(Math.random());

    toast.success("Jadwal berhasil diubah!");
    setShowModalModify(false);
  };

  return (
    <>
      <Container maxWidth="lg">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4}
        >
          <Box>
            <Typography variant="h4" sx={{ fontSize: "24px" }}>
              Kelola Ketersediaan Psikolog
            </Typography>
          </Box>
        </Stack>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Tabs
              value={tabValue}
              onChange={(_, val) => setTablIndex(val)}
              aria-label="basic tabs example"
            >
              <Tab label={`Aktif (${activedCount})`} {...a11yProps(1)} />
              <Tab label={`Dihapus (${deletedCount})`} {...a11yProps(2)} />
            </Tabs>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            margin: "0",
            paddingTop: "20px",
            paddingBottom: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "250px" }}>
            <SearchStyle
              onChange={(e) => setFilterSearch(e.target.value)}
              placeholder="Cari"
              startAdornment={
                <InputAdornment position="start">
                  <Iconify
                    icon="eva:search-fill"
                    sx={{
                      color: "text.disabled",
                      width: 25,
                      height: 25,
                    }}
                  />
                </InputAdornment>
              }
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            marginBottom: "20px",
          }}
        >
          {/* <FormControl>
            <Select
              displayEmpty
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value)}
              input={<OutlinedInput />}
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                height: "40px",
                fontSize: "14px",
                fontFamily: "Inter-Medium",
              }}
            >
              <MenuItem value="">
                <em>All Status</em>
              </MenuItem>
              {listStatus.map((status) => (
                <MenuItem key={status.label} value={status.value}>
                  <em>{status.label}</em>
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
          {/* <FormControl style={{ marginLeft: "16px" }}>
            <Select
              displayEmpty
              value={filterMembership}
              onChange={(e) => setFilterMembership(e.target.value)}
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                height: "40px",
                fontSize: "14px",
                fontFamily: "Inter-Medium",
              }}
            >
              <MenuItem value="">
                <em>All Membership</em>
              </MenuItem>
              {listMembership.map((dpr) => (
                <MenuItem key={dpr.set_key} value={dpr.set_key}>
                  <em>{dpr.set_label}</em>
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
        </Box>

        <TabPanelHidden value={tabValue} index={0}>
          <Card>
            <TabActived
              search={filterSearch}
              forceUpdate={activedForceUpdate}
              membership={filterMembership}
              onEdit={onEdit}
              onDelete={onDelete}
              onCount={(val) => setActivedCount(val)}
            />
          </Card>
        </TabPanelHidden>
        <TabPanelHidden value={tabValue} index={1}>
          <Card>
            <TabDeleted
              search={filterSearch}
              forceUpdate={deletedForceUpdate}
              membership={filterMembership}
              onCount={(val) => setDeletedCount(val)}
            />
          </Card>
        </TabPanelHidden>

        <ModalModify
          currentItem={currentItem}
          isShow={isShowModalModify}
          onSubmit={onSubmit}
          toggle={() => setShowModalModify(false)}
        ></ModalModify>

        <ModalBan
          isOpen={isShowModalDeleted}
          onClose={() => setShowModalDeleted(false)}
          onDelete={onConfirmDelete}
          title="Apakah Anda yakin ingin menonaktifkan psikolog ini? Mereka tidak akan tersedia di Ohana."
        ></ModalBan>
      </Container>
    </>
  );
}
