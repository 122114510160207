import React from "react";
import { TableCell, TableRow, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { IVoidCallback } from "interfaces/callback";
import dayjs from "dayjs";

export default function SpecialistRateRow(props: {
  row: any;
  onInvoice: IVoidCallback;
  onShowDetail: IVoidCallback;
}) {
  const {
    id,
    transaction_date,
    specialist_phone,
    specialist_email,
    booked_hours,
    earnings,
    status,
  } = props.row;

  return (
    <TableRow key={id} hover>
      <TableCell
        component="th"
        sx={{
          textTransform: "capitalize",
          fontFamily: "Fonts.interRegular",
        }}
      >
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            minWidth: "120px",
          }}
        >
          {dayjs(transaction_date).format("YYYY")}
        </Typography>
      </TableCell>

      <TableCell
        component="th"
        sx={{
          textTransform: "capitalize",
          fontFamily: "Fonts.interRegular",
        }}
      >
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            minWidth: "120px",
          }}
        >
          {dayjs(transaction_date).format("MMMM")}
        </Typography>
      </TableCell>

      <TableCell
        component="th"
        sx={{
          fontFamily: "Fonts.interRegular",
        }}
      >
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            minWidth: "120px",
          }}
        >
          {specialist_email}
        </Typography>
      </TableCell>

      <TableCell
        component="th"
        sx={{
          textTransform: "capitalize",
          fontFamily: "Fonts.interRegular",
        }}
      >
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            minWidth: "120px",
          }}
        >
          {specialist_phone}
        </Typography>
      </TableCell>

      <TableCell
        component="th"
        sx={{
          textTransform: "capitalize",
          fontFamily: "Fonts.interRegular",
        }}
      >
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            minWidth: "120px",
          }}
        >
          {booked_hours}
        </Typography>
      </TableCell>

      <TableCell
        component="th"
        sx={{
          textTransform: "capitalize",
          fontFamily: "Fonts.interRegular",
        }}
      >
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            minWidth: "120px",
          }}
        >
          {earnings}
        </Typography>
      </TableCell>

      <TableCell
        component="th"
        sx={{
          textTransform: "capitalize",
          fontFamily: "Fonts.interRegular",
        }}
      >
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            minWidth: "120px",
          }}
          className="text-blue-500 cursor-pointer"
        >
          <div onClick={props.onShowDetail} className="py-2">
            Lihat Detail
          </div>
        </Typography>
      </TableCell>

      {/* <TableCell align='left' sx={ { textTransform: 'capitalize' } }>
                <Typography variant='subtitle2' noWrap sx={ {
                    fontFamily: 'Fonts.interRegular',
                    width: '80px !important'
                } }>
                    <StatusContainer status={ status }></StatusContainer>
                </Typography>
            </TableCell> */}

      <TableCell align="left" sx={{}}>
        <Button
          variant="contained"
          color="success"
          sx={{
            width: "200px",
            fontSize: "12px",
            textTransform: "none",
          }}
          disableElevation
          onClick={props.onInvoice}
          size="medium"
        >
          Buat Invoice
        </Button>
      </TableCell>
    </TableRow>
  );
}

function StatusContainer(props: { status: string }) {
  if (props.status === "PAID") {
    return (
      <span
        style={{
          textTransform: "uppercase",
          background: "#E5F7EE",
          padding: "4px 12px",
          borderRadius: "10px",
          fontSize: "12px",
          color: "#00AC56",
          fontWeight: "600",
        }}
      >
        {props.status}
      </span>
    );
  }

  return (
    <span
      style={{
        textTransform: "uppercase",
        background: "#E8F4FB",
        padding: "4px 12px",
        borderRadius: "10px",
        fontSize: "12px",
        color: "#2D9CDB",
        fontWeight: "600",
      }}
    >
      {props.status}
    </span>
  );
}
