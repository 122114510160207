export const sessionTypes = [
    {
        key: 'in_person_individual',
        label: 'Face to Face (Individual)'
    }, {
        key: 'in_person_couple',
        label: 'Face to Face (Couple)'
    }, {
        key: 'in_person_family',
        label: 'Face to Face (Family)'
    }, {
        key: 'in_person_child',
        label: 'Face to Face (Child)'
    }, {
        key: 'in_person_hypnotherapy',
        label: 'Face to Face (Hypnotherapy)'
    }, {
        key: 'in_person_speech_therapy',
        label: 'Face to Face (Speech Therapy)'
    }, {
        key: 'in_person_sensory_integration_therapy',
        label: 'Face to Face (Sensory Integration Therapy)'
    }, {
        key: 'online_call_individual',
        label: 'Online Call (Individual)'
    }, {
        key: 'online_call_couple',
        label: 'Online Call (Couple)'
    },
];
