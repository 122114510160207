import { Images } from "constant";
import { LocalStorage } from "helpers";
import React, { useEffect, useRef, useState } from "react";
import { useAppSelector } from "store";
import { processLogin } from "store/auth";
import { Button, Container, LeftColumn, RightColumn } from "../style";

// Import Modal Component
import { ModalDefault } from "../../components/Modal";
import { useModal } from "../../components/Modal/useModal";

// Import Alert Component
import { AlertComponent } from "../../components/Alert";
import { useAlert } from "../../components/Alert/useAlert";

import { useNavigate } from "react-router-dom";

import {
  BoxWrap,
  ForgotPasswordLink,
  HeroLogin,
  Input,
  InputModal,
  Label,
  LabelModal,
  LogoMaxiSmall,
  SubTitle,
  SubTitleModal,
  Title,
} from "./style";

import { faEye, faEyeLowVision } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, CircularProgress } from "@mui/material";
import { green } from "@mui/material/colors";
import { Paths } from "constant";
import { toast } from "react-toastify";

const eye = <FontAwesomeIcon icon={faEye} />;
const eyeLowVision = <FontAwesomeIcon icon={faEyeLowVision} />;

export default function Login() {
  const { loadingGet, error } = useAppSelector((state) => state.auth);
  const [passwordShown, setPasswordShown] = useState(false);
  const [enabledButton, setEnabledButton] = useState(false);
  const [emailInput, setEmailInput] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isAutofill, setIsAutofill] = useState(false);
  const { isShown, toggle } = useModal();
  const { isShownAlert, toggleAlert } = useAlert();

  // Ref
  const emailRef = useRef(null);
  const passRef = useRef(null);

  // Navigation
  const navigate = useNavigate();

  const routeChange = () => {
    const path = Paths.forgotPassword;
    navigate(path);
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const handleSubmitSendEmail = () => {
    routeChange();
  };

  const handleProcessLogin = async () => {
    setEnabledButton(false);

    emailInput.length > 0 ? setEmailError("") : setEmailError("Email is Empty");
    passwordInput.length > 0
      ? setPasswordError("")
      : setPasswordError("Password is Empty");

    const email = emailInput;
    const password = passwordInput;

    const payload = {
      email,
      password,
    };

    processLogin({ body: payload })
      .then(function () {
        const tokenGet = LocalStorage.getToken();

        {
          tokenGet !== "" ? location.replace("/dashboard") : "";
        }
      })
      .catch(function (e) {
        setEnabledButton(true);
        toggleAlert();
        if (e.stat_msg.toString().includes("email")) {
          setEmailError(e.stat_msg);
        }
        if (e.stat_msg.toString().includes("password")) {
          setPasswordError(e.stat_msg);
        }
      });
  };

  useEffect(() => {
    if (emailInput.trim().length > 3 && passwordInput.trim().length > 3) {
      setEnabledButton(true);
    } else {
      setEnabledButton(false);
    }

    setIsAutofill(false);
  }, [passwordInput, emailInput]);

  useEffect(() => {
    setTimeout(() => {
      const isEmailFilled = (emailRef.current! as HTMLElement).matches(
        ":-internal-autofill-selected"
      );
      const isPassFilled = (passRef.current! as HTMLElement).matches(
        ":-internal-autofill-selected"
      );

      setIsAutofill(isEmailFilled && isPassFilled);
    }, 500);
  }, []);

  const contentModalFooter = (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Button
        title="Kirim Lupa Kata Sandi ke Email"
        onClick={handleSubmitSendEmail}
      >
        Kirim
      </Button>
    </Box>
  );

  const contentModal = (
    <>
      <SubTitleModal>
        Masukkan email Anda yang terdaftar untuk mereset kata sandi.
      </SubTitleModal>
      <Box>
        <LabelModal>Email</LabelModal>
        <div className="input-wrapper">
          <InputModal
            type="text"
            name="email"
            placeholder="Masukkan Email Anda!"
          />
        </div>
      </Box>
    </>
  );

  const isEnableButton = isAutofill ? true : enabledButton;

  return (
    <Container bg={Images.bgContainer}>
      <LeftColumn>
        <LogoMaxiSmall alt="maxi-cms" src="/logo192.png" />

        <SubTitle>Selamat Datang!</SubTitle>
        <Title>Masuk ke akun Anda</Title>

        <BoxWrap>
          <Label>Email</Label>
          <div className="input-wrapper">
            <Input
              ref={emailRef}
              id="someinput"
              type="text"
              name="email"
              className={emailError ? "error-outline" : ""}
              onChange={(e) =>
                setEmailInput((e.target as HTMLInputElement).value)
              }
              placeholder="Email atau ID Anda yang terdaftar"
            />
          </div>
          {emailError ? (
            <label className="label-error">{emailError}</label>
          ) : (
            ""
          )}
        </BoxWrap>
        <BoxWrap>
          <Label>Password</Label>
          <div className="pass-wrapper">
            <Input
              ref={passRef}
              type={passwordShown ? "text" : "password"}
              className={passwordError ? "error-outline" : ""}
              name="password"
              placeholder="Password"
              onChange={(e) =>
                setPasswordInput((e.target as HTMLInputElement).value)
              }
            />
            <i
              onClick={togglePassword}
              title="Tampilkan atau Sembunyikan Kata Sandi Anda"
            >
              {" "}
              {passwordShown ? eyeLowVision : eye}{" "}
            </i>
          </div>
          {passwordError ? (
            <label className="label-error">{passwordError}</label>
          ) : (
            ""
          )}
          <ForgotPasswordLink
            title="Klik untuk Lupa Kata Sandi Anda"
            href="#"
            onClick={toggle}
          >
            Lupa Password?
          </ForgotPasswordLink>

          <AlertComponent
            isShownAlert={isShownAlert}
            hide={toggleAlert}
            severity="error"
            content={error.stat_msg}
          />
          <Box sx={{ m: 1, position: "relative" }}>
            <Button
              disabled={!isEnableButton}
              className="btn-primary"
              title="Klik untuk Masuk ke Akun Anda"
              onClick={handleProcessLogin}
            >
              Masuk
            </Button>
            {loadingGet && (
              <CircularProgress
                size={24}
                sx={{
                  color: green[500],
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Box>
        </BoxWrap>
      </LeftColumn>
      <RightColumn>
        <HeroLogin src={String(Images.heroImgLogin)} />
      </RightColumn>

      {/* Modal Component */}
      <ModalDefault
        isShown={isShown}
        title="Lupa Password"
        reset={() => console.log("Clearout")}
        hide={toggle}
        modalFooter={contentModalFooter}
        modalContent={contentModal}
      />
    </Container>
  );
}
