import React, { useRef } from "react";
import { NavLink, Link } from "react-router-dom";
import Iconify from "screens/Layout/components/Iconify";

export default function menuItems(props: {
  isExpand: boolean;
  name;
  subMenus;
  iconClassName;
  onClick;
  to;
}) {
  const subMenu = useRef<HTMLUListElement>(null);

  return (
    <li>
      <Link
        to={props.to}
        className="menu-item my-1 flex cursor-pointer items-center p-3 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-200"
        onClick={props.onClick}
      >
        <div className="mr-4">{props.iconClassName}</div>
        <div className="menu-name">{props.name}</div>
        {props.subMenus && props.subMenus.length > 0 ? (
          <>
            {props.isExpand ? (
              <div className="icon-menu-item active mt-2">
                <Iconify
                  icon={"akar-icons:chevron-down"}
                  sx={{}}
                  width={17}
                  height={17}
                />
              </div>
            ) : (
              <div className="icon-menu-item inactive mt-2">
                <Iconify
                  icon={"akar-icons:chevron-right"}
                  sx={{}}
                  width={17}
                  height={17}
                />
              </div>
            )}
          </>
        ) : (
          ""
        )}
      </Link>
      {props.subMenus && props.subMenus.length > 0 ? (
        <ul
          className={`sub-menu ${props.isExpand ? "active" : ""}`}
          ref={subMenu}
        >
          {props.subMenus.map((menu, index) => (
            <li key={index} className="ml-1">
              <NavLink to={menu.to}>{menu.name}</NavLink>
            </li>
          ))}
        </ul>
      ) : (
        ""
      )}
    </li>
  );
}
