import React from "react";
import { Stack, TableCell, TableRow, Typography } from "@mui/material";
import { Images } from "constant";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import Iconify from "screens/Layout/components/Iconify";

export default function UserTableRow(props: {
  row: any;
  onDelete: () => void;
  onEdit: () => void;
  onResetPassword: () => void;
}) {
  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const { admin_code, name, email, site_role, access, status } = props.row;

  return (
    <TableRow key={admin_code} hover>
      <TableCell component="th" scope="row" padding="normal">
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography variant="subtitle2" noWrap>
            {name}
          </Typography>
        </Stack>
      </TableCell>

      <TableCell align="left">{email}</TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        {!site_role ? (
          "-"
        ) : (
          <span
            style={{
              background: "black",
              color: "#FFFFFF",
              borderRadius: "10px",
              padding: "5px 10px",
            }}
          >
            {site_role}
          </span>
        )}
      </TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        {!access
          ? "-"
          : (access as any[]).map((val) => val.display_name).join(", ")}
      </TableCell>

      <TableCell
        align="left"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={props.onResetPassword}
        >
          <Iconify
            icon="ant-design:reload-outlined"
            sx={{
              verticalAlign: "middle",
              color: "#767E8C",
              marginRight: "5px",
            }}
          />
          <a href="#" style={{ color: "#767E8C" }}>
            Reset Password
          </a>
        </div>

        <Button
          title="Edit"
          onClick={props.onEdit}
          sx={{
            minWidth: "30px",
            width: "40px",
            marginLeft: "10px",
          }}
        >
          <img src={Images.btn_edit} style={{ width: "30px" }} />
        </Button>

        <BootstrapTooltip
          placement="top-start"
          title={status === "active" ? "Inactived Admin" : "Actived Admin"}
        >
          <Button
            title="Inaktif"
            onClick={props.onDelete}
            sx={{
              minWidth: "30px",
              width: "40px",
            }}
          >
            <img src={Images.btn_warning} style={{ width: "30px" }} />
          </Button>
        </BootstrapTooltip>
      </TableCell>
    </TableRow>
  );
}
