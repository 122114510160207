interface Config {
	url: any;
	url_landing: string;
	urlaws: any;
	url_aws_access_key_id: any;
	url_aws_signature_version: any;
	url_aws_region: any;
	url_aws_secret_access_key: any;
	url_aws_secret_my_bucket: any;
	name: string;
	production: boolean;

	// API_KEY For Tiny MCE
	tiny_mce: string;
	airtable_key: string;
	airtable_app: string;
	partner_code: string;
}

const staging: Config = {
	url_landing: process.env.REACT_APP_LANDING_URL!,
	// eslint-disable-next-line no-undef
	url: process.env.REACT_APP_API_URL,
	// eslint-disable-next-line no-undef
	url_aws_access_key_id: process.env.REACT_APP_API_AWS_S3_ACCESS_KEY_ID,
	// eslint-disable-next-line no-undef
	url_aws_signature_version: process.env.REACT_APP_API_AWS_S3_SIGNATURE_VERSION,
	// eslint-disable-next-line no-undef
	url_aws_region: process.env.REACT_APP_API_AWS_S3_REGION,
	// eslint-disable-next-line no-undef
	url_aws_secret_access_key: process.env.REACT_APP_API_AWS_S3_SECRET_ACCESS_KEY,
	// eslint-disable-next-line no-undef
	url_aws_secret_my_bucket: process.env.REACT_APP_API_AWS_S3_MY_BUCKET,
	// eslint-disable-next-line no-undef
	urlaws: process.env.REACT_APP_GCS_URL || process.env.REACT_APP_API_URL_AWS,
	name: "Staging",
	production: false,

	tiny_mce: process.env.REACT_APP_API_TINYMCE_KEY!,
	airtable_key: process.env.REACT_APP_AIRTABLE_KEY!,
	airtable_app: process.env.REACT_APP_AIRTABLE_APP!,

	partner_code: process.env.REACT_APP_PARTNER_CODE ?? '',
};

const production: Config = {
	url_landing: process.env.REACT_APP_LANDING_URL!,
	// eslint-disable-next-line no-undef
	url: process.env.REACT_APP_API_URL,
	// eslint-disable-next-line no-undef
	url_aws_access_key_id: process.env.REACT_APP_API_AWS_S3_ACCESS_KEY_ID,
	// eslint-disable-next-line no-undef
	url_aws_signature_version: process.env.REACT_APP_API_AWS_S3_SIGNATURE_VERSION,
	// eslint-disable-next-line no-undef
	url_aws_region: process.env.REACT_APP_API_AWS_S3_REGION,
	// eslint-disable-next-line no-undef
	url_aws_secret_access_key: process.env.REACT_APP_API_AWS_S3_SECRET_ACCESS_KEY,
	// eslint-disable-next-line no-undef
	url_aws_secret_my_bucket: process.env.REACT_APP_API_AWS_S3_MY_BUCKET,
	// eslint-disable-next-line no-undef
	urlaws: process.env.REACT_APP_GCS_URL || process.env.REACT_APP_API_URL_AWS,
	name: "Production",
	production: true,

	tiny_mce: process.env.REACT_APP_API_TINYMCE_KEY!,
	airtable_key: process.env.REACT_APP_AIRTABLE_KEY!,
	airtable_app: process.env.REACT_APP_AIRTABLE_APP!,

	partner_code: process.env.REACT_APP_PARTNER_CODE ?? '',
};

let config: Config = staging;

if (process.env.REACT_APP_STAGE == "production") {
	config = production;
}

export default {
	...config,
};
