import {
  Box,
  Card,
  Container,
  InputAdornment,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import {
  ModalConfirmPayment,
  ModalDelete,
  ModalRepost,
} from "components/Modal/ModalSimple";
import { a11yProps, SearchStyle, TabPanelHidden } from "components/Panel";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Iconify from "screens/Layout/components/Iconify";
import { deletePromo, repostPromo } from "service/promo";
import { updateRefund } from "service/refund";
import TabApproved from "./components/TabApproved";
import TabDeclined from "./components/TabDeclined";
import TabOutstanding from "./components/TabOutstanding";

export default function MemberSpecialist() {
  // Options Table
  const [filterSearch, setFilterSearch] = useState("");

  // Force Update
  const [outstandingForceUpdate, setOutstandingForceUpdate] = useState(0);
  const [approvedForceUpdate, setApprovedForceUpdate] = useState(0);
  const [declinedForceUpdate, setDeclinedForceUpdate] = useState(0);

  // Count Label
  const [outstandingCount, setOutstandingCount] = useState(0);
  const [approvedCount, setApprovedCount] = useState(0);
  const [declinedCount, setDeclinedCount] = useState(0);

  // Tab Index
  const [tabValue, setTabValue] = useState(0);

  // Modal
  const [isShowModalRefund, setShowModalRefund] = useState(false);

  // Additional
  const [currentItem, setCurrentItem] = useState<any>({});

  useEffect(() => {
    if (!isShowModalRefund) {
      setCurrentItem({});
    }
  }, [isShowModalRefund]);

  const onChangeStatus = (val: any, status: string) => {
    val.status = status;

    setCurrentItem(val);
    setShowModalRefund(true);
  };

  const onConfirmRefund = async () => {
    await updateRefund(currentItem);

    setApprovedForceUpdate(Math.random());
    setDeclinedForceUpdate(Math.random());
    setOutstandingForceUpdate(Math.random());
    setShowModalRefund(false);
  };

  return (
    <>
      <Container maxWidth="lg">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4}
        >
          <Box>
            <Typography variant="h4" sx={{ fontSize: "24px" }}>
              Refund
            </Typography>
          </Box>
        </Stack>

        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Tabs value={tabValue} onChange={(_, val) => setTabValue(val)}>
              <Tab
                label={`Outstanding (${outstandingCount})`}
                {...a11yProps(0)}
              />
              <Tab label={`Disetujui (${approvedCount})`} {...a11yProps(1)} />
              <Tab label={`Ditolak (${declinedCount})`} {...a11yProps(2)} />
            </Tabs>
          </Box>
        </Box>

        <Box
          sx={{
            width: "100%",
            margin: "0",
            paddingTop: "20px",
            paddingBottom: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "250px" }}>
            <SearchStyle
              onChange={(e) => setFilterSearch(e.target.value)}
              placeholder="Cari"
              startAdornment={
                <InputAdornment position="start">
                  <Iconify
                    icon="eva:search-fill"
                    sx={{
                      color: "text.disabled",
                      width: 25,
                      height: 25,
                    }}
                  />
                </InputAdornment>
              }
            />
          </Box>
        </Box>

        <TabPanelHidden value={tabValue} index={0}>
          <Card>
            <TabOutstanding
              search={filterSearch}
              forceUpdate={outstandingForceUpdate}
              onChangeStatus={onChangeStatus}
              onCount={(val) => setOutstandingCount(val)}
            />
          </Card>
        </TabPanelHidden>
        <TabPanelHidden value={tabValue} index={1}>
          <Card>
            <TabApproved
              search={filterSearch}
              forceUpdate={approvedForceUpdate}
              onCount={(val) => setApprovedCount(val)}
              onChangeStatus={onChangeStatus}
            />
          </Card>
        </TabPanelHidden>
        <TabPanelHidden value={tabValue} index={2}>
          <Card>
            <TabDeclined
              search={filterSearch}
              forceUpdate={declinedForceUpdate}
              onCount={(val) => setDeclinedCount(val)}
              onChangeStatus={onChangeStatus}
            />
          </Card>
        </TabPanelHidden>

        <ModalConfirmPayment
          isOpen={isShowModalRefund}
          onClose={() => setShowModalRefund(false)}
          onConfirm={onConfirmRefund}
          title={`Are you sure want ${currentItem.status} this refund?`}
        ></ModalConfirmPayment>
      </Container>
    </>
  );
}
