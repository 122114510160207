import {
  Box,
  DialogActions,
  DialogContent,
  InputAdornment,
  TextField,
} from "@mui/material";
import { ModalSimple } from "components/Modal/ModalSimple";
import { Colors, Endpoints, Fonts, Member } from "constant";
import { sessionTypes } from "constant/Session";
import { IVoidCallback } from "interfaces/callback";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { LabelModal } from "screens/Login/style";
import { Button as ButtonStyle } from "screens/style";
import { utils } from "utils";

interface IRate {
  format_call: string;
  label: string;
  price: any;
  fee: any;
}

const DefaultRates: IRate[] = sessionTypes.map((val) => {
  return {
    format_call: val.key,
    label: val.label,
    fee: 0,
    price: 0,
  };
});

export default function ModalModify(props: {
  currentItem: any;
  isShow: boolean;
  toggle: IVoidCallback;
  onSubmit: (val: any) => void;
}) {
  // Pricing
  const [rates, setRates] = useState([...DefaultRates]);

  const [isEnabled, setIsEnabled] = useState(false);

  const checkFormValid = () => {
    let isEnabled = true;
    for (const item of rates) {
      if (item.price.length === 0 || item.fee.length === 0) {
        isEnabled = false;
        break;
      }
    }

    setIsEnabled(isEnabled);
  };

  useEffect(() => {
    checkFormValid();
  }, [rates]);

  useEffect(() => {
    if (!props.isShow) {
      for (const item of rates) {
        item.fee = 0;
        item.price = 0;
      }
      setRates([...rates]);
    } else {
      const specialistRates = props.currentItem.rates as any[];
      if (!specialistRates) {
        return;
      }

      for (const item of rates) {
        const rate = (specialistRates ?? []).find(
          (val) => val.format_call === item.format_call
        );
        if (rate) {
          item.fee = rate.fee;
          item.price = rate.price;
        }
      }

      setRates([...rates]);

      checkFormValid();
    }
  }, [props.isShow]);

  const onSubmit = async () => {
    setIsEnabled(false);

    const temp = rates;
    for (let index = 0; index < temp.length; index++) {
      temp[index].price = Number(temp[index].price);
      temp[index].fee = Number(temp[index].fee);
    }

    props.onSubmit({
      rates: rates,
      hourly_fee: Number(0),
      hourly_price: Number(0),
    });
  };

  const onChangePrice = (i: number, val: string) => {
    rates[i].price = val;

    setRates([...rates]);
  };

  const onChangeFee = (i: number, val: string) => {
    rates[i].fee = val;

    setRates([...rates]);
  };

  return (
    <ModalSimple
      isShown={props.isShow}
      title={"Edit Specialist Rates"}
      hide={props.toggle}
    >
      <>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box sx={{ overFlowY: "initial !important" }}>
              <div className="modalBodyC">
                <div className="input-wrapper" style={{ margin: "0 0 8px 0" }}>
                  <div className="schedule-cus" style={{ width: "100%" }}>
                    <div
                      className="date-schedule-cus"
                      style={{ width: "100%" }}
                    >
                      <LabelModal
                        style={{
                          margin: "5px 0 10px 0",
                          display: "inline-block",
                        }}
                      >
                        Avatar Profile*
                      </LabelModal>
                      <div className="input-wrapper form-inline">
                        <aside
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                          }}
                        >
                          <Thumbs
                            imageUrl={props.currentItem.profile_url}
                          ></Thumbs>
                        </aside>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="input-wrapper" style={{ margin: "0 0 0 0" }}>
                  <div className="schedule-cus" style={{ width: "100%" }}>
                    <div style={{ width: "100%" }}>
                      <LabelModal
                        style={{
                          margin: "5px 0 10px 0",
                          display: "inline-block",
                        }}
                      >
                        Full Name*
                      </LabelModal>
                      <div className="input-wrapper form-inline">
                        <input
                          disabled
                          type="text"
                          value={props.currentItem.username}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {rates.map((val, i) => {
                  return (
                    <div key={i}>
                      <LabelModal style={{ display: "inline-block" }}>
                        {val.label}
                      </LabelModal>
                      <div
                        className="input-wrapper"
                        style={{ margin: "5px 0 10px 0" }}
                      >
                        <div className="schedule-cus" style={{ width: "100%" }}>
                          <div
                            className="date-schedule-cus"
                            style={{ width: "50%" }}
                          >
                            <LabelModal style={{ display: "inline-block" }}>
                              Price
                            </LabelModal>
                            <TextField
                              sx={{
                                marginTop: "8px",
                                width: "100%",
                                border: "solid 1px Colors.app.GhostGrey",
                                background: Colors.white.default,
                                borderRadius: "5px",
                                color: Colors.app.black,
                                fontWeight: Fonts.weights.w5,
                                fontFamily: "Fonts.interMedium",
                                fontSize: Fonts.sizes.xs,
                              }}
                              type="text"
                              placeholder="Price"
                              onChange={(e) =>
                                onChangePrice(
                                  i,
                                  utils.onlyNumber(e.target.value)
                                )
                              }
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    className="opacity-80"
                                    position="start"
                                  >
                                    Rp
                                  </InputAdornment>
                                ),
                              }}
                              value={val.price}
                            />
                          </div>
                          <div
                            className="time-schedule-cus"
                            style={{ width: "50%" }}
                          >
                            <LabelModal
                              style={{
                                display: "inline-block",
                              }}
                            >
                              Fee
                            </LabelModal>
                            <TextField
                              sx={{
                                marginTop: "8px",
                                width: "100%",
                                border: "solid 1px Colors.app.GhostGrey",
                                background: Colors.white.default,
                                borderRadius: "5px",
                                color: Colors.app.black,
                                fontWeight: Fonts.weights.w5,
                                fontFamily: "Fonts.interMedium",
                                fontSize: Fonts.sizes.xs,
                              }}
                              type="text"
                              placeholder="Fee"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    className="opacity-80"
                                    position="start"
                                  >
                                    Rp
                                  </InputAdornment>
                                ),
                              }}
                              onChange={(e) =>
                                onChangeFee(i, utils.onlyNumber(e.target.value))
                              }
                              value={val.fee}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: "20px 24px !important" }}>
          <ButtonStyle
            className="btn-primary"
            disabled={!isEnabled}
            title="Add New Specialist "
            onClick={onSubmit}
            style={{ marginTop: "30px" }}
          >
            Edit
          </ButtonStyle>
        </DialogActions>
      </>
    </ModalSimple>
  );
}

function Thumbs(props: { imageUrl: string }) {
  const names = props.imageUrl.split("/");
  const name = names[names.length - 1];

  return (
    <Thumb
      {...props}
      name={name}
      url={Endpoints.baseUrlAws + props.imageUrl}
    ></Thumb>
  );
}

function Thumb(props: { name: string; url: string }) {
  return (
    <div
      style={{
        marginBottom: 8,
        marginRight: 8,
      }}
      key={props.name}
    >
      <div
        style={{
          display: "flex",
          minWidth: 0,
          overflow: "hidden",
        }}
      >
        <div
          style={{
            background: "#ffffff",
            width: "55px !important",
            marginTop: "0 !important",
            borderRadius: "100px",
            height: "55px !important",
            padding: "0 !important",
            cursor: "pointer",
          }}
        >
          <div
            style={{
              background: `url(${props.url}) #D3D3D3`,
              backgroundSize: "100%",
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
              width: "55px",
              height: "55px",
              display: "flex",
              alignItems: "center",
              borderRadius: "100px",
              justifyContent: "center",
            }}
          />
        </div>
      </div>
    </div>
  );
}
