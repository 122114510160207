import LocalizedStrings from "react-localization";
import { ILocalizationContent } from "interfaces/translation";
import { utils } from "utils";
import TranslationStoreType from "./actionTypes";

let initialState = new LocalizedStrings({
  en: {
    dashboard: "Beranda",
    flagged: "Flagged",
    daily: "Daily",
    content: "Content",
    report: "Organisation Report",
    organization: "Organisation",
    individual: "Individual",
    specialist: "Specialist",
    controlPanel: "Control Panel",
    notification: "Notification",
    admin: "Admin",
    setting: "Pengaturan",
    billing: "Billing",
  },
});

const translationReducer = (
  state: ILocalizationContent = initialState,
  action: { type: string; payload: any }
) => {
  if (action.type === TranslationStoreType.ChangeLanguage) {
    initialState.setLanguage(action.payload);

    return {
      ...initialState,
    };
  }

  if (action.type === TranslationStoreType.StoreLanguage) {
    initialState.setContent(action.payload);
    initialState.setLanguage(utils.getLanguage());

    return {
      ...initialState,
    };
  }

  return state;
};

export default translationReducer;
