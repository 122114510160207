import api from "./api";

export const fetchScheduleBySpecialist = async (params: {
  year: number;
  month: number;
  specialistCode: string;
  format_call: string;
  location_code: string;
}) => {
  return api
    .get(`/v1/specialists/${params.specialistCode}/schedule/monthly`, {
      params: { ...params },
    })
    .then((val) => val.data);
};

export const fetchMonthlyScheduleBySpecialistV2 = async (params: {
  year: number;
  month: number;
  specialistCode: string;
  format_call: string;
  location_code: string;
}) => {
  return api
    .get(`/v1/specialists/${params.specialistCode}/schedule/monthly_v2`, {
      params: { ...params },
    })
    .then((val) => val.data);
};

export const fetchScheduleFormatCallsBySpecialist = async (params: {
  year: number;
  month: number;
  specialistCode: string;
}) => {
  return api
    .get(`/v1/specialists/${params.specialistCode}/schedule/format_call`, {
      params: params,
    })
    .then((val) => val.data);
};

export const generateSchedules = (params: { memberCode: string }) => {
  return api
    .put(`/v1/specialists/${params.memberCode}/generate`)
    .then((val) => val.data);
};

export const fetchScheduleAvailability = async (params: {
  limit: number;
  page: number;
  keyword?: string;
  status?: string;
  membership?: string;
}) => {
  return api
    .get("/v1/specialists/availability", {
      params: params,
    })
    .then((val) => val.data);
};

export const updateSchedule = async (params: {
  psychologCode: string;
  scheduleCode: string;
  dateRange: number;
  scheduleDay: any[];
  timezone: string;
  formatCall: string;
  location: string[];
}) => {
  return api
    .put(
      `/v1/specialists/${params.psychologCode}/schedule/${params.scheduleCode}`,
      {
        date_range: params.dateRange,
        schedule_day: params.scheduleDay,
        timezone: params.timezone,
        format_call: params.formatCall,
        location: params.location,
      }
    )
    .then((val) => val.data);
};

export const getSchedulesByDate = async (params: {
  specialistCode: string;
  date: string;
  formatCall: string;
  locationCode: string;
}) => {
  return api
    .get(`/v1/specialists/${params.specialistCode}/schedule`, {
      params: {
        status: "active",
        format_call: params.formatCall,
        location_code: params.locationCode,
        date: params.date,
      },
    })
    .then((val) => val.data);
};
export const getSchedulesByDateV2 = async (params: {
  specialistCode: string;
  date: string;
  formatCall: string;
  locationCode: string;
}) => {
  return api
    .get(`/v1/specialists/${params.specialistCode}/schedule_v2`, {
      params: {
        status: "active",
        format_call: params.formatCall,
        location_code: params.locationCode,
        date: params.date,
      },
    })
    .then((val) => val.data);
};


export const checkCalendarToken = async (params: { memberCode: string }) => {
  return api
    .get(`/v1/calendar/sync/token/${params.memberCode}`)
    .then((val) => val.data);
};

export const refreshCalendarToken = async (params: { memberCode: string }) => {
  return api
    .get(`/v1/calendar/sync/refresh/${params.memberCode}`)
    .then((val) => val.data);
};

export const updateCalendarToken = async (params: {
  status: string;
  memberCode: string;
}) => {
  return api
    .put(`/v1/calendar/sync/status/${params.memberCode}`, {
      status: params.status,
    })
    .then((val) => val.data);
};
