import { GlobalStyles } from "constant";
import { LocalStorage } from "helpers";
import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import Router from "router";
import service from "service";
import { store } from "store";
import { refreshJobs } from "store/jobs/actions";
import { storeLanguage } from "store/translation/actions";
import Worker from "Worker";
import Access from "Access";
import "react-toastify/dist/ReactToastify.css";
import "App.css";
import Refresher from "Refresher";
import { QueryClientProvider } from "react-query";
import { queryClient } from "helpers/query";

const App = () => {
  // const initLanguage = async () => {
  //   const resp = await service.getsAirtable("cms");
  //   const lang = {
  //     en: {},
  //     id: {},
  //   };
  //   for (const item of resp.records) {
  //     const field = item.fields;
  //     if (field.key) {
  //       lang["en"][field.key] = field.en;
  //       lang["id"][field.key] = field.id;
  //     }
  //   }

  //   storeLanguage(lang);
  // };

  // useEffect(() => {
  //   if (LocalStorage.getToken() !== null) {
  //     initLanguage();
  //   }
  // }, []);

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <Router />
      </QueryClientProvider>
      <GlobalStyles />
      <ToastContainer />
      <Worker></Worker>
      <Access></Access>
      <Refresher></Refresher>
    </Provider>
  );
};

export default App;
