import React from "react";
import { Stack, TableCell, TableRow, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { Endpoints, Images } from "constant";
import dayjs from "dayjs";
import { IVoidCallback } from "interfaces/callback";
import { sessionTypes } from "constant/Session";
import numeral from "numeral";

const confirmed = "confirmed";
const rescheduled = "rescheduled";
const requestReschedule = "request_reschedule";
const waitingPayment = "waiting_payment";
const done = "done";

const cancelled = "cancelled";
const cancelledBook = "cancelled_book";
const failed = "failed";
const expired = "expired";
// const refund = "refund"

function statusIsConfirmed(status: string) {
  return [confirmed, rescheduled, requestReschedule].includes(status);
}

function statusIsCancelled(status: string) {
  return [cancelled, cancelledBook, failed].includes(status);
}

export default function SessionRow(props: {
  row: any;
  withoutAction?: boolean;
  onEdit?: IVoidCallback;
  onShare?: IVoidCallback;
  onPrecounseling?: IVoidCallback;
  onCounselingNote?: IVoidCallback;
  onOvertime?: IVoidCallback;
  onOvertimeLink?: IVoidCallback;
}) {
  const {
    reservation_code,
    date,
    time_from,
    psycholog_username,
    member_email,
    format_call,
    status,
    overtime,
    location_name,
    promo_code,
    rates,
  } = props.row;

  let overtimeDuration = "";
  let overtimePrice = "";
  let canEditOvertime = true;
  if (overtime) {
    overtimeDuration = `${overtime.duration} minutes`;
    overtimePrice = `Rp ${numeral(overtime.price).format("0,0")}`;

    if (overtime.status !== "created") {
      canEditOvertime = false;
    }
  }

  return (
    <TableRow key={reservation_code} hover>
      <TableCell
        component="th"
        sx={{
          textTransform: "capitalize",
          fontFamily: "Fonts.interRegular",
        }}
      >
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            minWidth: "120px",
          }}
        >
          {dayjs(date).format("MMMM D YYYY")}
        </Typography>
      </TableCell>

      <TableCell
        component="th"
        sx={{
          textTransform: "capitalize",
          fontFamily: "Fonts.interRegular",
        }}
      >
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            minWidth: "120px",
          }}
        >
          {time_from}
          {/* { dayjs(, { format: "HH:mm:ss" }).format('HH:mm A') } */}
        </Typography>
      </TableCell>

      <TableCell component="th" scope="row" padding="normal">
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography
            variant="subtitle2"
            sx={{
              flexWrap: "wrap",
              width: "200px",
              fontFamily: "Fonts.interMedium",
            }}
          >
            {psycholog_username}
          </Typography>
        </Stack>
      </TableCell>

      <TableCell align="left">
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            minWidth: "120px",
          }}
        >
          {member_email}
        </Typography>
      </TableCell>

      <TableCell
        align="left"
        sx={{
          textTransform: "capitalize",
          fontFamily: "Fonts.interRegular",
        }}
      >
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            width: "200px",
            fontFamily: "Fonts.interMedium",
          }}
        >
          {sessionTypes.find((val) => val.key === format_call)?.label}
        </Typography>
      </TableCell>

      <TableCell align="left">
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            minWidth: "120px",
          }}
        >
          {location_name ?? "-"}
        </Typography>
      </TableCell>

      <TableCell align="left">
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            minWidth: "120px",
          }}
        >
          {promo_code ?? "-"}
        </Typography>
      </TableCell>

      <TableCell align="left">
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            minWidth: "120px",
          }}
        >
          Rp{" "}
          {numeral(rates.find((val) => val.format_call === format_call)?.fee)
            .format("0,0")
            .replaceAll(",", ".")}
        </Typography>
      </TableCell>

      <TableCell align="left">
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            minWidth: "120px",
          }}
        >
          Rp{" "}
          {numeral(rates.find((val) => val.format_call === format_call)?.price)
            .format("0,0")
            .replaceAll(",", ".")}
        </Typography>
      </TableCell>

      <TableCell align="left">
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            minWidth: "120px",
          }}
        >
          {overtime ? (
            overtimeDuration
          ) : (
            <div className="text-neutral-secondary">Tidak Ada Overtime</div>
          )}
        </Typography>
      </TableCell>

      <TableCell align="left">
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            minWidth: "120px",
          }}
        >
          {overtime ? (
            overtimePrice
          ) : (
            <div className="text-neutral-secondary">Tidak Ada Overtime</div>
          )}
        </Typography>
      </TableCell>

      <TableCell align="left">
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            minWidth: "120px",
          }}
        >
          {overtime ? (
            <div
              className={`${
                overtimeDuration && overtimePrice
                  ? "text-brand-dark cursor-pointer"
                  : "text-gray-500"
              }`}
              onClick={
                overtimeDuration && overtimePrice
                  ? props.onOvertimeLink
                  : undefined
              }
            >
              Lihat Link
            </div>
          ) : (
            <div className="text-neutral-secondary">Tidak Ada Overtime</div>
          )}
        </Typography>
      </TableCell>

      <TableCell align="left">
        <Typography
          variant="body1"
          noWrap
          sx={{
            minWidth: "120px",
          }}
        >
          <div className="flex">
            {props.withoutAction ? (
              <div
                className={`
                    rounded-full px-4 bg-blue-50 text-blue-500
                    ${
                      statusIsCancelled(status)
                        ? "bg-pink-50 text-pink-500"
                        : "bg-gray-50 text-gray-500"
                    }
                `}
              >
                {statusIsCancelled(status)
                  ? "Pembayaran Dibatalkan"
                  : "Pembayaran Kadaluarsa"}
              </div>
            ) : (
              <div
                className={`
                    rounded-full px-4 bg-blue-50 text-blue-500
                    ${
                      statusIsConfirmed(status)
                        ? "bg-green-50 text-green-700"
                        : ""
                    }
                    ${
                      status === waitingPayment
                        ? "bg-yellow-50 text-yellow-500"
                        : ""
                    }
                    ${status === done ? "bg-green-50 text-green-700" : ""}
                `}
              >
                {statusIsConfirmed(status)
                  ? "Pembayaran Berhasil"
                  : status === done
                  ? "Selesai"
                  : status === waitingPayment
                  ? "Pembayaran Tertunda"
                  : "Permintaan Refund"}
              </div>
            )}
          </div>
        </Typography>
      </TableCell>

      {!props.withoutAction ? (
        <TableCell align="left" sx={{ minWidth: "150px" }}>
          <Button
            onClick={props.onShare}
            title="Share"
            sx={{ minWidth: "auto !important" }}
            className={`${
              statusIsConfirmed(status) ||
              status === done ||
              status === waitingPayment
                ? ""
                : "!hidden"
            }`}
          >
            <img src={Images.btn_share} style={{ width: "30px" }} />
          </Button>
          <Button
            onClick={props.onEdit}
            title="Edit"
            sx={{ minWidth: "auto !important" }}
            className={`${statusIsConfirmed(status) ? "" : "!hidden"}`}
          >
            <img src={Images.btn_edit} style={{ width: "30px" }} />
          </Button>
          <Button
            sx={{ minWidth: "auto !important" }}
            title="Overtime"
            className={`${
              status !== done || !canEditOvertime ? "!hidden" : ""
            }`}
            onClick={props.onOvertime}
          >
            <img src={Images.btn_time} style={{ width: "30px" }} />
          </Button>
        </TableCell>
      ) : null}
    </TableRow>
  );
}
