import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import Select from "@mui/material/Select";
import { ModalConfirmPayment, ModalSimple } from "components/Modal/ModalSimple";
import { PaperComponent } from "components/Modal/Paper";
import { SearchStyle } from "components/Panel";
import Images from "constant/Images";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import { IVoidCallback } from "interfaces/callback";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Iconify from "screens/Layout/components/Iconify";
import {
  getSpecialistTransaction,
  refreshTransaction,
  updateStatusTransaction,
} from "service/specialist";
import { utils } from "utils";
import ModalInvoice from "./components/ModalInvoice";
import TabSpecialist from "./components/TabSpecialist";
import { formatRupiah } from "utils/formatNumber";

export default function DailyWallpaper() {
  dayjs.extend(customParseFormat);
  const listYear = utils.getListYear();
  const listMonth = utils.getListMonth();
  const listStatus = [
    {
      label: "Pending",
      value: "PENDING",
    },
    {
      label: "Paid",
      value: "PAID",
    },
    {
      label: "Overdue",
      value: "OVERDUE",
    },
  ];

  // Filter
  const [filterSearch, setFilterSearch] = useState("");
  const [filterYear, setFilterYear] = useState("");
  const [filterMonth, setFilterMonth] = useState("");
  const [filterStatus, setFilterStatus] = useState("");

  // Modal
  const [isShowInvoice, setShowInvoice] = useState(false);
  const [isShowDetail, setShowDetail] = useState(false);
  const [details, setDetails] = useState<any[]>([]);

  const [isShowPayment, setShowPayment] = useState(false);
  const [currentItem, setCurrentItem] = useState<any>({});

  // Force Update
  const [activedForceUpdate, setActivedForceUpdate] = useState(0);

  useEffect(() => {
    if (!isShowDetail && !isShowInvoice) {
      setDetails([]);
    }
  }, [isShowDetail, isShowInvoice]);

  // Refresh Last Month
  // Because Javascript use 0 based month, we need to update or add month with one
  const onRefreshTransaction = async () => {
    const date = new Date();
    date.setDate(1);
    date.setMonth(date.getMonth() - 1); // Set month to last month

    await refreshTransaction({
      month: date.getMonth() + 1, // make month based on 1 not 0
      year: date.getFullYear(),
    });

    setActivedForceUpdate(Math.random());
    toast.success("specialist transactions already refreshed");
  };

  const onConfirmPayment = async () => {
    const date = dayjs(currentItem.transaction_date);
    await updateStatusTransaction({
      month: Number(date.format("M")),
      year: Number(date.format("YYYY")),
      specialistCode: currentItem.specialist_code,
      status: "PAID",
    });

    setShowPayment(false);
    setActivedForceUpdate(Math.random());
  };

  const onShowDetail = async (val: any) => {
    const date = dayjs(val.transaction_date);
    const res = await getSpecialistTransaction({
      month: Number(date.format("M")),
      year: Number(date.format("YYYY")),
      specialistCode: val.specialist_code,
    });

    setDetails(res.data ?? []);

    setShowDetail(true);
  };

  const onShowPayment = async (val: any) => {
    const date = dayjs(val.transaction_date);
    const res = await getSpecialistTransaction({
      month: Number(date.format("M")),
      year: Number(date.format("YYYY")),
      specialistCode: val.specialist_code,
    });

    setDetails(res.data ?? []);
    setCurrentItem(val);
    setShowInvoice(true);
    // setShowPayment(true);
  };

  return (
    <>
      <Container maxWidth="lg">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4}
        >
          <Box>
            <Typography variant="h4" sx={{ fontSize: "24px" }}>
              Transaksi Psikolog
            </Typography>
          </Box>
        </Stack>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <SearchStyle
            onChange={(e) => setFilterSearch(e.target.value)}
            placeholder="Cari"
            startAdornment={
              <InputAdornment position="start">
                <Iconify
                  icon="eva:search-fill"
                  sx={{
                    color: "text.disabled",
                    width: 25,
                    height: 25,
                  }}
                />
              </InputAdornment>
            }
          />
          <Box sx={{ display: "flex" }}>
            <Button
              className="btn-action-filter-light"
              sx={{ width: "220px" }}
              endIcon={
                <img src={Images.btn_switch} style={{ width: "24px" }} />
              }
              onClick={onRefreshTransaction}
            >
              Perbarui Transaksi
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            margin: "0",
            paddingTop: "20px",
            paddingBottom: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <FormControl
            sx={{
              width: 140,
            }}
          >
            <Select
              displayEmpty
              value={filterYear}
              onChange={(e) => setFilterYear(e.target.value)}
              input={<OutlinedInput />}
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                height: "40px",
                fontFamily: "Inter-Medium",
                fontSize: "14px",
              }}
            >
              <MenuItem value="">
                <em>Semua Tahun</em>
              </MenuItem>
              {listYear.map((val: number) => {
                return (
                  <MenuItem value={val.toString()} key={val}>
                    <em>{val}</em>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl
            sx={{
              width: 140,
              marginLeft: "16px",
            }}
          >
            <Select
              displayEmpty
              value={filterMonth}
              onChange={(e) => setFilterMonth(e.target.value)}
              input={<OutlinedInput />}
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                height: "40px",
                fontFamily: "Inter-Medium",
                fontSize: "14px",
              }}
            >
              <MenuItem value="">
                <em>Semua Bulan</em>
              </MenuItem>
              {listMonth.map((val: number) => {
                return (
                  <MenuItem value={val.toString()} key={val}>
                    <em>{val}</em>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          {/* <FormControl
            sx={{
              width: 160,
              marginLeft: "16px",
            }}
          >
            <Select
              displayEmpty
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value)}
              input={<OutlinedInput />}
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                height: "40px",
                fontSize: "14px",
                fontFamily: "Inter-Medium",
              }}
            >
              <MenuItem value="">
                <em>Semua Status</em>
              </MenuItem>
              {listStatus.map((val, i) => {
                return (
                  <MenuItem value={val.value} key={i}>
                    <em>{val.label}</em>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl> */}
        </Box>

        <TabSpecialist
          search={filterSearch}
          status={filterStatus}
          year={filterYear}
          month={filterMonth}
          forceUpdate={activedForceUpdate}
          onShowDetail={onShowDetail}
          onShowPayment={onShowPayment}
        ></TabSpecialist>

        <ModalConfirmPayment
          isOpen={isShowPayment}
          onClose={() => setShowPayment(false)}
          onConfirm={onConfirmPayment}
        ></ModalConfirmPayment>

        <ModalDetail
          isOpen={isShowDetail}
          onClose={() => setShowDetail(false)}
          transactions={details}
        ></ModalDetail>

        <ModalInvoice
          isShow={isShowInvoice}
          onHide={() => setShowInvoice(false)}
          invoiceNumber={`INV-${leadingZeros(currentItem.id)}`}
          country={currentItem.specialist_country}
          email={currentItem.specialist_email}
          phone={currentItem.specialist_phone}
          username={currentItem.specialist_username}
          amount={currentItem.earnings}
          accountHolder={currentItem.account_holder}
          accountNumber={currentItem.account_number}
          bankName={currentItem.bank_name}
          details={details}
          dateIssued={dayjs(currentItem.created_date).format("DD/MM/YYYY")}
          transactionDate={dayjs(currentItem.created_date).format("MMMM YYYY")}
        ></ModalInvoice>
      </Container>
    </>
  );
}

function leadingZeros(value?: number) {
  const numStr = (value ?? 0).toString();
  const zeroTimes = Math.max(6 - numStr.length, 0);

  return "0".repeat(zeroTimes) + numStr;
}

function ModalDetail(props: {
  isOpen: boolean;
  onClose: IVoidCallback;
  transactions: any[];
}) {
  return (
    <ModalSimple
      isShown={props.isOpen}
      title={"Detail Transaksi"}
      hide={props.onClose}
      width="80%"
    >
      <>
        <DialogContent>
          <div>
            <div className="flex items-center bg-[#F3F5F9] h-[50px] px-4">
              <div className="w-[140px] text-sm">Tanggal Sesi</div>
              <div className="w-[100px] text-sm">Jam Sesi</div>
              <div className="w-[200px] text-sm">Nama</div>
              <div className="w-[180px] text-sm">Tipe Sesi</div>
              <div className="w-[120px] text-sm">Harga</div>
              <div className="w-[120px] text-sm">Pendapatan</div>
              <div className="w-[100px] text-sm">Kode Promo</div>
            </div>
            <div className="px-4">
              {props.transactions.map((val, i) => {
                return (
                  <div className="flex items-center h-[50px]" key={i}>
                    <div className="w-[140px] text-sm">
                      {dayjs(val.date).format("DD MMMM YYYY")}
                    </div>
                    <div className="w-[100px] text-sm">
                      {dayjs(val.time_from, "HH:mm:ss").format("HH:mm A")}
                    </div>
                    <div className="w-[200px] text-sm">{val.member_name}</div>
                    <div className="w-[180px] text-sm">
                      {utils.getSessionType(val.format_call)}
                    </div>
                    <div className="w-[120px] text-sm">
                      {formatRupiah(val.order_amount)}
                    </div>
                    <div className="w-[120px] text-sm">
                      {formatRupiah(parseInt(val.specialist_fee))}
                    </div>
                    <div className="w-[100px] text-sm">{val.promo_code}</div>
                  </div>
                );
              })}
            </div>
          </div>

          <div
            style={{
              width: "100%",
              marginTop: "40px",
            }}
          >
            <Button
              className="w-full btn-action-filter-dark"
              onClick={props.onClose}
            >
              Confirm
            </Button>
          </div>
        </DialogContent>
      </>
    </ModalSimple>
  );
}
