import {
  Box,
  Button,
  Card,
  Container,
  InputAdornment,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import {
  ModalDelete,
  ModalRepost,
  ModalRestore,
} from "components/Modal/ModalSimple";
import { SearchStyle, a11yProps, TabPanelHidden } from "components/Panel";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Iconify from "screens/Layout/components/Iconify";
import { addPromo, deletePromo, repostPromo, updatePromo } from "service/promo";
import ModalModify from "./components/ModalModify";
import TabActived from "./components/TabActived";
import TabDeleted from "./components/TabDeleted";

export default function MemberSpecialist() {
  // Options Table
  const [filterSearch, setFilterSearch] = useState("");

  // Force Update
  const [activedForceUpdate, setActivedForceUpdate] = useState(0);
  const [deletedForceUpdate, setDeletedForceUpdate] = useState(0);

  // Count Label
  const [activedCount, setActivedCount] = useState(0);
  const [deletedCount, setDeletedCount] = useState(0);

  // Tab Index
  const [tabValue, setTabValue] = useState(0);

  // Modal
  const [isShowModalModify, setShowModalModify] = useState(false);
  const [isShowModalDelete, setShowModalDelete] = useState(false);
  const [isShowModalRepost, setShowModalRepost] = useState(false);

  const [discountType, setDiscountType] = useState("flat");

  // Additional
  const [isAddContent, setAddContent] = useState(false);
  const [currentItem, setCurrentItem] = useState<any>({});

  useEffect(() => {
    if (!isShowModalModify && !isShowModalDelete && !isShowModalRepost) {
      setAddContent(true);
      setCurrentItem({});
    }
  }, [isShowModalModify, isShowModalDelete, isShowModalRepost]);

  const onAddFixedPromo = () => {
    setDiscountType("flat");
    setAddContent(true);
    setShowModalModify(true);
  };

  const onAddPercentagePromo = () => {
    setDiscountType("percentage");
    setAddContent(true);
    setShowModalModify(true);
  };

  const onShow = (item: any) => {
    setCurrentItem(item);
    setShowModalModify(true);
    setAddContent(false);
  };

  const onDelete = (item: any) => {
    setCurrentItem(item);

    setShowModalDelete(true);
  };

  const onConfirmDelete = async () => {
    await deletePromo(currentItem);

    setShowModalDelete(false);
    setActivedForceUpdate(Math.random());
    setDeletedForceUpdate(Math.random());

    toast.success("promo already deleted");
  };

  const onEdit = (val: any) => {
    setAddContent(false);
    setCurrentItem(val);
    setShowModalModify(true);
  };

  const onRestore = (val: any) => {
    setCurrentItem(val);
    setShowModalRepost(true);
  };

  const onRestoreAction = async () => {
    await repostPromo({ promo_code: currentItem.promo_code });
    setShowModalRepost(false);

    setActivedForceUpdate(Math.random());
    setDeletedForceUpdate(Math.random());

    toast.success("promo already active");
  };

  const onSubmit = async (val: any) => {
    if (isAddContent) {
      await addPromo(val);
    } else {
      await updatePromo(val);
    }

    setShowModalModify(false);
    setActivedForceUpdate(Math.random());
  };

  return (
    <>
      <Container maxWidth="lg">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4}
        >
          <Box>
            <Typography variant="h4" sx={{ fontSize: "24px" }}>
              Manage Promo Code
            </Typography>
          </Box>
        </Stack>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Tabs value={tabValue} onChange={(_, val) => setTabValue(val)}>
              <Tab label={`Aktif (${activedCount})`} {...a11yProps(0)} />
              <Tab label={`Dihapus (${deletedCount})`} {...a11yProps(1)} />
            </Tabs>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            margin: "0",
            paddingTop: "20px",
            paddingBottom: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "250px" }}>
            <SearchStyle
              onChange={(e) => setFilterSearch(e.target.value)}
              placeholder="Cari"
              startAdornment={
                <InputAdornment position="start">
                  <Iconify
                    icon="eva:search-fill"
                    sx={{
                      color: "text.disabled",
                      width: 25,
                      height: 25,
                    }}
                  />
                </InputAdornment>
              }
            />
          </Box>
          <Box className="flex gap-4">
            <Button
              className="btn-action-filter-dark-w-250"
              onClick={onAddPercentagePromo}
              endIcon={<Iconify icon="eva:plus-fill" sx={{}} />}
            >
              Tambah Promo Persen
            </Button>
            <Button
              className="btn-action-filter-dark-w-250"
              onClick={onAddFixedPromo}
              endIcon={<Iconify icon="eva:plus-fill" sx={{}} />}
            >
              Tambah Promo Fixed
            </Button>
          </Box>
        </Box>

        <TabPanelHidden value={tabValue} index={0}>
          <Card>
            <TabActived
              search={filterSearch}
              forceUpdate={activedForceUpdate}
              onShow={onShow}
              onDelete={onDelete}
              onEdit={onEdit}
              onCount={(val) => setActivedCount(val)}
            />
          </Card>
        </TabPanelHidden>
        <TabPanelHidden value={tabValue} index={1}>
          <Card>
            <TabDeleted
              search={filterSearch}
              forceUpdate={deletedForceUpdate}
              onRestore={onRestore}
              onCount={(val) => setDeletedCount(val)}
            />
          </Card>
        </TabPanelHidden>

        <ModalModify
          currentItem={currentItem}
          isShow={isShowModalModify}
          isEdit={!isAddContent}
          discountType={discountType}
          onSubmit={onSubmit}
          toggle={() => setShowModalModify(false)}
          isAddContent={isAddContent}
        ></ModalModify>

        <ModalDelete
          title="Apakah Anda yakin ingin menghapus promo ini?"
          isOpen={isShowModalDelete}
          onClose={() => setShowModalDelete(false)}
          onDelete={onConfirmDelete}
        ></ModalDelete>

        <ModalRepost
          isOpen={isShowModalRepost}
          onClose={() => setShowModalRepost(false)}
          onChange={onRestoreAction}
          title="Apakah Anda yakin, mengaktifkan promo ini?"
        ></ModalRepost>
      </Container>
    </>
  );
}
