import { Endpoints } from "constant";
import api from "./api";

export const sendInvitations = async (body: any[]) => {
	const resp = await api.post(Endpoints.members + "send-invitations", body);
	return resp.data;
};

export const getMembersCompact = async (params: {
	page: number;
	limit: number;
	organization_code?: string;
}) => {
	const resp = await api.get(Endpoints.members + "compact", {
		params: {
			page: params.page,
			limit: params.limit,
			sort: "",
			organization_code: params.organization_code,
			status: "active",
			keyword: "",
		},
	});

	return resp.data;
};

export const fetchMember = async (params: {
	page: number;
	limit: number;
	keyword?: string;
	status?: string;
	sort?: string;
}) => {
	return api.get("/v1/members", {
		params: {
			page: params.page,
			limit: params.limit,
			sort: params.sort,
			status: params.status ?? "active",
			keyword: params.keyword,
		},
	}).then(val => val.data);
};


export const getMembers = async (params: {
	page: number;
	limit: number;
	role?: string;
	type?: string;
	organization_code?: string;
	keyword?: string;
	status?: string;
}) => {
	const resp = await api.get(Endpoints.members, {
		params: {
			page: params.page,
			limit: params.limit,
			sort: "",
			role_name: params.role,
			organization_code: params.organization_code,
			department_name: "",
			membership_name: "",
			status: params.status ?? "active",
			type: params.type,
			keyword: params.keyword,
		},
	});

	return resp.data;
};

export const updateMemberRole = async (body: {
	member_code: string;
	organization_code: string;
	department_code: string;
	role_name: string;
	status: string;
	is_verified: boolean;
}) => {
	const resp = await api.put(
		`${Endpoints.members}${body.member_code}/role`,
		body
	);
	return resp.data;
};

export const addSpecialist = async (body: {
	username: string;
	email: string;
	birthdate: string;
	gender: string;
	phone: string;
	membership_name: string;
	profile_url: string;
	specialist_type: string;
	bio: string;
	year_of_experience: string;
	location: string;
	expertises: string[];
	language: string[];
}) => {
	const resp = await api.post(Endpoints.members, body);
	return resp.data;
};

export const updateSpecialist = async (body: {
	member_code: string;
	username: string;
	email: string;
	birthdate: string;
	gender: string;
	phone: string;
	membership_name: string;
	profile_url: string;
	specialist_type: string;
	bio: string;
	year_of_experience: string;
	location: string;
	expertises: string[];
	language: string[];
	role_name: string;
	status: string;
	is_verified: boolean;
}) => {
	const resp = await api.put(Endpoints.members + body.member_code, body);
	return resp.data;
};

export const getSalesList = async (params: {
	page?: number;
	order?: string;
	sort?: string;
	limit?: number;
	offset?: string;
	keyword?: string;
}) => {
	const resp = await api.get("/v1/manage-sales", {
		params: {
			page: params.page ?? 1,
			order: params.order,
			sort: params.sort ?? "DESC",
			limit: params.limit ?? 100,
			offset: params.offset,
			keyword: params.keyword,
		},
	});
	return resp.data;
};

export const addSales = async (body: {
	name: string;
	email: string;
	phone: string;
	location: string;
}) => {
	const resp = await api.post("/v1/manage-sales", body);
	return resp.data;
};

export const updateSales = async (params: {
	sales_code: string;
	name: string;
	email: string;
	phone: string;
	location: string;
}) => {
	const resp = await api.put(`/v1/manage-sales/${params.sales_code}`, params);
	return resp.data;
};

export const deleteSales = async (params: { sales_code: string }) => {
	const resp = await api.delete(`/v1/manage-sales/${params.sales_code}`);
	return resp.data;
};

export const addMember = (params: any) => {
	return api.post(`/v1/members`, params).then(val => val.data);
};

export const updateMember = (params: any) => {
	return api.put(`/v1/members/${params.member_code}`, params).then(val => val.data);
};
