import React, { useEffect, useState } from "react";

// @mui
import {
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  InputAdornment,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";

// components
import { AlertComponent } from "components/Alert";
import { useAlert } from "components/Alert/useAlert";
import { Colors, Fonts, Images } from "constant";
import { hooks } from "helpers";
import Iconify from "screens/Layout/components/Iconify";
import { useAppSelector } from "store";
import { addAdmin } from "store/admin";

// component
import Select from "@mui/material/Select";
import { ModalDefault } from "components/Modal";
import { InputModal, LabelModal } from "components/Modal/style";
import { useModal } from "components/Modal/useModal";
import { a11yProps, SearchStyle, TabPanel } from "components/Panel";
import { Button as ButtonStyle } from "screens/style";

import useTable from "components/Table/useTable";
import ActiveTab from "./components/ActiveTab";
import InactiveTabs from "./components/InactiveTabs";

import { ModalDelete, ModalRestore } from "components/Modal/ModalSimple";
import {
  getListAccess,
  updateAdmin,
  updateAdminAccess,
  updateAdminPassword,
} from "service/admin";
import { changeStatus, getAdmin, getAdminCount } from "store/admin";
import { toast } from "react-toastify";

export default function Admin() {
  const { pagination, list } = useAppSelector((state) => state.admin);
  const [tableData, setTableData] = useState(list);

  const [tablePagination, setTablePagination] = useState<any[]>(pagination);
  const [pagePagination, setPagePagination] = useState(1);
  const [labelInactiveTab, setLabelInactiveTab] = useState("");
  const [labelActiveTab, setLabelActiveTab] = useState("");
  const [tabName, setTabName] = useState("active");

  const [value, setValue] = useState(0);
  const [currentItem, setCurrentItem] = useState<any>({});
  const [isAddAdmin, setAddAdmin] = useState(true);

  const { isShown, toggle } = useModal();
  const [qeyword, setKeyword] = useState("");

  const [listAccess, setListAccess] = useState<any[]>([]);
  const [listFilteredAccess, setListFilteredAccess] = useState<any[]>([]);

  const [isShowModalDelete, setShowModalDelete] = useState(false);
  const [isShowModalRestore, setShowModalRestore] = useState(false);

  const [runUseEffect, setRunUseEffect] = useState(false);
  const { isShownAlert, toggleAlert } = useAlert();
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [enabledButton, setEnabledButton] = useState(false);

  const [role, setRole] = useState("");
  const [access, setAccess] = useState<string[]>([]);
  const [emailInput, setEmailInput] = useState("");
  const [emailError, setEmailError] = useState("");
  const [nameInput, setNameInput] = useState("");
  const [nameError, setNameError] = useState("");

  const [generatePassword, setGeneratePassword] = useState("");
  const [addSuccess, setAddSuccess] = useState(false);
  const [pagex, setPagex] = useState(0);
  const [limitTable, setLimitTable] = useState(10);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabName(getTabName(newValue));
    setValue(newValue);
    setLimitTable(10);
    loadDataPagi(0, getTabName(newValue));
  };

  const getTabName = (params) => {
    if (params === 0) {
      return "active";
    } else {
      return "inactive";
    }
  };

  const handleSearch = (q) => {
    const page = pagePagination;
    const limit = 10;
    const is_active = tabName === "active";
    const keyword = q.target.value;
    const payload = {
      page,
      limit,
      is_active,
      keyword,
    };

    getAdmin({ body: payload }).then(function (e) {
      setTablePagination((prevTablePagination) => e);
    });
  };

  const getCountTab = () => {
    getAdminCount()
      .then(function (response) {
        setLabelActiveTab("Aktif (" + response[0].active_count + ")");
        setLabelInactiveTab("Nonaktif (" + response[0].inactive_count + ")");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    if (runUseEffect === false) {
      const page = pagePagination;
      const limit = 10;
      const is_active = true;
      const keyword = qeyword;
      const payload = {
        page,
        limit,
        is_active,
        keyword,
      };
      getAdmin({ body: payload }).then((e: any) => {
        setTablePagination(() => e);
        setRunUseEffect(true);
      });
    }

    setTableData(list);

    if (labelActiveTab === "") {
      getCountTab();
    }
    setRunUseEffect(true);
  }, [list, tablePagination, labelActiveTab, labelInactiveTab]);

  const { page } = useTable();

  const onChangeRowsPerPage = (event: { target: { value: string } }) => {
    changeLoadData(pagex, tabName, parseInt(event.target.value));
  };

  const changeLoadData = (params, stt, limitParams) => {
    setLimitTable(limitParams);
    if (params < 0) {
      setPagex(0);
    } else {
      setPagex(params);
    }

    const page = params + 1;
    const limit = limitParams;
    const is_active = stt === "active";
    const payload = {
      page,
      limit,
      is_active,
    };
    getAdmin({ body: payload }).then(function (e) {
      getCountTab();
      setTablePagination((prevTablePagination) => e);
    });
  };

  const onDeleteAction = () => {
    const payload = {
      ...currentItem,
      id: currentItem.admin_code,
      is_active: false,
    };

    const exec = changeStatus({ body: payload });
    exec
      .then(function () {
        loadDataPagi(pagex, tabName);
        setShowModalDelete(false);
        setCurrentItem({});
      })
      .catch(function (e) {
        console.log(e.stat_msg);
      });
  };

  const onDelete = (params: any) => {
    setShowModalDelete(true);

    setCurrentItem(params);
  };

  const onRestore = (params: any) => {
    setShowModalRestore(true);

    setCurrentItem(params);
  };

  const onRestoreAction = () => {
    const payload = {
      ...currentItem,
      id: currentItem.admin_code,
      is_active: true,
    };

    const exec = changeStatus({ body: payload });
    exec
      .then(function () {
        loadDataPagi(pagex, tabName);
        setShowModalRestore(false);
        setCurrentItem({});
      })
      .catch(function (e) {
        console.log(e.stat_msg);
      });
  };

  const getAnyPage = (params) => {
    const pagar = page < params ? params + page : params - page;

    loadDataPagi(pagar, tabName);
  };

  const validButton = () => {
    if (
      emailInput.length > 0 &&
      nameInput.length > 0 &&
      role.length > 0 &&
      access.length > 0
    ) {
      setEnabledButton(true);
    } else {
      setEnabledButton(false);
    }
  };

  const loadDataPagi = (params, stt) => {
    if (params < 0) {
      setPagex(0);
    } else {
      setPagex(params);
    }

    const page = params + 1;
    const limit = limitTable;
    const is_active = stt === "active";
    const keyword = qeyword;
    const payload = {
      page,
      limit,
      is_active,
      keyword,
    };
    getAdmin({ body: payload }).then((e: any) => {
      getCountTab();
      setTablePagination(() => e);
    });
  };

  const showToast = () => {
    setOpenSnackbar(true);
  };

  useEffect(() => {
    validButton();
  }, [role, emailInput, nameInput, access]);

  useEffect(() => {
    setGeneratePassword(hooks.generatePassword());
  }, [role, limitTable]);

  useEffect(() => {
    const temp: any[] = [];
    for (const item of listAccess) {
      if (item.site_role === role) {
        temp.push(item);
      }
    }

    setListFilteredAccess(temp);
  }, [role]);

  useEffect(() => {
    if (!isShown) {
      resetModal();
    }
  }, [isShown]);

  useEffect(() => {
    getListAccess().then((val) => {
      setListAccess(val.data);
    });
  }, []);

  const onEdit = (val: any) => {
    setEmailInput(val.email);
    setNameInput(val.name);
    setRole(val.site_role);
    setCurrentItem(val);
    setAddAdmin(false);
    toggle();

    const tempAccess: any[] = [];
    for (const item of val.access ?? []) {
      tempAccess.push(item.id);
    }

    setAccess(tempAccess);
  };

  const onResetPassword = async (val: any) => {
    toast.info("updating admin password!");
    const pass = hooks.generatePassword();

    setGeneratePassword(pass);
    await updateAdminPassword({
      code: val.admin_code,
      password: pass,
    });
    toast.success("data already updated!");

    setAddSuccess(true);
    setAddAdmin(false);
    toggle();
  };

  const handleProcessAdd = async () => {
    setEnabledButton(false);

    nameInput.length > 0 ? setNameError("") : setNameError("Name is Empty");
    emailInput.length > 0 ? setEmailError("") : setEmailError("Email is Empty");
    if (isAddAdmin) {
      const payload = {
        name: nameInput,
        email: emailInput,
        password: generatePassword,
        site_role: role,
        access,
      };

      return addAdmin({ body: payload })
        .then(() => {
          setAddSuccess(true);
          loadDataPagi(pagex, tabName);
        })
        .catch((e: any) => {
          toggleAlert();
          setEnabledButton(true);
          setEmailError(e.data.email ? e.data.email : "");
          setNameError(e.data.name ? e.data.name : "");
        });
    }

    const code = currentItem.admin_code;
    updateAdmin({
      code: code,
      is_active: true,
      name: nameInput,
      site_role: role,
    })
      .then(() => {
        return updateAdminAccess({
          code: code,
          access: access.map((val) => Number(val)),
        });
      })
      .then(() => {
        toggle();
        loadDataPagi(pagex, tabName);
        toast.success("data already updated");
      })
      .catch((e) => {
        toggleAlert();
        setEnabledButton(true);
        setEmailError(e.data.email ? e.data.email : "");
        setNameError(e.data.name ? e.data.name : "");
      });
  };

  const resetModal = () => {
    setAddSuccess(false);
    setAddAdmin(true);
    setAccess([]);
    setRole("");
    setCurrentItem({});
    setEmailInput("");
    setNameInput("");
  };

  const contentModalSuccess = (
    <Box>
      <LabelModal style={{ fontSize: "18px" }}>
        Copy this generated password to your admin
      </LabelModal>
      <div
        style={{
          paddingTop: "30px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={Images.successModal} style={{ width: "133px" }} alt="" />
        <Typography
          style={{
            width: "350px",
            fontFamily: `${Fonts.interRegular}`,
            fontWeight: `${Fonts.weights.w6}`,
            textAlign: "center",
            marginTop: "30px",
            background: "#F3F5F9",
            border: "2px dashed #E0E5ED",
            boxSizing: "border-box",
            borderRadius: "5px",
            padding: "14px 49px",
          }}
        >
          {generatePassword}
        </Typography>
      </div>
    </Box>
  );

  const contentModalSuccessFooter = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <ButtonStyle
        className="btn-primary"
        onClick={() => {
          navigator.clipboard.writeText(generatePassword);
          showToast();
          setAddSuccess(false);
          toggle();
          setEnabledButton(false);
        }}
        style={{ marginTop: "30px" }}
      >
        Copy
      </ButtonStyle>
    </Box>
  );

  const contentModal = (
    <Box>
      <LabelModal>Full Name</LabelModal>
      <div className="input-wrapper">
        <InputModal
          type="text"
          name="name"
          onChange={(e) => setNameInput(e.target.value)}
          value={nameInput}
          className={nameError ? "error-outline" : ""}
          placeholder="Full Name"
        />
      </div>
      {nameError ? <label className="label-error">{nameError}</label> : ""}

      <LabelModal>Email Address</LabelModal>
      <div className="input-wrapper">
        <InputModal
          disabled={!isAddAdmin}
          type="text"
          name="email"
          onChange={(e) => setEmailInput(e.target.value)}
          value={emailInput}
          className={emailError ? "error-outline" : ""}
          placeholder="Email Address"
        />
      </div>
      {emailError ? <label className="label-error">{emailError}</label> : ""}
      <AlertComponent
        isShownAlert={isShownAlert}
        hide={toggleAlert}
        severity="error"
        content={emailError}
      />

      <LabelModal>Site Role</LabelModal>
      <div className="input-wrapper mb-4">
        <Select
          displayEmpty
          value={role}
          input={<OutlinedInput />}
          onChange={(e) => {
            setRole(e.target.value);
            setAccess([]);
          }}
          sx={{
            marginTop: "8px",
            width: "100%",
            border: "solid 1px Colors.app.GhostGrey",
            background: Colors.white.default,
            borderRadius: "5px",
            color: Colors.app.black,
            fontWeight: Fonts.weights.w5,
            fontFamily: "Fonts.interMedium",
            fontSize: Fonts.sizes.xs,
          }}
        >
          <MenuItem value="" disabled>
            <em>Pilih Role</em>
          </MenuItem>
          <MenuItem value={"Admin"}>Admin</MenuItem>
          <MenuItem value={"Super Admin"}>Super Admin</MenuItem>
        </Select>
      </div>

      <LabelModal>Access</LabelModal>
      <div className="input-wrapper">
        <Select
          multiple
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          value={access}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return "Choose Access";
            }

            const temp: string[] = [];
            for (const item of selected) {
              for (const filteredAcess of listFilteredAccess) {
                if (filteredAcess.id === item) {
                  temp.push(filteredAcess.display_name);
                  break;
                }
              }
            }

            return temp.join(", ");
          }}
          onChange={(e) => {
            setAccess(e.target.value as string[]);
          }}
          sx={{
            marginTop: "8px",
            width: "100%",
            border: "solid 1px Colors.app.GhostGrey",
            background: Colors.white.default,
            borderRadius: "5px",
            color: Colors.app.black,
            fontWeight: Fonts.weights.w5,
            fontFamily: "Fonts.interMedium",
            fontSize: Fonts.sizes.xs,
          }}
        >
          {listFilteredAccess.map((val: any) => {
            return (
              <MenuItem key={val.id} value={val.id}>
                <Checkbox checked={access.indexOf(val.id) > -1} />
                <ListItemText primary={val.display_name} />
              </MenuItem>
            );
          })}
        </Select>
      </div>
    </Box>
  );

  const contentModalFooter = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <ButtonStyle
        disabled={!enabledButton}
        className="btn-primary"
        title="Add New Admin"
        onClick={handleProcessAdd}
        style={{ marginTop: "30px" }}
      >
        {isAddAdmin ? "Add" : "Edit"}
      </ButtonStyle>
    </Box>
  );

  return (
    <>
      <Container maxWidth="lg">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4}
        >
          <Typography variant="h4" sx={{ fontSize: "24px" }} gutterBottom>
            Manage Admin
          </Typography>
        </Stack>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label={labelActiveTab} {...a11yProps(0)} />
              <Tab label={labelInactiveTab} {...a11yProps(1)} />
            </Tabs>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            margin: "0",
            paddingTop: "20px",
            paddingBottom: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <SearchStyle
              placeholder="Cari"
              onChange={(e) => handleSearch(e)}
              sx={{ height: "40px" }}
              startAdornment={
                <InputAdornment position="start">
                  <Iconify
                    icon="eva:search-fill"
                    sx={{
                      color: "text.disabled",
                      width: 25,
                      height: 25,
                    }}
                  />
                </InputAdornment>
              }
            />
          </Box>
          <Box
            sx={{
              flex: 3,
              textAlign: "right",
            }}
          >
            <Button
              variant="contained"
              onClick={toggle}
              sx={{ width: "180px" }}
              className="btn-action-filter-dark"
              endIcon={<Iconify icon="eva:plus-fill" sx={{}} />}
            >
              Add New Admin
            </Button>
          </Box>
        </Box>
        <TabPanel value={value} index={0}>
          {value === 0 ? (
            <>
              <Card>
                <ActiveTab
                  list={tableData}
                  pagination={tablePagination}
                  getChangeRows={onChangeRowsPerPage}
                  getAnyPage={getAnyPage}
                  onDelete={onDelete}
                  onEdit={onEdit}
                  onResetPassword={onResetPassword}
                />
              </Card>
            </>
          ) : (
            "Empty Tab"
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {value === 1 ? (
            <>
              <Card>
                <InactiveTabs
                  list={tableData}
                  pagination={tablePagination}
                  getChangeRows={onChangeRowsPerPage}
                  getAnyPage={getAnyPage}
                  onRestore={onRestore}
                />
              </Card>
            </>
          ) : (
            "Empty Tab"
          )}
        </TabPanel>
      </Container>

      <ModalDelete
        isOpen={isShowModalDelete}
        onClose={() => setShowModalDelete(false)}
        onDelete={onDeleteAction}
        title="Are you sure want to delete this admin?"
      ></ModalDelete>

      <ModalRestore
        isOpen={isShowModalRestore}
        onClose={() => setShowModalRestore(false)}
        onChange={onRestoreAction}
        title="Are you sure want to restore this admin?"
      ></ModalRestore>

      <ModalDefault
        isShown={isShown}
        title={
          addSuccess === true
            ? "Successfully Added Admin"
            : isAddAdmin
            ? "Add New Admin"
            : "Edit Admin"
        }
        hide={toggle}
        reset={() => resetModal()}
        modalContent={addSuccess === true ? contentModalSuccess : contentModal}
        modalFooter={
          addSuccess === true ? contentModalSuccessFooter : contentModalFooter
        }
      />
    </>
  );
}
