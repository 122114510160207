import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from "@mui/material";
import { TableEmptyRows, TableHeadCustom } from "components/Table";
import useTable from "components/Table/useTable";
import React, { useEffect, useState } from "react";
import LocationRow from "sections/@dashboard/location";
import PromoRow from "sections/@dashboard/promo";
import { fetchRooms } from "service/location";
import { getListPromo } from "service/promo";
import { utils } from "utils";

const TABLE_HEAD = [
  {
    id: "name",
    label: "NAMA LOKASI",
    alignRight: false,
  },
  {
    id: "address",
    label: "ALAMAT LOKASI",
    alignRight: false,
  },
  {
    id: "discount_type",
    label: "NAMA RUANGAN",
  },
  {
    id: "discount",
    label: "TIPE SESI",
    alignRight: false,
  },
  { id: "act" },
];

export default function TabDeleted(props: {
  search: string;
  forceUpdate: number;
  onCount: (val: number) => void;
  onRestore: (val: any) => void;
}) {
  const [data, setData] = useState<any[]>([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);

  const { onSort } = useTable();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("promo_code");

  useEffect(() => {
    fetchRoom(page, limit, props.search);
  }, [props.search, props.forceUpdate, page, limit]);

  const onRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const dataFiltered = utils.applySortFilter({
    data: data,
    comparator: utils.getComparator(order, orderBy),
  });

  const onChangeRowPerPage = (val) => {
    fetchRoom(0, val, props.search);
  };

  const onChangePage = (val) => {
    fetchRoom(val, limit, props.search);
  };

  const fetchRoom = async (page: number, limit: number, search: string) => {
    const resp = await fetchRooms({
      page: page +1,
      limit: limit,
      keyword: search,
      status: "inactive",
    });

    const pagination = resp.pagination;

    setLimit(pagination.limit);
    setPage(pagination.page - 1);
    setData(resp.data ?? []);

    setCount(pagination.count);
    console.log(pagination);
    if (props.onCount) {
      props.onCount(pagination.count);
    }
  };

  return (
    <div>
      <TableContainer
        sx={{
          minWidth: 800,
          position: "relative",
        }}
      >
        <Table size="medium">
          <TableHeadCustom
            headLabel={TABLE_HEAD}
            rowCount={data.length}
            orderBy={orderBy}
            onRequestSort={onRequestSort}
            onSort={onSort}
            order={order}
          />

          <TableBody>
            {dataFiltered.map((row, index) => {
              const labelIdx = `test-${index}`;

              return (
                <LocationRow
                  key={row.member_code}
                  row={{
                    ...row,
                    status: "inactive",
                  }}
                  labelId={labelIdx}
                  onRestore={() => props.onRestore(row)}
                />
              );
            })}

            <TableEmptyRows height={72} emptyRows={data.length} />
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ position: "relative" }}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          labelRowsPerPage="Item per halaman: "
          count={count}
          rowsPerPage={limit}
          page={page}
          onPageChange={(_, pg) => onChangePage(pg)}
          onRowsPerPageChange={(e) => onChangeRowPerPage(e.target.value)}
        />
      </Box>
    </div>
  );
}
