const removeEmoji = (text?: string) => {
  if (!text) {
    return "";
  }

  return text
    .replace(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
      ""
    )
    .replace(/\s+/g, " ")
    .trim();
};

const getSessionType = (text: string) => {
  const IN_PERSON_CHILD = "in_person_child";
  const IN_PERSON_COUPLE = "in_person_couple";
  const IN_PERSON_FAMILY = "in_person_family";
  const IN_PERSON_INDIVIDUAL = "in_person_individual";
  const IN_PERSON_HYPNOTHERAPY = "in_person_hypnotherapy";
  const IN_PERSON_SI_THERAPY = "in_person_sensory_integration_therapy";
  const IN_PERSON_SPEECH_THERAPY = "in_person_speech_therapy";
  const ONLINE_CALL_INDIVIDUAL = "online_call_individual";
  const ONLINE_CALL_COUPLE = "online_call_couple";

  if (text === IN_PERSON_INDIVIDUAL) {
    return "Face to face (Individual)";
  } else if (text === IN_PERSON_COUPLE) {
    return "Face to face (Couple)";
  } else if (text === IN_PERSON_FAMILY) {
    return "Face to face (Family)";
  } else if (text === IN_PERSON_CHILD) {
    return "Face to face (Child)";
  } else if (text === IN_PERSON_HYPNOTHERAPY) {
    return "Face to face (Hypnotherapy)";
  } else if (text === IN_PERSON_SI_THERAPY) {
    return "Face to face (SI Therapy)";
  } else if (text === IN_PERSON_SPEECH_THERAPY) {
    return "Face to face (Speech Therapy)";
  } else if (text === ONLINE_CALL_INDIVIDUAL) {
    return "Online Call (Individual)";
  } else if (text === ONLINE_CALL_COUPLE) {
    return "Online Call (Couple)";
  } else {
    return "Face to face (Individual)";
  }
};

const lastDate = () => {
  let currentDate = new Date();
  let nextDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);

  return nextDate.getDate();
};

const delay = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const maxIndex = (val: number[]) => {
  if (val.length === 0) return -1;

  let index = 0;
  for (let i = 0; i < val.length; i++) {
    if (val[i] > val[index]) {
      index = i;
    }
  }

  return index;
};

const getLanguage = () => {
  return localStorage.getItem("__lang") ?? "id";
};

const setLanguage = (lang: string) => {
  localStorage.setItem("__lang", lang);
};

const filterOrganization = (data: any[]) => {
  const exist: { [key: string]: boolean } = {};
  const res: any[] = [];

  for (const item of data) {
    if (exist[item.organization_code]) {
      continue;
    }

    exist[item.organization_code] = true;
    res.push(item);
  }

  return res;
};

const isAccessExist = (access: any[], val: string) => {
  for (const item of access) {
    if (item.access === val || item.access === "ALL") {
      return true;
    }
  }

  return false;
};

const isEmail = (email: string) => {
  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return regex.test(email);
};

const textToImage = (elementId: string, text: string) => {
  const width = 720;
  const height = 480;

  const canvas = document.getElementById(elementId) as HTMLCanvasElement;
  const context = canvas?.getContext("2d");

  if (context) {
    context.clearRect(0, 0, width, height);

    context.fillStyle = "rgba(0, 0, 0, 0)";
    context.fillRect(0, 0, width, height);

    context.textAlign = "center";
    context.textBaseline = "middle";
    context.fillStyle = "rgba(255, 255, 255, 1)";
    context.font = "46px Inter-Regular";

    drawMultilineText(context, text, {
      rect: {
        x: 360,
        y: 0,
        width: 580,
        height: 480,
      },
      font: "Inter-Regular",
      verbose: true,
      lineHeight: 1.9,
      minFontSize: 36,
      maxFontSize: 36,
    });

    return context.canvas.toDataURL();
  }
};

const drawMultilineText = (
  ctx: CanvasRenderingContext2D,
  text: string,
  opts: {
    rect?: any;
    font?: any;
    verbose?: any;
    lineHeight?: any;
    minFontSize?: any;
    maxFontSize?: any;
    stroke?: any;
    logFunction?: any;
  }
) => {
  // Default options
  if (!opts) opts = {};
  if (!opts.font) opts.font = "Inter-Regular";
  if (typeof opts.stroke === "undefined") opts.stroke = false;
  if (typeof opts.verbose === "undefined") opts.verbose = false;
  if (!opts.rect)
    opts.rect = {
      x: 0,
      y: 0,
      width: ctx.canvas.width,
      height: ctx.canvas.height,
    };
  if (!opts.lineHeight) opts.lineHeight = 1.1;
  if (!opts.minFontSize) opts.minFontSize = 30;
  if (!opts.maxFontSize) opts.maxFontSize = 100;
  if (!opts.logFunction)
    opts.logFunction = function (message: any) {
      console.log(message);
    };

  const words = text.split(" ");
  // if (opts.verbose) opts.logFunction('Text contains ' + words.length + ' words');
  let lines: any[] = [];
  let y: number; // New Line

  // Finds max font size  which can be used to print whole text in opts.rec

  let lastFittingLines: any[] = []; // declaring 4 new variables (addressing issue 3)
  let lastFittingFont: any;
  let lastFittingY = 0;
  let lastFittingLineHeight = 0;
  let fontSize = opts.minFontSize;

  for (fontSize; fontSize <= opts.maxFontSize; fontSize++) {
    // Line height
    const lineHeight = fontSize * opts.lineHeight;

    // Set font for testing with measureText()
    // ctx.font = ' ' + fontSize + 'px ' + opts.font;

    // Start
    const x = opts.rect.x;
    y = lineHeight; // modified line        // setting to lineHeight as opposed to fontSize (addressing issue 1)
    lines = [];
    let line = "";

    // Cycles on words

    for (let n = 0; n < words.length; n++) {
      // Add next word to line
      const linePlus = line + words[n] + " ";
      // If added word exceeds rect width...
      if (ctx.measureText(linePlus).width > opts.rect.width) {
        // ..."prints" (save) the line without last word
        lines.push({
          text: line,
          x: x,
          y: y,
        });
        // New line with ctx last word
        line = words[n] + " ";
        y += lineHeight;
      } else {
        // ...continues appending words
        line = linePlus;
      }
    }

    // "Print" (save) last line
    lines.push({
      text: line,
      x: x,
      y: y,
    });

    // If bottom of rect is reached then breaks "fontSize" cycle

    if (y > opts.rect.height) break;

    lastFittingLines = lines; // using 4 new variables for 'step back' (issue 3)
    // lastFittingFont = ctx.font;
    lastFittingY = y;
    lastFittingLineHeight = lineHeight;
  }

  lines = lastFittingLines; // assigning last fitting values (issue 3)
  // ctx.font = lastFittingFont;
  // if (opts.verbose) opts.logFunction('Font used: ' + ctx.font);
  const offset =
    opts.rect.y -
    lastFittingLineHeight / 2 +
    (opts.rect.height - lastFittingY) / 2; // modifying calculation (issue 2)
  // Fill or stroke
  for (const line of lines)
    if (opts.stroke) ctx.strokeText(line.text.trim(), line.x, line.y + offset);
    // modified line
    else ctx.fillText(line.text.trim(), line.x, line.y + offset); // modified line

  // Returns font size
  return fontSize;
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(props: { data: any[]; comparator }) {
  const stabilizedThis = props.data.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = props.comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

function getListYear() {
  const date = new Date();
  const listYear: number[] = [];

  for (let year = 2020; year <= date.getFullYear(); year++) {
    listYear.push(year);
  }

  return listYear.reverse();
}

function getListMonth() {
  const listMonth: number[] = [];
  for (let year = 1; year <= 12; year++) {
    listMonth.push(year);
  }

  return listMonth;
}

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function onlyNumber(val: string) {
  return val.replace(/\D/g, "");
}

const InvalidLocationCode = 'LOC---------';
const OnlineLocationCode = '';

export const utils = {
  lastDate,
  delay,
  maxIndex,
  setLanguage,
  getLanguage,
  filterOrganization,
  isAccessExist,
  isEmail,
  textToImage,
  getComparator,
  applySortFilter,
  getListYear,
  getListMonth,
  removeEmoji,
  capitalizeFirstLetter,
  onlyNumber,
  getSessionType,
  InvalidLocationCode,
  OnlineLocationCode,
};
