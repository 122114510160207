import React from "react";
import { Box, Button, TableCell, TableRow, Typography } from "@mui/material";
import { Images } from "constant";
import { sessionTypes } from "constant/Session";

export default function LocationRow(props: {
  row: any;
  labelId: any;
  onEdit?: () => void;
  onDelete?: () => void;
  onRestore?: () => void;
}) {
  const { name, address, room_name, session_type, format_calls } = props.row;

  return (
    <TableRow hover key={props.labelId} role="checkbox" tabIndex={-1}>
      <TableCell align="left">
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "150px !important",
          }}
        >
          {name}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "150px !important",
          }}
        >
          {address ?? "-"}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "150px !important",
          }}
        >
          {room_name ?? "-"}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        <div className="flex flex-wrap !max-w-xs overflow-hidden gap-1">
          {(format_calls ?? "").split(",").map((val) => {
            return (
              <span
                key={val}
                style={{
                  textTransform: "uppercase",
                  background: "#E8F8F0",
                  padding: "4px 12px",
                  borderRadius: "10px",
                  fontSize: "12px",
                  color: "#87CCCC",
                }}
              >
                {sessionTypes.find((e) => e.key === val)?.label}
              </span>
            );
          })}
        </div>
      </TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        <Box sx={{ width: "150px", display: "flex" }}>
          {props.onEdit ? (
            <Button
              title="Edit"
              onClick={props.onEdit}
              sx={{ minWidth: "auto !important" }}
            >
              <img src={Images.btn_edit} style={{ width: "30px" }} />
            </Button>
          ) : null}

          {props.onDelete ? (
            <Button
              title="Delete"
              onClick={props.onDelete}
              sx={{ minWidth: "auto !important" }}
            >
              <img src={Images.btn_delete} style={{ width: "30px" }} />
            </Button>
          ) : null}

          {props.onRestore ? (
            <Button
              title="Restore"
              onClick={props.onRestore}
              sx={{ minWidth: "auto !important" }}
            >
              <img src={Images.btn_repost_act} style={{ width: "30px" }} />
            </Button>
          ) : null}
        </Box>
      </TableCell>
    </TableRow>
  );
}
