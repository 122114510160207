import React from "react";
import { Box, Button, TableCell, TableRow, Typography } from "@mui/material";
import dayjs from "dayjs";
import { Images } from "constant";

export default function RefundRow(props: {
  row: any;
  labelId: any;
  onApproved?: () => void;
  onDeclined?: () => void;
}) {
  const { email, title, requested_date, purchased_date } = props.row;

  return (
    <TableRow hover key={props.labelId} role="checkbox" tabIndex={-1}>
      <TableCell align="left">
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "150px !important",
          }}
        >
          {email}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
          }}
        >
          Counseling ( {title} )
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "150px !important",
          }}
        >
          {dayjs(purchased_date).format("MMMM DD YYYY")}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "150px !important",
          }}
        >
          {dayjs(requested_date).format("MMMM DD YYYY")}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        <Box sx={{ width: "200px", display: "flex" }}>
          {props.onApproved ? (
            <Button
              onClick={props.onApproved}
              title="Setujui"
              sx={{ minWidth: "auto !important" }}
            >
              <img src={Images.btn_check_act} style={{ width: "30px" }} />
            </Button>
          ) : null}
          {props.onDeclined ? (
            <Button
              onClick={props.onDeclined}
              title="Tolak"
              sx={{ minWidth: "auto !important" }}
            >
              <img src={Images.btn_x_act} style={{ width: "30px" }} />
            </Button>
          ) : null}
        </Box>
      </TableCell>
    </TableRow>
  );
}
