import { Close } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  DialogActions,
  DialogContent,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ModalSimple } from "components/Modal/ModalSimple";
import { Colors, Endpoints, Fonts } from "constant";
import { sessionTypes } from "constant/Session";
import dayjs from "dayjs";
import { Add } from "iconsax-react";
import { IVoidCallback } from "interfaces/callback";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { LabelModal } from "screens/Login/style";
import { Button as ButtonStyle } from "screens/style";
import { fetchLocationByFormatCallV2 } from "service/location";
import { utils } from "utils";

interface ScheduleTimeSlot {
  format_calls: string;
  time_from: string;
  time_to: string;
  location_code: string[];
}
interface ScheduleDay {
  day: string;
  time: ScheduleTimeSlot[];
}

interface LocationRoomData {
  location_code: string;
  name: string;
  address: string;
  room_name: string;
  session_type: string; // format_calls
  status: string;
}

interface LocationDays {
  [day: string]: LocationRoomData[][];
}

async function listLocationByFormatCallV2(
  format_call: string
): Promise<LocationRoomData[]> {
  const format_calls = format_call
    .split(",")
    .filter((x) => x.includes("in_person"));
  if (format_calls.length > 0) {
    return fetchLocationByFormatCallV2({
      format_call: format_calls.join(","),
    }).then((res) => {
      return res.data as LocationRoomData[];
    });
  }
  return [];
}

export default function ModalModify(props: {
  currentItem: any;
  isShow: boolean;
  toggle: IVoidCallback;
  onSubmit: (val: any) => void;
}) {
  // Form
  const [specialistTitle, setSpecialistTitle] = useState("");
  const [profileUrl, setProfileUrl] = useState("");
  const [username, setUsername] = useState("");
  const [calendarSync, setCalendarSync] = useState("");
  const [timezone, setTimezone] = useState("");
  const [dateRange, setDateRange] = useState("");
  const [noticePeriod, setNoticePeriod] = useState("");

  // Schedule Days
  const [schedules, setSchedules] = useState<any>({});
  const [locationSchedule, setLocationSchedule] = useState<any>();

  //const [isEnabled, setEnabled] = useState(false);

  const checkFormValid = () => {
    const isFieldValid =
      dateRange.length > 0 && noticePeriod.length > 0 && timezone != "";
    const isScheduleExist = Object.keys(schedules || {}).length > 0;

    // schedules  is not ScheduleDay, but similar, wtf!
    //console.log(schedules);

    const missingLocations = Object.keys(schedules || {}).filter((day) => {
      const scheduleTime = schedules[day] as ScheduleTimeSlot[];
      let hasInvalid = 0;
      let timebefore = "00:00";
      for (let timeslot of scheduleTime) {
        if (!timeslot.format_calls) {
          hasInvalid++;
          break;
        }
        if (timeslot.format_calls?.includes("in_person")) {
          if (!timeslot.location_code) {
            hasInvalid++;
            break;
          }
          if (timeslot.location_code?.length == 0) {
            hasInvalid++;
            break;
          }
        }
        if (timeslot.time_from < timebefore) {
          hasInvalid++;
          break;
        }
        if (timeslot.time_from > timeslot.time_to) {
          hasInvalid++;
          break;
        }
        timebefore = timeslot.time_to;
      }
      return hasInvalid > 0;
    });

    return isFieldValid && isScheduleExist && missingLocations.length == 0;
  };

  const isEnabled = checkFormValid();

  /*useEffect(() => {
    checkFormValid();
  }, [dateRange, noticePeriod, schedules]);
  */

  useEffect(() => {
    if (!props.isShow) {
      setProfileUrl("");
      setUsername("");
      setSpecialistTitle("");
      setCalendarSync("");
      setTimezone("");
      setNoticePeriod("");
      setSchedules({});
    } else {
      const item = props.currentItem;
      const scheduleDays = item.schedule_day ?? [];
      const schedule: any = {};

      initLocation();

      for (const item of scheduleDays) {
        const dayName = item.day as string;
        const times: any[] = [];

        for (const time of item.time) {
          const timeFroms = time.time_from.split(":");
          const timeTos = time.time_to.split(":");
          const timeFrom = new Date();
          timeFrom.setHours(Number(timeFroms[0]), Number(timeFroms[1]), 0, 0);

          const timeTo = new Date();
          timeTo.setHours(Number(timeTos[0]), Number(timeTos[1]), 0, 0);
          times.push({
            time_from: timeFrom,
            time_to: timeTo,
            format_calls: time.format_calls,
            location_code: time.location_code,
          });
        }

        schedule[dayName.toLocaleLowerCase()] = times;
      }

      setProfileUrl(item.psycholog_profile_url ?? "");
      setUsername(item.psycholog_username ?? "");
      setSpecialistTitle(item.psycholog_title ?? "");
      setCalendarSync(
        item.calendar_sync_status !== undefined
          ? item.calendar_sync_status === "not_linked"
            ? "not_linked"
            : item.calendar_sync_status
          : "not_linked"
      );
      setTimezone(item.timezone);
      setNoticePeriod(item.notice_period.toString());
      setDateRange(item.date_range.toString());
      setSchedules(schedule);
    }
  }, [props.isShow]);

  const [isLoading, setIsLoading] = useState(false);

  const initLocation = async () => {
    setIsLoading(true);
    const scheduleDays: ScheduleDay[] = props.currentItem.schedule_day ?? [];
    const location: LocationDays = {};
    for (const item of scheduleDays) {
      const dayName = item.day as string;
      const times: LocationRoomData[][] = [];
      for (const [_i, time] of item.time.entries()) {
        times.push(await listLocationByFormatCallV2(time.format_calls));
      }

      location[dayName.toLocaleLowerCase()] = times;
    }

    setLocationSchedule(location);
    setIsLoading(false);
  };

  const onSubmit = async (calendarStatus: string) => {
    if (Object.keys(schedules).length == 0) {
      toast.warn("input the schedules first!");
    }

    //setEnabled(false);

    const scheduleDays: any[] = [];
    for (const key in schedules) {
      if (Object.prototype.hasOwnProperty.call(schedules, key)) {
        const items = schedules[key];
        const times: any[] = [];

        for (const item of items) {
          times.push({
            time_from: dayjs(item.time_from as Date).format("HH:mm"),
            time_to: dayjs(item.time_to as Date).format("HH:mm"),
            format_calls: item.format_calls,
            location_code: item.location_code,
          });
        }

        scheduleDays.push({
          day: utils.capitalizeFirstLetter(key),
          time: times,
        });
      }
    }

    props.onSubmit({
      date_range: dateRange,
      timezone: timezone,
      schedule_day: scheduleDays,
      notice_period: Number(noticePeriod),
      calendar_sync_status: calendarStatus,
    });
  };

  const onAddSchedule = (day: string) => {
    if (!Array.isArray(schedules[day])) schedules[day] = [];

    let timeFrom: Date, timeTo: Date;

    if (schedules[day].length == 0) {
      timeFrom = new Date();
      timeFrom.setHours(8, 0, 0);

      timeTo = new Date();
      timeTo.setHours(16, 0, 0);
    } else {
      const lastTime = schedules[day].slice(-1).pop();
      const timeFroms = dayjs(lastTime.time_to as Date)
        .format("HH:mm")
        .split(":");
      const firstHour = Number(timeFroms[0].toString());

      timeFrom = new Date();
      timeFrom.setHours(firstHour, Number(timeFroms[1].toString()), 0, 0);

      const secondHour = firstHour + 3 > 24 ? 24 : firstHour + 3;
      timeTo = new Date();
      timeTo.setHours(secondHour, 0, 0, 0);
    }

    const times: any[] = schedules[day] ?? [];
    times.push({
      time_from: timeFrom,
      time_to: timeTo,
    });

    setSchedules({
      ...schedules,
      [day]: times,
    });
  };

  const onChangeSchedule = (day: string, index: number, newValue: any) => {
    const times: any[] = schedules[day] ?? [];
    if (times.length <= index) {
      return toast.error("invalid schedules. please restart!");
    }

    times[index] = newValue;
    setSchedules({
      ...schedules,
      [day]: times,
    });
  };

  const onRemoveSchedule = (day: string, index: number) => {
    let times: any[] = schedules[day] ?? [];
    if (times.length <= index) {
      return toast.error("invalid schedules. please restart!");
    }

    times.splice(index, 1);
    setSchedules({
      ...schedules,
      [day]: times,
    });
  };

  const onChangeScheduleLocation = async (
    day: string,
    index: number,
    format_call: string
  ) => {
    const location: LocationDays = locationSchedule;
    if (!location[day]) {
      location[day] = [];
    }
    // times
    if (location[day].length < index + 1) {
      for (let i = location[day].length; i < index + 1; i++)
        location[day].push([]);
    }
    //const location: any[] = locationSchedule[day][index] ?? [];

    setIsLoading(true);

    const newLocationSchedule: LocationDays = {
      ...location,
      [day]: [
        ...location[day].slice(0, index),
        await listLocationByFormatCallV2(format_call),
        ...location[day].slice(index + 1),
      ],
    };

    //console.log(newLocationSchedule);

    setLocationSchedule(newLocationSchedule);

    /**
    let found = location.some((obj) => {
      if (Object.keys(obj)[0] === index.toString()) {
        delete obj[index.toString()];
      }
    });
    if (!found) {
      location.push(_locationArray);
    }
    console.log(location);



    setLocationSchedule({
      ...locationSchedule,
      [day]: [
        ...locationSchedule[day].slice(0,index),
        {
          ...locationSchedule[day][index]
        },
        ...locationSchedule[day].slice(index+1),
      ],
    });
    **/
    setIsLoading(false);
  };

  return (
    <ModalSimple
      isShown={props.isShow}
      title="Ubah Ketersediaan Psikolog"
      hide={props.toggle}
      width="840px"
    >
      <>
        <DialogContent>
          {/* CONTENT */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Box sx={{ overFlowY: "initial !important" }}>
                <div className="modalBodyC">
                  <div
                    className="input-wrapper"
                    style={{ margin: "0 0 8px 0" }}
                  >
                    <div className="schedule-cus" style={{ width: "100%" }}>
                      <div
                        className="date-schedule-cus"
                        style={{ width: "100%" }}
                      >
                        <LabelModal
                          style={{
                            margin: "5px 0 10px 0",
                            display: "inline-block",
                          }}
                        >
                          Avatar Profile*
                        </LabelModal>
                        <div className="input-wrapper form-inline">
                          {
                            <aside
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                              }}
                            >
                              <Thumbs imageUrl={profileUrl}></Thumbs>
                            </aside>
                          }
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="input-wrapper"
                    style={{ margin: "0 0 8px 0" }}
                  >
                    <div className="schedule-cus" style={{ width: "100%" }}>
                      <div style={{ width: "100%" }}>
                        <LabelModal
                          style={{
                            margin: "5px 0 10px 0",
                            display: "inline-block",
                          }}
                        >
                          Full Name*
                        </LabelModal>
                        <div className="input-wrapper form-inline">
                          <input type="text" disabled value={username} />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="input-wrapper"
                    style={{ margin: "0 0 8px 0" }}
                  >
                    <div
                      className="schedule-cus"
                      style={{
                        width: "100%",
                        paddingTop: "0",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <LabelModal
                          style={{
                            margin: "0 0 10px 0",
                            display: "inline-block",
                          }}
                        >
                          Title*
                        </LabelModal>
                        <div className="input-wrapper form-inline">
                          <input type="text" disabled value={specialistTitle} />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="input-wrapper"
                    style={{ margin: "0 0 12px 0" }}
                  >
                    <div style={{ width: "100%" }}>
                      <LabelModal>Time Zone*</LabelModal>
                      <Select
                        displayEmpty
                        value={timezone}
                        onChange={(val) => setTimezone(val.target.value)}
                        inputProps={{ "aria-label": "Without label" }}
                        sx={{
                          marginTop: "8px",
                          width: "100%",
                          border: "solid 1px Colors.app.GhostGrey",
                          background: Colors.white.default,
                          borderRadius: "5px",
                          color: Colors.app.black,
                          fontWeight: Fonts.weights.w5,
                          fontFamily: "Fonts.interMedium",
                          fontSize: Fonts.sizes.xs,
                        }}
                      >
                        <MenuItem disabled value="">
                          <em className="text-slate-400">Time Zone*</em>
                        </MenuItem>
                        <MenuItem value="Asia/Jakarta">
                          <em>Asia/Jakarta</em>
                        </MenuItem>
                        <MenuItem value="Asia/Makassar">
                          <em>Asia/Makassar</em>
                        </MenuItem>
                        <MenuItem value="Asia/Jayapura">
                          <em>Asia/Jayapura</em>
                        </MenuItem>
                      </Select>
                    </div>
                  </div>

                  <div
                    className="input-wrapper"
                    style={{ margin: "0 0 12px 0" }}
                  >
                    <div style={{ width: "100%" }}>
                      <LabelModal>Date Range*</LabelModal>
                      <Select
                        displayEmpty
                        value={dateRange}
                        onChange={(val) => setDateRange(val.target.value)}
                        inputProps={{ "aria-label": "Without label" }}
                        sx={{
                          marginTop: "8px",
                          width: "100%",
                          border: "solid 1px Colors.app.GhostGrey",
                          background: Colors.white.default,
                          borderRadius: "5px",
                          color: Colors.app.black,
                          fontWeight: Fonts.weights.w5,
                          fontFamily: "Fonts.interMedium",
                          fontSize: Fonts.sizes.xs,
                        }}
                      >
                        <MenuItem disabled value={""}>
                          <em>Date Range*</em>
                        </MenuItem>
                        <MenuItem value={"30"}>
                          <em>30 Days</em>
                        </MenuItem>
                        <MenuItem value={"60"}>
                          <em>60 Days</em>
                        </MenuItem>
                      </Select>
                    </div>
                  </div>

                  <div
                    className="input-wrapper"
                    style={{ margin: "0 0 12px 0" }}
                  >
                    <div style={{ width: "100%" }}>
                      <LabelModal>Notice Period*</LabelModal>
                      <Select
                        displayEmpty
                        value={noticePeriod}
                        onChange={(val) => setNoticePeriod(val.target.value)}
                        inputProps={{ "aria-label": "Without label" }}
                        sx={{
                          marginTop: "8px",
                          width: "100%",
                          border: "solid 1px Colors.app.GhostGrey",
                          background: Colors.white.default,
                          borderRadius: "5px",
                          color: Colors.app.black,
                          fontWeight: Fonts.weights.w5,
                          fontFamily: "Fonts.interMedium",
                          fontSize: Fonts.sizes.xs,
                        }}
                      >
                        <MenuItem disabled value={""}>
                          <em>Notice Period*</em>
                        </MenuItem>
                        <MenuItem value={"1"}>
                          <em>1 Hour</em>
                        </MenuItem>
                        <MenuItem value={"6"}>
                          <em>6 Hours</em>
                        </MenuItem>
                        <MenuItem value={"8"}>
                          <em>8 Hours</em>
                        </MenuItem>
                        <MenuItem value={"12"}>
                          <em>12 Hours</em>
                        </MenuItem>
                        <MenuItem value={"18"}>
                          <em>18 Hours</em>
                        </MenuItem>
                        <MenuItem value={"24"}>
                          <em>24 Hours</em>
                        </MenuItem>
                      </Select>
                    </div>
                  </div>

                  <div className="mt-6 mb-4">
                    <LabelModal>Schedule*</LabelModal>
                  </div>

                  {/* Sundays */}
                  <ScheduleItem
                    day="Sundays"
                    schedules={schedules["sunday"] ?? []}
                    locations={
                      locationSchedule ? locationSchedule["sunday"] : []
                    }
                    onAddSchedule={() => onAddSchedule("sunday")}
                    onRemoveSchedule={(index) =>
                      onRemoveSchedule("sunday", index)
                    }
                    onChange={(index, newValue) =>
                      onChangeSchedule("sunday", index, newValue)
                    }
                    onChangeLocation={(index, formatCall) =>
                      onChangeScheduleLocation("sunday", index, formatCall)
                    }
                  ></ScheduleItem>

                  {/* Mondays */}
                  <ScheduleItem
                    day="Mondays"
                    schedules={schedules["monday"] ?? []}
                    locations={
                      locationSchedule ? locationSchedule["monday"] : []
                    }
                    onAddSchedule={() => onAddSchedule("monday")}
                    onRemoveSchedule={(index) =>
                      onRemoveSchedule("monday", index)
                    }
                    onChange={(index, newValue) =>
                      onChangeSchedule("monday", index, newValue)
                    }
                    onChangeLocation={(index, formatCall) =>
                      onChangeScheduleLocation("monday", index, formatCall)
                    }
                  ></ScheduleItem>

                  {/* Tuesdays */}
                  <ScheduleItem
                    day="Tuesdays"
                    schedules={schedules["tuesday"] ?? []}
                    locations={
                      locationSchedule ? locationSchedule["tuesday"] : []
                    }
                    onAddSchedule={() => onAddSchedule("tuesday")}
                    onRemoveSchedule={(index) =>
                      onRemoveSchedule("tuesday", index)
                    }
                    onChange={(index, newValue) =>
                      onChangeSchedule("tuesday", index, newValue)
                    }
                    onChangeLocation={(index, formatCall) =>
                      onChangeScheduleLocation("tuesday", index, formatCall)
                    }
                  ></ScheduleItem>

                  {/* Wednesdays */}
                  <ScheduleItem
                    day="Wednesdays"
                    schedules={schedules["wednesday"] ?? []}
                    locations={
                      locationSchedule ? locationSchedule["wednesday"] : []
                    }
                    onAddSchedule={() => onAddSchedule("wednesday")}
                    onRemoveSchedule={(index) =>
                      onRemoveSchedule("wednesday", index)
                    }
                    onChange={(index, newValue) =>
                      onChangeSchedule("wednesday", index, newValue)
                    }
                    onChangeLocation={(index, formatCall) =>
                      onChangeScheduleLocation("wednesday", index, formatCall)
                    }
                  ></ScheduleItem>

                  {/* Thursdays */}
                  <ScheduleItem
                    day="Thursdays"
                    schedules={schedules["thursday"] ?? []}
                    locations={
                      locationSchedule ? locationSchedule["thursday"] : []
                    }
                    onAddSchedule={() => onAddSchedule("thursday")}
                    onRemoveSchedule={(index) =>
                      onRemoveSchedule("thursday", index)
                    }
                    onChange={(index, newValue) =>
                      onChangeSchedule("thursday", index, newValue)
                    }
                    onChangeLocation={(index, formatCall) =>
                      onChangeScheduleLocation("thursday", index, formatCall)
                    }
                  ></ScheduleItem>

                  {/* Fridays */}
                  <ScheduleItem
                    day="Fridays"
                    schedules={schedules["friday"] ?? []}
                    locations={
                      locationSchedule ? locationSchedule["friday"] : []
                    }
                    onAddSchedule={() => onAddSchedule("friday")}
                    onRemoveSchedule={(index) =>
                      onRemoveSchedule("friday", index)
                    }
                    onChange={(index, newValue) =>
                      onChangeSchedule("friday", index, newValue)
                    }
                    onChangeLocation={(index, formatCall) =>
                      onChangeScheduleLocation("friday", index, formatCall)
                    }
                  ></ScheduleItem>

                  {/* Saturdays */}
                  <ScheduleItem
                    day="Saturdays"
                    schedules={schedules["saturday"] ?? []}
                    locations={
                      locationSchedule ? locationSchedule["saturday"] : []
                    }
                    onAddSchedule={() => onAddSchedule("saturday")}
                    onRemoveSchedule={(index) =>
                      onRemoveSchedule("saturday", index)
                    }
                    onChange={(index, newValue) =>
                      onChangeSchedule("saturday", index, newValue)
                    }
                    onChangeLocation={(index, formatCall) =>
                      onChangeScheduleLocation("saturday", index, formatCall)
                    }
                  ></ScheduleItem>
                </div>
              </Box>
            </LocalizationProvider>
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: "4px 24px !important" }}>
          <ButtonStyle
            className="btn-primary"
            disabled={!isEnabled}
            title="Add Schedule "
            onClick={() => onSubmit(calendarSync)}
            style={{ marginTop: "10px" }}
          >
            Simpan
          </ButtonStyle>
        </DialogActions>
      </>
    </ModalSimple>
  );
}

function ScheduleItem(props: {
  day: string;
  schedules: any[];
  locations: any[];
  onRemoveSchedule: (index: number) => void;
  onAddSchedule: () => void;
  onChange: (index: number, value: any) => void;
  onChangeLocation: (index: number, formatCall: string) => void;
}) {
  return (
    <div className="w-full mb-4 my-2">
      <LabelModal
        style={{
          margin: "0 0 10px 0",
          display: "block",
        }}
      >
        {props.day}
      </LabelModal>
      {props.schedules.map((val, i) => {
        const locationList: LocationRoomData[] = props.locations?.[i]
          ? props.locations[i] || []
          : [];

        return (
          <div key={i}>
            <div className="flex items-center justify-between">
              <div className="mt-2 flex items-center">
                <MobileTimePicker
                  ampm={false}
                  value={val.time_from}
                  sx={{
                    width: "120px",
                  }}
                  minutesStep={15}
                  onChange={(newTime) =>
                    props.onChange(i, {
                      ...val,
                      time_from: newTime,
                    })
                  }
                  minTime={i > 0 ? props.schedules[i - 1].time_to : null}
                />
                <div className="mx-2">ke</div>
                <MobileTimePicker
                  ampm={false}
                  value={val.time_to}
                  sx={{
                    width: "120px",
                  }}
                  minutesStep={15}
                  onChange={(newTime) =>
                    props.onChange(i, {
                      ...val,
                      time_to: newTime,
                    })
                  }
                  minTime={val.time_from ?? null}
                />
                {/*-- Session --*/}
                <Select
                  displayEmpty
                  multiple
                  value={val.format_calls ? val.format_calls.split(",") : []}
                  error={!(val.format_calls && val.format_calls.length)}
                  onChange={(event) => {
                    props.onChange(i, {
                      ...val,
                      format_calls:
                        typeof event.target.value === "string"
                          ? event.target.value
                          : event.target.value.join(","),
                      location_code: [],
                    });
                    props.onChangeLocation(
                      i,
                      typeof event.target.value === "string"
                        ? event.target.value
                        : event.target.value.join(",")
                    );
                  }}
                  renderValue={(selected: string[]) => {
                    if (selected.length === 0) {
                      return <em className="text-slate-400">Format Call</em>;
                    }

                    const vals = sessionTypes
                      .filter((item) => {
                        return selected.includes(item.key);
                      })
                      .map((val) => val.label);

                    return vals.join(", ");
                  }}
                  sx={{
                    border: "solid 1px Colors.app.GhostGrey",
                    background: Colors.white.default,
                    borderRadius: "5px",
                    color: Colors.app.black,
                    fontWeight: Fonts.weights.w5,
                    fontFamily: "Fonts.interMedium",
                    fontSize: Fonts.sizes.xs,
                    marginLeft: "16px",
                  }}
                  className="!w-48 shrink-0"
                >
                  {sessionTypes.map((item) => {
                    return (
                      <MenuItem key={item.key} value={item.key}>
                        <Checkbox
                          color="default"
                          checked={(val.format_calls ?? "")
                            .split(",")
                            .includes(item.key)}
                        />
                        <ListItemText>{item.label}</ListItemText>
                      </MenuItem>
                    );
                  })}
                </Select>
                {/*-- Location --*/}
                {val.format_calls?.includes("online") &&
                !val.format_calls?.includes("in_person") ? (
                  <div className="w-[182px] max-w-[182px] border border-solid border-black rounded-md ml-4 h-[43px] flex items-center justify-start pl-2 py-1">
                    <p>Online</p>
                  </div>
                ) : (
                  <Select
                    displayEmpty
                    value={
                      val.location_code && val.location_code.length
                        ? val.location_code[0]
                        : utils.InvalidLocationCode
                    }
                    onChange={(event) =>
                      props.onChange(i, {
                        ...val,
                        location_code:
                          event.target.value != utils.InvalidLocationCode
                            ? [event.target.value]
                            : [],
                      })
                    }
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{
                      borderRadius: "5px",
                      marginLeft: "16px",
                      textAlign: "start",
                    }}
                    className="!w-48 shrink-0"
                    error={!(val.location_code && val.location_code.length)}
                  >
                    <MenuItem value={utils.InvalidLocationCode}>
                      <p className="text-slate-400">Lokasi</p>
                    </MenuItem>
                    {locationList.map((_loc) => (
                      <MenuItem
                        key={_loc.location_code}
                        value={_loc.location_code}
                      >
                        <p>{_loc.name}</p>
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </div>
              <div
                className="px-2 ml-2 cursor-pointer"
                onClick={() => props.onRemoveSchedule(i)}
              >
                <Close></Close>
              </div>
            </div>
          </div>
        );
      })}
      <div
        className="bg-blue-background py-3 px-4 flex gap-2 items-center cursor-pointer mt-4"
        onClick={() => props.onAddSchedule()}
      >
        <Add className="text-brand-dark" size={18}></Add>
        <div className="text-sm capitalize text-brand-dark">Add Times</div>
      </div>
    </div>
  );
}

function Thumbs(props: { imageUrl: string }) {
  const names = props.imageUrl.split("/");
  const name = names[names.length - 1];

  return (
    <Thumb
      {...props}
      name={name}
      url={Endpoints.baseUrlAws + props.imageUrl}
    ></Thumb>
  );
}

function Thumb(props: { name: string; url: string }) {
  return (
    <div
      style={{
        marginBottom: 8,
        marginRight: 8,
      }}
      key={props.name}
    >
      <div
        style={{
          display: "flex",
          minWidth: 0,
          overflow: "hidden",
        }}
      >
        <div
          style={{
            background: "#ffffff",
            width: "55px !important",
            marginTop: "0 !important",
            borderRadius: "100px",
            height: "55px !important",
            padding: "0 !important",
            cursor: "pointer",
          }}
        >
          <div
            style={{
              background: `url(${props.url}) #D3D3D3`,
              backgroundSize: "100%",
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
              width: "55px",
              height: "55px",
              display: "flex",
              alignItems: "center",
              borderRadius: "100px",
              justifyContent: "center",
            }}
          />
        </div>
      </div>
    </div>
  );
}
