import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { TableEmptyRows, TableHeadCustom } from "components/Table";
import useTable from "components/Table/useTable";
import SpecialistRow from "sections/@dashboard/billing/specialist";
import { getSpecialistTransactionList } from "service/specialist";

const TABLE_HEAD = [
  {
    id: "transaction_date",
    label: "TAHUN",
  },
  {
    id: "transaction_date",
    label: "BULAN",
  },
  {
    id: "specialist_email",
    label: "EMAIL UTAMA",
  },
  {
    id: "specialist_phone",
    label: "NO HP",
  },
  {
    id: "booked_hours",
    label: "JAM PEMESANAN",
  },
  {
    id: "earnings",
    label: "PENDAPATAN",
  },
  {
    id: "detail",
    label: "DETAIL",
  },
  {
    id: "action",
    label: "INVOICE",
  },
];
export default function TabActived(props: {
  forceUpdate: number;
  year: string;
  month: string;
  search: string;
  status: string;
  onShowDetail: (val: any) => void;
  onShowPayment: (val: any) => void;
}) {
  const [data, setData] = useState<any[]>([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);

  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("scheduled_date");
  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const { dense, selected, onSort } = useTable();

  const onChangeRowPerPage = (val) => {
    setPage(1);
    setLimit(val);
  };

  const onChangePage = (val) => {
    setPage(val + 1);
  };

  const init = async () => {
    const resp = await getSpecialistTransactionList({
      keyword: props.search,
      status: props.status,
      limit: limit ?? 10,
      // order: orderBy,
      // sort: order,
      page: page ?? 1,
      year: props.year,
      month: props.month,
    });

    const pagination = resp.pagination;

    setCount(pagination.count);
    setLimit(pagination.limit);
    setPage(pagination.page);
    setData(resp.data ?? []);
  };

  useEffect(() => {
    init();
  }, [props.status, props.forceUpdate, props.year, props.month, page, limit]);

  useEffect(() => {
    if (props.search.length > 0 && page !== 1) {
      onChangePage(0);
    } else {
      init();
    }
  }, [props.search]);

  return (
    <div>
      <TableContainer
        sx={{
          minWidth: 800,
          position: "relative",
        }}
      >
        <Table size={dense ? "small" : "medium"}>
          <TableHeadCustom
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headLabel={TABLE_HEAD}
            rowCount={data.length}
            onSort={onSort}
          />

          <TableBody>
            {data.map((row, index) => (
              <SpecialistRow
                key={index}
                row={row}
                onInvoice={() => props.onShowPayment(row)}
                onShowDetail={() => props.onShowDetail(row)}
              />
            ))}

            <TableEmptyRows height={dense ? 52 : 72} emptyRows={data.length} />
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ position: "relative" }}>
        <TablePagination
          rowsPerPageOptions={[1, 10, 25, 100]}
          component="div"
          labelRowsPerPage="Item per halaman: "
          count={count || 0}
          rowsPerPage={limit || 10}
          page={page - 1 || 0}
          onPageChange={(_, val) => onChangePage(val)}
          onRowsPerPageChange={(e) =>
            onChangeRowPerPage(Number(e.target.value))
          }
        />
      </Box>
    </div>
  );
}
