import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from "@mui/material";
import { TableEmptyRows, TableHeadCustom } from "components/Table";
import useTable from "components/Table/useTable";
import React, { useEffect, useState } from "react";
import RefundRow from "sections/@dashboard/refund";
import { getListRefund } from "service/refund";
import { utils } from "utils";

const TABLE_HEAD = [
  {
    id: "email",
    label: "EMAIL",
  },
  {
    id: "title",
    label: "ITEM",
  },
  {
    id: "purchased_date",
    label: "TANGGAL PEMBELIAN",
  },
  {
    id: "requested_date",
    label: "TANGGAL PERMINTAAN",
  },
  { id: "act" },
];

export default function TabApproved(props: {
  search: string;
  forceUpdate: number;
  onCount: (val: number) => void;
  onChangeStatus: (val: any, status: string) => void;
}) {
  const [data, setData] = useState<any[]>([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);

  const { onSort } = useTable();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("promo_code");

  useEffect(() => {
    init();
  }, [props.forceUpdate, page, limit]);

  useEffect(() => {
    if (props.search.length > 0 && page !== 1) {
      onChangePage(0);
    } else {
      init();
    }
  }, [props.search]);

  const onRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const dataFiltered = utils.applySortFilter({
    data: data,
    comparator: utils.getComparator(order, orderBy),
  });

  const onChangeRowPerPage = (val) => {
    setPage(1);
    setLimit(val);
  };

  const onChangePage = (val) => {
    setPage(val + 1);
  };

  const init = async () => {
    const resp = await getListRefund({
      page: page,
      limit: limit,
      keyword: props.search,
      status: "approved",
    });

    const pagination = resp.pagination;

    setCount(pagination.count);
    setLimit(pagination.limit);
    setPage(pagination.page);
    setData(resp.data ?? []);

    props.onCount(pagination.count);
  };

  return (
    <div>
      <TableContainer
        sx={{
          minWidth: 800,
          position: "relative",
        }}
      >
        <Table size="medium">
          <TableHeadCustom
            headLabel={TABLE_HEAD}
            rowCount={data.length}
            orderBy={orderBy}
            onRequestSort={onRequestSort}
            onSort={onSort}
            order={order}
          />

          <TableBody>
            {dataFiltered.map((row, index) => {
              const labelIdx = `test-${index}`;

              return (
                <RefundRow
                  key={row.order_refund_code}
                  row={row}
                  labelId={labelIdx}
                  onDeclined={() => props.onChangeStatus(row, "declined")}
                />
              );
            })}

            <TableEmptyRows height={72} emptyRows={data.length} />
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ position: "relative" }}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          labelRowsPerPage="Item per halaman: "
          count={count || 0}
          rowsPerPage={limit || 10}
          page={page - 1 || 0}
          onPageChange={(_, pg) => onChangePage(pg)}
          onRowsPerPageChange={(e) => onChangeRowPerPage(e.target.value)}
        />
      </Box>
    </div>
  );
}
