/* eslint-disable no-undef */
export default {
  logoMaxiRoundSmall: require("assets/images/logo-maxi-round-small.png"),
  avatar: require("assets/images/avatar.png"),
  delete: require("assets/images/delete.png"),
  ban: require("assets/images/ban.png"),
  heroImgLogin: require("assets/images/login-hero.png"),
  bgContainer: require("assets/images/bg-container-front.png"),
  successModal: require("assets/images/success.png"),
  addImagePost: require("assets/images/gallery-add.png"),
  demoPost: require("assets/images/demo-post.png"),
  transaction: require("assets/images/transaction.png"),
  overtime: require("assets/images/overtime.png"),

  csv: require("assets/images/csv.png"),
  import: require("assets/images/import.png"),
  send: require("assets/images/send.png"),

  btn_time: require("assets/images/btn/btn_time.png"),
  btn_share: require("assets/images/btn/btn_share.png"),
  btn_delete: require("assets/images/btn/btn_delete.png"),
  btn_delete_account: require("assets/images/btn/btn_delete_account.png"),
  btn_check: require("assets/images/btn/btn_check.png"),
  btn_edit: require("assets/images/btn/btn_edit.png"),
  btn_import: require("assets/images/btn/btn_import.png"),
  btn_send: require("assets/images/btn/btn_send.png"),
  btn_warning: require("assets/images/btn/btn_warning.png"),
  btn_switch: require("assets/images/btn/btn_switch.png"),
  btn_flag: require("assets/images/btn/btn_flag.png"),
  btn_export: require("assets/images/btn/btn_export.png"),
  btn_resign: require("assets/images/btn/btn_resign.png"),
  btn_eye: require("assets/images/btn/btn_eye.png"),

  btn_x_act: require("assets/images/btn/btn_x_act.png"),
  btn_check_act: require("assets/images/btn/btn_check_act.png"),
  btn_repost_act: require("assets/images/btn/btn_repost_act.png"),
  btn_specialist_act: require("assets/images/btn/btn_specialist_act.png"),
  btn_import_act: require("assets/images/btn/btn_import_act.png"),
  btn_resign_act: require("assets/images/btn/btn_resign_act.png"),
  btn_edit_act: require("assets/images/btn/btn_edit_act.png"),
  btn_flag_act: require("assets/images/btn/btn_flag_act.png"),
  btn_send_act: require("assets/images/btn/btn_send_act.png"),
  btn_switch_act: require("assets/images/btn/btn_switch_act.png"),
  backgroundGradient:
    "https://maxi-stage-assets.s3.ap-southeast-1.amazonaws.com/assets/bg-quote.jpg",
};
