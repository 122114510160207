import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Container,
  FormControl,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import Select from "@mui/material/Select";
import Iconify from "screens/Layout/components/Iconify";
import TabActived from "./components/TabActived";
import TabDeleted from "./components/TabDeleted";
import { ModalDelete } from "components/Modal/ModalSimple";
import { a11yProps, SearchStyle, TabPanelHidden } from "components/Panel";
import service from "service";
import ModalModify from "./components/ModalModify";
import ModalPreview from "./components/ModalPreview";
import { getSpecialistList } from "service/specialist";

export default function DailyWallpaper() {
  const [tabValue, setTabValue] = useState(0);

  // List Data
  const [listMember, setListMember] = useState<any[]>([]);
  const [listChapter, setListChapter] = useState<any[]>([]);

  // Filter
  const [filterSearch, setFilterSearch] = useState("");
  const [filterChapter, setFilterChapter] = useState("");
  const [filterReviewedBy, setFilterReviewedBy] = useState("");

  // Modal State
  const [isDefaultLanguage, setDefaultLanguage] = useState(true);
  const [isAddContent, setAddContent] = useState(true);
  const [currentItem, setCurrentItem] = useState<any>({});

  // Count Label
  const [scheduledCount, setScheduledCount] = useState(0);
  const [deletedCount, setDeletedCount] = useState(0);

  // Modal
  const [isShowModalPreview, setShowModalPreview] = useState(false);
  const [isShowModalModify, setShowModalModify] = useState(false);
  const [isShowModalDelete, setShowModalDelete] = useState(false);
  const [activedForceUpdate, setActivedForceUpdate] = useState(0);
  const [deletedForceUpdate, setDeletedForceUpdate] = useState(0);

  const onEdit = (val: any) => {
    setCurrentItem(val);
    setShowModalModify(true);
    setAddContent(false);
    setActivedForceUpdate(Math.random());
  };

  const onDelete = (val: any) => {
    setCurrentItem(val);
    setShowModalDelete(true);
  };

  const onConfirmDelete = async () => {
    await service.course.deleteCourse(currentItem.course_code);

    setShowModalDelete(false);
    setDeletedForceUpdate(Math.random());
    setActivedForceUpdate(Math.random());
  };

  const onPreviewAction = async () => {
    if (tabValue === 1) {
      await service.course.repostCourse(currentItem.course_code);

      setDeletedForceUpdate(Math.random());
      setActivedForceUpdate(Math.random());
    }

    if (tabValue === 0 && !isDefaultLanguage) {
      setShowModalModify(true);
    }

    setShowModalPreview(false);
  };

  const refreshUpdateSubmit = () => {
    setShowModalModify(false);
    setActivedForceUpdate(Math.random());
  };

  const onSubmit = async (val: any) => {
    const files = val.files;

    let bg_image_url = val.imageUrl;
    if (files.length > 0 && isDefaultLanguage) {
      const fileOrigin = files[0].origin;
      const resp = await service.uploadImage(fileOrigin);

      bg_image_url = resp.data.file_path;
    }

    if (val.isAddChapter && isDefaultLanguage) {
      const resp = await service.course.addCourseChapter({
        chapter_name: val.chapter,
      });

      val.chapter = resp.data.chapter_code;

      initChapters();
    }

    if (isAddContent) {
      if (isDefaultLanguage) {
        await service.course.addCourse({
          bg_image_url: bg_image_url,
          chapter_code: val.chapter,
          content: val.content,
          ordering: val.ordering,
          reviewer_code: val.reviewer,
          status: "active",
          title: val.title,
        });
      } else {
        await service.course.addCourseTranslation({
          content: val.content,
          course_code: currentItem.course_code,
          language: val.language,
          title: val.title,
        });
      }

      return refreshUpdateSubmit();
    }

    if (isDefaultLanguage) {
      await service.course.updateCourse({
        course_code: currentItem.course_code,
        bg_image_url: bg_image_url,
        chapter_code: val.chapter,
        content: val.content,
        ordering: val.ordering,
        reviewer_code: val.reviewer,
        status: "active",
        title: val.title,
      });
    } else {
      await service.course.updateCourseTranslation({
        content: val.content,
        course_code: currentItem.course_code,
        language: currentItem.translation,
        title: val.title,
      });
    }

    refreshUpdateSubmit();
  };

  const onTranslation = async (row: any, lang: string) => {
    const resp = await service.course.getCourseTranslation({
      course_code: row.course_code,
      language: lang,
    });
    const data = resp.data;

    setCurrentItem({
      ...row,
      title: data.title,
      content: data.content,
      translation: lang,
    });
    setDefaultLanguage(false);
    setAddContent(false);
    setShowModalPreview(true);
  };

  const onAddTranslation = (row: any, lang: string) => {
    setCurrentItem({
      ...row,
      translation: lang,
    });

    setDefaultLanguage(false);
    setShowModalModify(true);
    setAddContent(true);
  };

  const initChapters = () => {
    service.course.getListCourseChapter({ limit: 1000 }).then((val) => {
      setListChapter(val.data ?? []);
    });
  };

  useEffect(() => {
    getSpecialistList({
      limit: 1000,
      page: 1,
    }).then((val) => {
      setListMember(val.data ?? []);
    });

    initChapters();
  }, []);

  useEffect(() => {
    if (!(isShowModalModify || isShowModalPreview)) {
      setDefaultLanguage(true);
      setAddContent(true);
      setCurrentItem({});
    }
  }, [isShowModalModify, isShowModalPreview]);

  useEffect(() => {
    if (!isShowModalModify && !isShowModalDelete) {
      setCurrentItem({});
    }
  }, [isShowModalModify, isShowModalDelete]);

  return (
    <>
      <Container maxWidth="lg">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4}
        >
          <Box>
            <Typography variant="h4" sx={{ fontSize: "24px" }}>
              Course
            </Typography>
          </Box>

          <SearchStyle
            onChange={(e) => setFilterSearch(e.target.value)}
            placeholder="Search"
            startAdornment={
              <InputAdornment position="start">
                <Iconify
                  icon="eva:search-fill"
                  sx={{
                    color: "text.disabled",
                    width: 25,
                    height: 25,
                  }}
                />
              </InputAdornment>
            }
          />
        </Stack>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Tabs
              value={tabValue}
              onChange={(_, i) => setTabValue(i)}
              aria-label="basic tabs example"
            >
              <Tab label={`Aktif (${scheduledCount})`} {...a11yProps(0)} />
              <Tab label={`Dihapus (${deletedCount})`} {...a11yProps(1)} />
            </Tabs>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            margin: "0",
            paddingTop: "20px",
            paddingBottom: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <FormControl
            sx={{
              m: 0,
              width: 188,
            }}
          >
            <Select
              displayEmpty
              value={filterChapter}
              onChange={(e) => setFilterChapter(e.target.value)}
              input={<OutlinedInput />}
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                height: "40px",
                fontFamily: "Inter-Medium",
                fontSize: "14px",
              }}
            >
              <MenuItem value="">
                <em>Course Chapter</em>
              </MenuItem>
              {listChapter.map((val, i) => {
                return (
                  <MenuItem value={val.chapter_code} key={i}>
                    <em>{val.chapter_name}</em>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl
            sx={{
              m: 1,
              width: 238,
            }}
          >
            <Select
              displayEmpty
              value={filterReviewedBy}
              onChange={(e) => setFilterReviewedBy(e.target.value)}
              input={<OutlinedInput />}
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                height: "40px",
                fontSize: "14px",
                fontFamily: "Inter-Medium",
              }}
            >
              <MenuItem value="">
                <em>Reviewed by</em>
              </MenuItem>
              {listMember.map((val, i) => {
                return (
                  <MenuItem key={i} value={val.member_code}>
                    <em>{val.username}</em>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <Box
            sx={{
              flex: 3,
              textAlign: "right",
            }}
          >
            <Button
              onClick={() => setShowModalModify(!isShowModalModify)}
              className="btn-action-filter-dark"
              sx={{ width: "220px" }}
              endIcon={<Iconify icon="eva:plus-fill" sx={{}} />}
            >
              Add New Course
            </Button>
          </Box>
        </Box>

        <TabPanelHidden value={tabValue} index={0}>
          <Card>
            <TabActived
              search={filterSearch}
              reviewedBy={filterReviewedBy}
              chapter={filterChapter}
              forceUpdate={activedForceUpdate}
              onCount={(val) => setScheduledCount(val)}
              onDelete={onDelete}
              onEdit={onEdit}
              onPreview={(item: any) => {
                setCurrentItem(item);
                setShowModalPreview(true);
              }}
              onTranslation={onTranslation}
              onAddTranslation={onAddTranslation}
            ></TabActived>
          </Card>
        </TabPanelHidden>
        <TabPanelHidden value={tabValue} index={1}>
          <Card>
            <TabDeleted
              reviewedBy={filterReviewedBy}
              forceUpdate={deletedForceUpdate}
              chapter={filterChapter}
              onCount={(val) => setDeletedCount(val)}
              search={filterSearch}
              onPreview={(item: any) => {
                setCurrentItem(item);
                setShowModalPreview(true);
              }}
            ></TabDeleted>
          </Card>
        </TabPanelHidden>

        <ModalPreview
          onAction={() => onPreviewAction()}
          onHide={() => setShowModalPreview(false)}
          isShow={isShowModalPreview}
          value={currentItem}
          actionTitle={
            tabValue === 0
              ? isDefaultLanguage
                ? "Confirm"
                : "Edit"
              : "Re-post"
          }
          title={tabValue === 0 ? "Course Preview" : "Deleted Course Preview"}
        ></ModalPreview>

        <ModalModify
          isDefaultlanguage={isDefaultLanguage}
          listReviewer={listMember}
          listChapter={listChapter}
          hide={() => setShowModalModify(false)}
          isShow={isShowModalModify}
          onSubmit={onSubmit}
          value={currentItem}
          isAddContent={isAddContent}
        ></ModalModify>

        <ModalDelete
          isOpen={isShowModalDelete}
          onClose={() => setShowModalDelete(false)}
          onDelete={onConfirmDelete}
          title="Are you sure want to delete this course?"
        />
      </Container>
    </>
  );
}
