import {
  Box,
  Checkbox,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { Images } from "constant";
import React, { useRef, useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";

export default function UserRow(props: {
  row: any;
  isSelectedRow: boolean;
  labelId: any;

  onEdit?: () => void;
  onDelete?: () => void;
  onSelect?: () => void;
  onInvitation?: () => void;
  onChangeSpecialist?: () => void;
  onUnbanned?: () => void;
}) {
  const {
    email,
    gender,
    birthdate,
    city,
    country,
    username,
    customer_id,
    phone,
    street_name,
    state,
    post_code,
    Religion,
    EducationLevel,
  } = props.row;

  return (
    <TableRow
      hover
      key={props.row.invitation_email}
      aria-checked={props.isSelectedRow}
      role="checkbox"
      tabIndex={-1}
      selected={props.isSelectedRow}
    >
      {!props.onUnbanned ? (
        <TableCell padding="checkbox" align="center">
          <Checkbox
            onClick={props.onSelect}
            checked={props.isSelectedRow}
            inputProps={{ "aria-labelledby": props.labelId }}
            color="primary"
          />
        </TableCell>
      ) : null}

      <TableCell align="left">
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "150px !important",
          }}
        >
          {customer_id}
        </Typography>
      </TableCell>

      <TableCell align="left">
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "150px !important",
          }}
        >
          {username}
        </Typography>
      </TableCell>

      <TableCell align="left">
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "150px !important",
          }}
        >
          {email}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "180px !important",
          }}
        >
          {phone}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "100px !important",
          }}
        >
          {gender === "rather_not_saying" ? "Prefer Not to Say" : gender}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "130px !important",
          }}
        >
          {birthdate ?? "-"}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "130px !important",
          }}
        >
          {EducationLevel.name ?? "-"}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "130px !important",
          }}
        >
          {Religion.name ?? "-"}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "100px !important",
          }}
        >
          {street_name}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "100px !important",
          }}
        >
          {city}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "100px !important",
          }}
        >
          {state}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "100px !important",
          }}
        >
          {post_code}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "100px !important",
          }}
        >
          {country}
        </Typography>
      </TableCell>

      {!props.onUnbanned ? (
        <TableCell align="left" sx={{ textTransform: "capitalize" }}>
          <Box sx={{ width: "200px" }}>
            <IconButton title="Edit" onClick={props.onEdit}>
              <img src={Images.btn_edit_act} alt="" />
            </IconButton>
            <IconButton title="Kirim" onClick={props.onInvitation}>
              <img src={Images.btn_send_act} alt="" />
            </IconButton>
            <IconButton title="Change Role" onClick={props.onChangeSpecialist}>
              <img src={Images.btn_specialist_act} alt="" />
            </IconButton>
            <IconButton title="Flag" onClick={props.onDelete}>
              <img src={Images.btn_flag_act} alt="" />
            </IconButton>
          </Box>
        </TableCell>
      ) : (
        <TableCell align="left" sx={{ textTransform: "capitalize" }}>
          <Box sx={{ width: "50px" }}>
            <IconButton onClick={props.onUnbanned}>
              <img src={Images.btn_repost_act} alt="" />
            </IconButton>
          </Box>
        </TableCell>
      )}
    </TableRow>
  );
}
