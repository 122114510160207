import api from "./api";

export const getApproachList = async () => {
	const resp = await api.get("/v1/specialists/approaches");

	return resp.data;
};

export const fetchApproachFlat = () => {
	return api.get("/v1/specialists/approaches/flat").then(val => val.data);
};
