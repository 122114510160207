import React from "react";
import PropTypes from "prop-types";

// @mui
import { Stack, TableCell, TableRow, Typography } from "@mui/material";

// components
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import { Images } from "constant";

export default function UserTableRow(props: {
  row: any;
  onRestore: () => void;
}) {
  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const { admin_code, name, email, site_role, status } = props.row;

  return (
    <TableRow key={admin_code} hover>
      <TableCell component="th" scope="row" padding="normal">
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography variant="subtitle2" noWrap>
            {name}
          </Typography>
        </Stack>
      </TableCell>

      <TableCell align="left">{email}</TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        {!site_role ? (
          "-"
        ) : (
          <span
            style={{
              background: "black",
              color: "#FFFFFF",
              borderRadius: "10px",
              padding: "5px 10px",
            }}
          >
            {site_role}
          </span>
        )}
      </TableCell>

      <TableCell align="left" sx={{ display: "flex" }}>
        <BootstrapTooltip
          placement="top-start"
          title={status === "active" ? "Inactived Admin" : "Actived Admin"}
        >
          <Button
            title="Restore"
            onClick={props.onRestore}
            sx={{
              minWidth: "30px",
              width: "40px",
            }}
          >
            <img src={Images.btn_check} style={{ width: "30px" }} />
          </Button>
        </BootstrapTooltip>
      </TableCell>
    </TableRow>
  );
}
