import React, { useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from "@mui/material";
import { useState } from "react";
import useTable from "components/Table/useTable";
import { TableEmptyRows, TableHeadCustom } from "components/Table";
import SessionRow from "sections/@dashboard/session";
import { useQuery } from "react-query";
import { utils } from "utils";
import { fetchReservation } from "service/reservation";
import dayjs from "dayjs";

const TABLE_HEAD = [
  {
    id: "date",
    label: "TANGGAL SESI",
    alignRight: false,
  },
  {
    id: "time",
    label: "JAM SESI",
    alignRight: false,
  },
  {
    id: "psycholog_name",
    label: "NAMA PSIKOLOG",
    alignRight: false,
  },
  {
    id: "member_email",
    label: "EMAIL KLIEN",
    alignRight: false,
  },
  {
    id: "session_type",
    label: "TIPE SESI",
    alignRight: false,
  },
  {
    id: "session_location",
    label: "LOKASI SESI",
    alignRight: false,
  },
  {
    id: "promo_code",
    label: "KODE PROMO",
    alignRight: false,
  },
  {
    id: "fee",
    label: "FEE",
    alignRight: false,
  },
  {
    id: "price",
    label: "AMOUNT",
    alignRight: false,
  },
  {
    id: "overtime_duration",
    label: "OVERTIME",
    alignRight: false,
  },
  {
    id: "overtime_price",
    label: "HARGA OVERTIME",
    alignRight: false,
  },
  {
    id: "overtime_link",
    label: "TAUTAN OVERTIME",
    alignRight: false,
  },
  {
    id: "status",
    label: "STATUS",
    alignRight: false,
  },
  { id: "" },
];
// cancelled,cancelled_book,failed,expired,refund
export default function DeletedTab(props: {
  onCount: (val: number) => void;
  onPrecounseling: (val: any) => void;
  onCounselingNote: (val: any) => void;
  keyword?: string;
  specialist?: string;
  startDate?: Date | null;
  endDate?: Date | null;
}) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("title");

  const {
    dense,
    selected,
    onSort,
    page,
    rowsPerPage,
    setRowsPerPage,
    setPage,
  } = useTable({ defaultCurrentPage: 1 });

  const queryFetchReservation = useQuery(["fetchReservation", "deleted"], () =>
    fetchReservation({
      limit: rowsPerPage,
      page: page,
      psycholog_code: props.specialist,
      status: "cancelled,cancelled_book,failed,expired",
      keyword: props.keyword,
      start_date: props.startDate
        ? dayjs(props.startDate).format("YYYY-MM-DD")
        : "",
      end_date: props.endDate ? dayjs(props.endDate).format("YYYY-MM-DD") : "",
    })
  );

  const reservation = queryFetchReservation.data ?? {};
  const pagination = reservation.pagination ?? {};
  const count = pagination.count ?? 0;
  const data = reservation.data ?? [];

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === "asc";

    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const dataFiltered = utils.applySortFilter({
    data: data,
    comparator: utils.getComparator(order, orderBy),
  });

  const denseHeight = dense ? 52 : 72;

  useEffect(() => {
    queryFetchReservation.refetch();
  }, [page, rowsPerPage, props.specialist, props.keyword]);

  useEffect(() => {
    props.onCount(count);
  }, [count]);

  return (
    <div>
      <TableContainer
        sx={{
          minWidth: 800,
          position: "relative",
        }}
      >
        <Table size={dense ? "small" : "medium"}>
          <TableHeadCustom
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headLabel={TABLE_HEAD}
            rowCount={data.length}
            numSelected={selected.length}
            onSort={onSort}
          />

          <TableBody>
            {dataFiltered.map((row, index) => (
              <SessionRow
                key={index}
                row={row}
                withoutAction={true}
                onPrecounseling={() => props.onPrecounseling(row)}
                onCounselingNote={() => props.onCounselingNote(row)}
              />
            ))}

            <TableEmptyRows
              height={denseHeight}
              emptyRows={dataFiltered.length}
            />
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ position: "relative" }}>
        <TablePagination
          rowsPerPageOptions={[1, 10, 25, 100]}
          component="div"
          labelRowsPerPage="Item per halaman: "
          count={count || 0}
          rowsPerPage={rowsPerPage || 10}
          page={page - 1 || 0}
          onPageChange={(e, page) => setPage(page + 1)}
          onRowsPerPageChange={(val) =>
            setRowsPerPage(Number(val.target.value))
          }
        />
      </Box>
    </div>
  );
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter({ list, comparator }) {
  const stabilizedThis = list.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  list = stabilizedThis.map((el) => el[0]);

  return list;
}
