import {
  Box,
  DialogActions,
  DialogContent,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ModalSimple } from "components/Modal/ModalSimple";
import { Colors, Fonts } from "constant";
import { IVoidCallback } from "interfaces/callback";
import React, { useEffect, useState } from "react";
import { LabelModal } from "screens/Login/style";
import { Button as ButtonStyle } from "screens/style";
import { fetchEducationLevel } from "service/education";
import { fetchReligion } from "service/religion";
import { getSettingList } from "service/setting";
import { utils } from "utils";

export default function ContentModal(props: {
  isEdit: boolean;
  currentItem: any;
  isShow: boolean;
  onHide: IVoidCallback;
  onSubmit: (val: any) => void;
}) {
  // Form
  const [customerID, setCustomerID] = useState("");
  const [memberCode, setMemberCode] = useState("");
  const [country, setCountry] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const [status, setStatus] = useState("");
  const [profileUrl, setProfileUrl] = useState("");
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState("");
  const [birthdate, setBirthdate] = useState<Date | null>(null);
  const [educationLevel, setEducationLevel] = useState("");
  const [religion, setReligion] = useState("");

  const [postCode, setPostCode] = useState("");
  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");
  const [street, setStreet] = useState("");

  const [genders, setGenders] = useState<any[]>([]);
  const [educationLevels, setEducationLevels] = useState<any[]>([]);
  const [religions, setReligions] = useState<any[]>([]);

  const [isEnabled, setIsEnabled] = useState(false);

  const checkFormValid = () => {
    if (
      fullname.length > 0 &&
      utils.isEmail(email) &&
      phone.length > 0 &&
      customerID.length > 0
    ) {
      setIsEnabled(true);
    } else {
      setIsEnabled(false);
    }
  };

  useEffect(() => {
    getSettingList({
      group: "gender",
    }).then((val) => {
      setGenders(val.data ?? []);
    });

    fetchEducationLevel().then((val) => {
      setEducationLevels(val.data);
    });

    fetchReligion().then((val) => {
      setReligions(val.data);
    });
  }, []);

  useEffect(() => {
    checkFormValid();
  }, [fullname, email, phone, customerID]);

  useEffect(() => {
    if (!props.isShow) {
      setMemberCode("");
      setStatus("");
      setIsVerified(false);
      setProfileUrl("");
      setFullname("");
      setEmail("");
      setPhone("");
      setGender("");
      setBirthdate(null);
      setEducationLevel("");
      setCountry("");
      setReligion("");
      setStreet("");
      setCity("");
      setProvince("");
      setPostCode("");

      setIsEnabled(false);
    } else {
      if (!props.isEdit) {
        return;
      }

      const item = props.currentItem;

      setMemberCode(item.member_code ?? "");
      setCustomerID(item.customer_id ?? "");
      setStatus(item.status ?? "");
      setIsVerified(item.is_verified ?? false);
      setProfileUrl(item.profile_url ?? "");
      setFullname(item.username ?? "");
      setEmail(item.email ?? "");
      setPhone(item.phone ?? "");
      setGender(item.gender ?? "");
      setBirthdate(new Date(item.birthdate ?? ""));
      setEducationLevel(
        item.EducationLevel.id === 0 ? "" : item.EducationLevel.id
      );
      setCountry(item.country ?? "");
      setProvince(item.state ?? "");
      setCity(item.city ?? "");
      setReligion(item.Religion.id === 0 ? "" : item.Religion.id);
      setPostCode(item.post_code ?? "");
      setStreet(item.street_name ?? "");

      checkFormValid();
    }
  }, [props.isShow]);

  const onSubmit = () => {
    setIsEnabled(false);
    props.onSubmit({
      profile_url: profileUrl,
      member_code: memberCode,
      username: fullname,
      customer_id: customerID,
      email,
      phone,
      gender,
      birthdate,
      education_level_id: Number(educationLevel),
      religion_id: Number(religion),
      status: status,
      isVerified: isVerified,
      country,
      state: province,
      city,
      post_code: postCode,
      street_name: street,
    });
  };

  return (
    <ModalSimple
      isShown={props.isShow}
      title={props.isEdit ? "Update Client" : "Add New Client"}
      hide={props.onHide}
    >
      <>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box sx={{ overFlowY: "initial !important" }}>
              <div className="modalBodyC">
                <div className="input-wrapper" style={{ margin: "0 0 0 0" }}>
                  <div className="schedule-cus" style={{ width: "100%" }}>
                    <div style={{ width: "100%" }}>
                      <LabelModal
                        style={{
                          margin: "5px 0 10px 0",
                          display: "inline-block",
                        }}
                      >
                        Customer ID*
                      </LabelModal>
                      <div className="input-wrapper form-inline">
                        <input
                          type="text"
                          placeholder="Customer ID"
                          onChange={(val) => setCustomerID(val.target.value)}
                          value={customerID}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="input-wrapper" style={{ margin: "0 0 8px 0" }}>
                  <div className="schedule-cus" style={{ width: "100%" }}>
                    <div style={{ width: "100%" }}>
                      <LabelModal
                        style={{
                          margin: "5px 0 10px 0",
                          display: "inline-block",
                        }}
                      >
                        Name*
                      </LabelModal>
                      <div className="input-wrapper form-inline">
                        <input
                          type="text"
                          placeholder="Name"
                          onChange={(val) => setFullname(val.target.value)}
                          value={fullname}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="input-wrapper" style={{ margin: "0 0 8px 0" }}>
                  <div
                    className="schedule-cus"
                    style={{
                      width: "100%",
                      paddingTop: "0",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <LabelModal
                        style={{
                          margin: "0 0 10px 0",
                          display: "inline-block",
                        }}
                      >
                        Main Email*
                      </LabelModal>
                      <div className="input-wrapper form-inline">
                        <input
                          disabled={props.isEdit}
                          type="text"
                          placeholder="Email Address"
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="input-wrapper" style={{ margin: "0 0 8px 0" }}>
                  <div
                    className="schedule-cus"
                    style={{
                      width: "100%",
                      paddingTop: "0",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <LabelModal
                        style={{
                          margin: "0 0 10px 0",
                          display: "inline-block",
                        }}
                      >
                        Phone Number*
                      </LabelModal>
                      <div className="input-wrapper form-inline">
                        <input
                          type="tel"
                          placeholder="Phone Number"
                          value={phone}
                          onChange={(val) => setPhone(val.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="input-wrapper" style={{ margin: "0 0 12px 0" }}>
                  <div className="w-full">
                    <LabelModal style={{ display: "inline-block" }}>
                      Gender
                    </LabelModal>
                    <Select
                      displayEmpty
                      value={gender}
                      onChange={(e) => setGender(e.target.value as string)}
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        marginTop: "8px",
                        width: "100%",
                        border: "solid 1px Colors.app.GhostGrey",
                        background: Colors.white.default,
                        borderRadius: "5px",
                        color: Colors.app.black,
                        fontWeight: Fonts.weights.w5,
                        fontFamily: "Fonts.interMedium",
                        fontSize: Fonts.sizes.xs,
                      }}
                    >
                      <MenuItem value={""}>
                        <em className="text-slate-400">Gender</em>
                      </MenuItem>
                      {genders.map((item) => (
                        <MenuItem key={item.set_key} value={item.set_key}>
                          <em>{item.set_label}</em>
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>

                <div className="input-wrapper" style={{ margin: "0 0 12px 0" }}>
                  <div className="w-full">
                    <LabelModal style={{ display: "inline-block" }}>
                      Education Level
                    </LabelModal>
                    <Select
                      displayEmpty
                      value={educationLevel}
                      onChange={(e) =>
                        setEducationLevel(e.target.value as string)
                      }
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        marginTop: "8px",
                        width: "100%",
                        border: "solid 1px Colors.app.GhostGrey",
                        background: Colors.white.default,
                        borderRadius: "5px",
                        color: Colors.app.black,
                        fontWeight: Fonts.weights.w5,
                        fontFamily: "Fonts.interMedium",
                        fontSize: Fonts.sizes.xs,
                      }}
                    >
                      <MenuItem value={""} disabled>
                        <em className="text-slate-400">Education Level</em>
                      </MenuItem>
                      {educationLevels.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          <em>{item.name}</em>
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>

                <div className="input-wrapper" style={{ margin: "0 0 12px 0" }}>
                  <div className="w-full">
                    <LabelModal style={{ display: "inline-block" }}>
                      Religion
                    </LabelModal>
                    <Select
                      displayEmpty
                      value={religion}
                      onChange={(e) => setReligion(e.target.value as string)}
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        marginTop: "8px",
                        width: "100%",
                        border: "solid 1px Colors.app.GhostGrey",
                        background: Colors.white.default,
                        borderRadius: "5px",
                        color: Colors.app.black,
                        fontWeight: Fonts.weights.w5,
                        fontFamily: "Fonts.interMedium",
                        fontSize: Fonts.sizes.xs,
                      }}
                    >
                      <MenuItem value={""} disabled>
                        <em className="text-slate-400">Religion</em>
                      </MenuItem>
                      {religions.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          <em>{item.name}</em>
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>

                <div className="input-wrapper" style={{ margin: "0 0 12px 0" }}>
                  <div className="w-full">
                    <LabelModal
                      style={{ display: "inline-block" }}
                      className="mb-2"
                    >
                      Birthdate
                    </LabelModal>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        className="w-full block"
                        onChange={(e) => setBirthdate(e)}
                        value={birthdate}
                      ></DatePicker>
                    </LocalizationProvider>
                  </div>
                </div>

                <div className="input-wrapper" style={{ margin: "0 0 12px 0" }}>
                  <div className="w-full">
                    <LabelModal style={{ display: "inline-block" }}>
                      Street Name
                    </LabelModal>
                    <TextField
                      sx={{
                        marginTop: "8px",
                        width: "100%",
                        border: "solid 1px Colors.app.GhostGrey",
                        background: Colors.white.default,
                        borderRadius: "5px",
                        color: Colors.app.black,
                        fontWeight: Fonts.weights.w5,
                        fontFamily: "Fonts.interMedium",
                        fontSize: Fonts.sizes.xs,
                      }}
                      type="text"
                      placeholder="Street Name"
                      onChange={(e) => setStreet(e.target.value)}
                      value={street}
                    />
                  </div>
                </div>

                <div className="input-wrapper" style={{ margin: "0 0 12px 0" }}>
                  <div className="w-full">
                    <LabelModal style={{ display: "inline-block" }}>
                      City
                    </LabelModal>
                    <TextField
                      sx={{
                        marginTop: "8px",
                        width: "100%",
                        border: "solid 1px Colors.app.GhostGrey",
                        background: Colors.white.default,
                        borderRadius: "5px",
                        color: Colors.app.black,
                        fontWeight: Fonts.weights.w5,
                        fontFamily: "Fonts.interMedium",
                        fontSize: Fonts.sizes.xs,
                      }}
                      type="text"
                      placeholder="City"
                      onChange={(e) => setCity(e.target.value)}
                      value={city}
                    />
                  </div>
                </div>

                <div className="input-wrapper" style={{ margin: "0 0 12px 0" }}>
                  <div className="w-full">
                    <LabelModal style={{ display: "inline-block" }}>
                      Province
                    </LabelModal>
                    <TextField
                      sx={{
                        marginTop: "8px",
                        width: "100%",
                        border: "solid 1px Colors.app.GhostGrey",
                        background: Colors.white.default,
                        borderRadius: "5px",
                        color: Colors.app.black,
                        fontWeight: Fonts.weights.w5,
                        fontFamily: "Fonts.interMedium",
                        fontSize: Fonts.sizes.xs,
                      }}
                      type="text"
                      placeholder="Province"
                      onChange={(e) => setProvince(e.target.value)}
                      value={province}
                    />
                  </div>
                </div>

                <div className="input-wrapper" style={{ margin: "0 0 12px 0" }}>
                  <div className="w-full">
                    <LabelModal style={{ display: "inline-block" }}>
                      Post Code
                    </LabelModal>
                    <TextField
                      sx={{
                        marginTop: "8px",
                        width: "100%",
                        border: "solid 1px Colors.app.GhostGrey",
                        background: Colors.white.default,
                        borderRadius: "5px",
                        color: Colors.app.black,
                        fontWeight: Fonts.weights.w5,
                        fontFamily: "Fonts.interMedium",
                        fontSize: Fonts.sizes.xs,
                      }}
                      type="text"
                      placeholder="Post Code"
                      onChange={(e) => setPostCode(e.target.value)}
                      value={postCode}
                    />
                  </div>
                </div>

                <div className="input-wrapper" style={{ margin: "0 0 12px 0" }}>
                  <div className="w-full">
                    <LabelModal style={{ display: "inline-block" }}>
                      Country
                    </LabelModal>
                    <TextField
                      sx={{
                        marginTop: "8px",
                        width: "100%",
                        border: "solid 1px Colors.app.GhostGrey",
                        background: Colors.white.default,
                        borderRadius: "5px",
                        color: Colors.app.black,
                        fontWeight: Fonts.weights.w5,
                        fontFamily: "Fonts.interMedium",
                        fontSize: Fonts.sizes.xs,
                      }}
                      type="text"
                      // pattern='[0-9]*'
                      placeholder="Country"
                      onChange={(e) => setCountry(e.target.value)}
                      value={country}
                    />
                  </div>
                </div>
              </div>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: "20px 24px !important" }}>
          <ButtonStyle
            className="btn-primary"
            disabled={!isEnabled}
            title={props.isEdit ? "Update Client" : "Add New Client"}
            onClick={onSubmit}
            style={{ marginTop: "30px" }}
          >
            {props.isEdit ? "Update Client" : "Add Client"}
          </ButtonStyle>
        </DialogActions>
      </>
    </ModalSimple>
  );
}
