import api from "./api";

export const fetchEducationLevel = () => {
	return api
		.get('/v1/members/education-level', {
			params: {
				lang: 'id'
			}
		})
		.then((val) => val.data);
};

// export const updateOvertime = async (params: {
// 	reservationCode: string;
// 	duration: number;
// 	price: number;
// }) => {
// 	return api
// 		.post(`/v1/reservation/${params.reservationCode}/overtime`, {
// 			price: params.price,
// 			duration: params.duration,
// 		})
// 		.then((val) => val.data);
// };
