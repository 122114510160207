import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from "@mui/material";
import { TableEmptyRows, TableHeadCustom } from "components/Table";
import useTable from "components/Table/useTable";
import React, { useEffect, useState } from "react";
import UserRow from "sections/@dashboard/member/individual";
import { fetchMember, getMembers } from "service/member";
import { getSpecialistList } from "service/specialist";
import { utils } from "utils";

const TABLE_HEAD = [
  {
    id: "",
    type: "checkbox",
  },
  {
    id: "customer_id",
    label: "ID PELANGGAN",
    alignRight: false,
  },
  {
    id: "username",
    label: "NAMA",
    alignRight: false,
  },
  {
    id: "email",
    label: "EMAIL UTAMA",
    alignRight: false,
  },
  {
    id: "phone",
    label: "NO HP",
  },
  {
    id: "gender",
    label: "KELAMIN",
  },
  {
    id: "birthyear",
    label: "TANGGAL LAHIR",
  },
  {
    id: "education",
    label: "EDUKASI",
  },
  {
    id: "religion",
    label: "AGAMA",
  },
  {
    id: "street",
    label: "NAMA JALAN",
  },
  {
    id: "city",
    label: "KOTA",
  },
  {
    id: "province",
    label: "PROVINSI",
  },
  {
    id: "post_code",
    label: "KODE POS",
  },
  {
    id: "country",
    label: "NEGARA",
  },
  { id: "act" },
];

export default function TabActived(props: {
  search: string;
  status: string;
  forceUpdate: number;
  selectedItems: any[];
  onEdit: (val: any) => void;
  onDelete: (val: any) => void;
  onCount: (val: number) => void;
  onSelected: (val: any) => void;
  onSelectedAll: (val: any[]) => void;
  onInvitation: (val: any) => void;
  onChangeSpecialist: (val: any) => void;
}) {
  const [data, setData] = useState<any[]>([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);

  const { onSort } = useTable();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("username");

  useEffect(() => {
    props.onCount(count);
  }, [count]);

  useEffect(() => {
    init();
  }, [props.status, props.forceUpdate, page, limit]);

  useEffect(() => {
    if (props.search.length > 0 && page !== 1) {
      onChangePage(0);
    } else {
      init();
    }
  }, [props.search]);

  const onRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const dataFiltered = utils.applySortFilter({
    data: data,
    comparator: utils.getComparator(order, orderBy),
  });

  const onChangeRowPerPage = (val) => {
    setPage(1);
    setLimit(val);
  };

  const onChangePage = (val) => {
    setPage(val + 1);
  };

  const init = async () => {
    const resp = await fetchMember({
      page: page,
      limit: limit,
      keyword: props.search,
      status: "active",
    });

    const pagination = resp.pagination;

    setCount(pagination.count);
    setLimit(pagination.limit);
    setPage(pagination.page);
    setData(resp.data ?? []);
  };

  return (
    <div>
      <TableContainer
        sx={{
          minWidth: 800,
          position: "relative",
        }}
      >
        <Table size="medium">
          <TableHeadCustom
            headLabel={TABLE_HEAD}
            rowCount={data.length}
            orderBy={orderBy}
            onRequestSort={onRequestSort}
            onSort={onSort}
            order={order}
            checkSelected={props.selectedItems}
            numSelected={props.selectedItems.length}
            onSelectAllRows={() => props.onSelectedAll(data)}
          />

          <TableBody>
            {dataFiltered.map((row, index) => {
              const found = props.selectedItems.findIndex(
                (item) => item.member_code === row.member_code
              );
              const labelIdx = row.member_code;

              return (
                <UserRow
                  key={row.member_code}
                  isSelectedRow={found !== -1}
                  row={row}
                  labelId={labelIdx}
                  onEdit={() => props.onEdit(row)}
                  onDelete={() => props.onDelete(row)}
                  onSelect={() => props.onSelected(row)}
                  onInvitation={() => props.onInvitation(row)}
                  onChangeSpecialist={() => props.onChangeSpecialist(row)}
                />
              );
            })}

            <TableEmptyRows height={72} emptyRows={data.length} />
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ position: "relative" }}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          labelRowsPerPage="Item per halaman: "
          count={count || 0}
          rowsPerPage={limit || 10}
          page={page - 1 || 0}
          onPageChange={(_, pg) => onChangePage(pg)}
          onRowsPerPageChange={(e) => onChangeRowPerPage(e.target.value)}
        />
      </Box>
    </div>
  );
}
